import { SecurityID } from "@/model/security_id";

export class NanoInfo {
  constructor(name: string, primary_symbol: string, entity_id: SecurityID) {
    this.name_ = name;
    this.primary_symbol_ = primary_symbol;
    this.entity_id_ = entity_id;
  }

  get name(): string {
    return this.name_;
  }

  get primary_symbol(): string {
    return this.primary_symbol_;
  }

  get entity_id(): SecurityID {
    return this.entity_id_;
  }

  private readonly name_: string;
  private readonly primary_symbol_: string;
  private readonly entity_id_: SecurityID;
}
