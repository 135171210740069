import { SubscriptionsInfo, default_subscriptions_info } from "@/model/auth/subscriptions_info";

export class RefreshTokenData {
  constructor(
    expires_in_seconds: number,
    options?: {
      roles?: string[];
      subs_info?: SubscriptionsInfo;
    }
  ) {
    this.expires_in_seconds_ = expires_in_seconds;
    this.roles_ = options?.roles ?? [];
    this.subs_info_ = options?.subs_info ?? default_subscriptions_info();
  }

  get expires_in_seconds(): number {
    return this.expires_in_seconds_;
  }

  get roles(): string[] {
    return this.roles_;
  }

  get subs_info(): SubscriptionsInfo {
    return this.subs_info_;
  }

  private readonly expires_in_seconds_: number = 0;
  private readonly roles_: string[] = [];
  private readonly subs_info_: SubscriptionsInfo;
}
