import { verify_field_defined } from "@xelonic.com/trill";
import { Resource } from "@/model/resource";
import { Resource as APIResource } from "@xelonic.com/xelonic-api";

export function resource_from_api(resource: APIResource): Resource {
  const type_name = "Resource";
  verify_field_defined(resource, type_name, "url");

  return new Resource(resource.url!, {
    source_name: resource.source_name,
    source_url: resource.source_url,
    license_url: resource.license_url,
  });
}
