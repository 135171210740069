import { UserPreferences as APIUserPreferences } from "@xelonic.com/xelonic-api";
import { UserPreferences } from "@/model/user/user_preferences";
import {
  company_watchlist_settings_from_api,
  company_watchlist_settings_to_api,
} from "@/api/user/preferences/company_watchlist_settings";
import { Logger } from "@xelonic.com/trill";

export function preferences_from_api(preferences: APIUserPreferences, logger: Logger): UserPreferences {
  const company_watchlist = preferences.company_watchlist
    ? company_watchlist_settings_from_api(preferences.company_watchlist, logger)
    : undefined;

  return new UserPreferences({
    locale: preferences.locale,
    sifted_infos: preferences.sifted_infos,
    accepted_cookies: preferences.accepted_cookies,
    company_watchlist,
  });
}

export function preferences_to_api(preferences: UserPreferences): APIUserPreferences {
  const company_watchlist = company_watchlist_settings_to_api(preferences.company_watchlist);

  return {
    locale: preferences.locale,
    sifted_infos: preferences.sifted_infos,
    accepted_cookies: preferences.accepted_cookies,
    company_watchlist,
  };
}
