import { years_ago } from "@/model/time/date";
import { TimePoint, TimePointType } from "@/model/time/time_point";
import { compare_greater } from "@xelonic.com/trill";

export class YearMonthDay implements TimePoint {
  static from_date(date: Date): YearMonthDay {
    return new YearMonthDay(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }

  static from_string(str: string): YearMonthDay {
    const year_regex = /^(\d{4})$/;
    const year_month_regex = /^(\d{4})-(\d{2})$/;
    const year_month_day_regex = /^(\d{4})-(\d{2})-(\d{2})$/;

    let match = str.match(year_regex);
    if (match) {
      return new YearMonthDay(parseInt(match[1]));
    }

    match = str.match(year_month_regex);
    if (match) {
      return new YearMonthDay(parseInt(match[1]), parseInt(match[2]));
    }

    match = str.match(year_month_day_regex);
    if (match) {
      return new YearMonthDay(parseInt(match[1]), parseInt(match[2]), parseInt(match[3]));
    }

    throw new Error(`invalid year-month-day: ${str}`);
  }

  constructor(year?: number, month?: number, day?: number) {
    if (!year) {
      year = new Date().getUTCFullYear();
    }

    if (month !== undefined && (month < 1 || month > 12)) {
      throw new Error(`month not in range [1,12]: ${month}`);
    }

    if (day !== undefined && (day < 1 || day > 31)) {
      throw new Error(`day not in range [1,31]: ${day}`);
    }

    this.year_ = year;
    this.month_ = month;
    this.day_ = day;
  }

  get type(): TimePointType {
    return TimePointType.YEAR_MONTH_DAY;
  }

  get year(): number {
    return this.year_;
  }

  get month(): number | undefined {
    return this.month_;
  }

  get day(): number | undefined {
    return this.day_;
  }

  before(other: TimePoint): boolean {
    return this.to_date() < other.to_date();
  }

  after(other: TimePoint): boolean {
    return this.to_date() > other.to_date();
  }

  equal_to(other: TimePoint): boolean {
    if (!(other instanceof YearMonthDay)) {
      return false;
    }

    return this.year_ === other.year_ && this.month_ === other.month_ && this.day_ === other.day_;
  }

  truncate_by_minutes(): TimePoint {
    return new YearMonthDay(this.year_, this.month_, this.day_);
  }

  toISOString(): string {
    return this.to_date().toISOString().substr(0, 10);
  }

  to_date(): Date {
    return new Date(Date.UTC(this.year_, (this.month_ ?? 1) - 1, this.day_ ?? 0));
  }

  to_string(): string {
    let month = "";
    let day = "";

    if (this.month_) {
      month = `${this.month_}`.padStart(2, "0");
      month = `-${month}`;

      if (this.day_) {
        day = `${this.day_}`.padStart(2, "0");
        day = `-${day}`;
      }
    }

    return `${this.year_}${month}${day}`;
  }

  years_ago(years: number): YearMonthDay {
    return YearMonthDay.from_date(years_ago(this.to_date(), years));
  }

  add_days(days: number): YearMonthDay {
    const date = this.to_date();
    date.setUTCDate(date.getUTCDate() + days);
    return YearMonthDay.from_date(date);
  }

  private readonly year_: number;
  private readonly month_?: number;
  private readonly day_?: number;
}

export function compare_year_month_day_descending(lhs?: YearMonthDay, rhs?: YearMonthDay): number {
  let result = compare_greater(lhs?.year, rhs?.year);
  if (result !== 0) {
    return result;
  }

  result = compare_greater(lhs?.month, rhs?.month);
  if (result !== 0) {
    return result;
  }

  return compare_greater(lhs?.day, rhs?.day);
}
