import { RouteConfig } from "vue-router";

export function create_not_found_route(path: string): RouteConfig {
  return {
    path: path,
    component: () => import("@/vue/views/NotFoundPage.vue"),
    meta: {
      translation_key: "router.not_found",
    },
  };
}
