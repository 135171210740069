<template>
  <div
    id="app"
    class="d-flex flex-column min-vh-100 mx-auto px-0 px-md-3"
    :class="app_display_class"
    :style="css_properties"
  >
    <div id="top" />
    <b-container id="content" fluid class="flex-container flex-grow-1">
      <b-row align-h="center">
        <b-col :class="left_sidebar_display_class">
          <div class="sticky-header">
            <div class="navbar-filler" />
            <div class="left-sidebar text-right">
              <div class="mr-3">
                <router-view v-if="$auth.is_active" name="left_sidebar" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col :class="main_display_class">
          <!-- background-color: this makes the navbar span the full width so that the background won't shine through -->
          <b-row class="sticky-header" style="background-color: white">
            <b-col>
              <nav-bar />
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-alert v-if="$auth.is_activating" class="text-center">
                <div>
                  {{ $ls("app.is_activating") }}
                </div>
                <div class="mt-3">
                  <b-spinner small />
                </div>
              </b-alert>
              <div v-else>
                <alert v-if="tell_user_to_create_sub">
                  <div v-html="tell_user_to_create_sub_content" />
                </alert>
                <router-view />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col :class="right_sidebar_display_class">
          <div class="sticky-header">
            <div class="navbar-filler" />
            <div class="right-sidebar text-left">
              <router-view v-if="$auth.is_active" name="right_sidebar" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div id="footer">
      <footer-nav />
      <cookie-law
        v-if="cookie_banner_visible"
        theme="dark-lime"
        :buttonText="$ls('app.gdpr.button')"
        buttonClass="btn btn-success"
        v-on:accept="accept_cookies()"
      >
        <template v-slot:message>
          <div class="gdpr">
            {{ $ls("app.gdpr.banner_text") }}
            <router-link to="/privacy">{{ $ls("app.gdpr.privacy_policy") }}.</router-link>
          </div>
        </template>
      </cookie-law>
    </div>
  </div>
</template>

<script lang="ts">
import CookieLaw from "vue-cookie-law";
import { TooltipService } from "@/services/tooltip_service";
import FooterNav from "@/vue/views/FooterNav.vue";
import NavBar from "@/vue/views/NavBar.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BContainer, BRow, BCol } from "bootstrap-vue/esm/components/layout";
import { ChartsService, get_charts_service } from "@/services/charts_service";
import { BLink } from "bootstrap-vue/esm/components/link";
import { BSpinner } from "bootstrap-vue/esm/components/spinner";
import Alert from "@/vue/components/general/Alert.vue";
import { BAlert } from "bootstrap-vue/esm/components/alert";

@Component({
  components: {
    Alert,
    BAlert,
    BCol,
    BContainer,
    BLink,
    BRow,
    BSpinner,
    CookieLaw,
    FooterNav,
    NavBar,
  },
})
export default class App extends Vue {
  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get css_properties(): Record<string, string> {
    return {
      "--suggestion-fade-in-msec": `${this.$config.suggestions_fade_in_msecs}ms`,
      "--suggestion-fade-out-msec": `${this.$config.suggestions_fade_out_msecs}ms`,
    };
  }

  get app_display_class(): string {
    return this.charts.maximized_chart ? "container-fluid" : "container-xxl";
  }

  get left_sidebar_display_class(): string {
    return this.charts.maximized_chart ? "d-none" : "col-0 col-xl-3 col-xxl-2 d-none d-xl-block";
  }

  get main_display_class(): string {
    return this.charts.maximized_chart ? "col-12" : "col-12 col-xl-9 col-xxl-8";
  }

  get right_sidebar_display_class(): string {
    return this.charts.maximized_chart ? "d-none" : "col-0 col-xxl-2 d-none d-xxl-block";
  }

  get cookie_banner_visible(): boolean {
    return !this.$user_preferences.accepted_cookies;
  }

  accept_cookies() {
    this.$tracking.enable();
    this.$user_preferences.accepted_cookies = true;
  }

  get tell_user_to_create_sub(): boolean {
    return (
      this.$auth.is_logged_in &&
      !this.$auth.user_has_alive_sub &&
      !this.$route.fullPath.includes("subscriptions") &&
      !this.$route.fullPath.includes("initial-sub")
    );
  }

  get tell_user_to_create_sub_content(): string {
    const subs = this.$router.resolve({ name: "subscriptions" });
    const youtube = this.$router.resolve({ name: "youtube" });

    return this.$ls("app.alert_activate_subscription", { subs_link: subs.href, youtube: youtube.href });
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------

  @Watch("$route")
  hide_tooltip(): void {
    this.tooltip_service_?.hide_all();
  }

  created(): void {
    this.$log.info(`app version ${this.$config.app_version}`);

    // startup stuff

    window.addEventListener("scroll", () => this.hide_tooltip(), { passive: true });

    this.tooltip_service_ = new TooltipService(this.$root);
  }

  private get charts(): ChartsService {
    return get_charts_service(this.$backend);
  }

  private tooltip_service_: TooltipService | null = null;
}
</script>

<style lang="scss">
// import globally (i.e. without "scoped") the styles, so that bootstrap and bootstrap vue can function correctly.
// the line below is not needed actually, because it's automatically included. But we do this to make it explicit.
@import "@/styles/main.scss";

// this reduces the CLS from ~0.7-0.9 to <0.1 because for e.g. the feed there's no scrollbar initially, but then there
// is causing the whole document to be shifted to the left
body {
  overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $text-primary;
  line-height: $default-line-height;
}

$header-z-index: 100;

.navbar-item-color {
  color: $navbar-light-color;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: $header-z-index;
}

.above-header {
  z-index: $header-z-index + 1;
}

.navbar-filler {
  height: $navbar-height;
}

#footer {
  flex-shrink: 0;
}

.left-sidebar {
  border-right: 1px solid $separator-color;
}

.right-sidebar {
  border-left: 1px solid $separator-color;
}

.gdpr {
  color: $gray-100;
}

.gdpr a {
  color: $green;
}
</style>
