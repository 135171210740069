import { EntityType } from "@/model/entity_type";
import { props_security_id_from_route, security_id_from_route } from "@/router/router_util";
import { RouteBuilder } from "@xelonic.com/trill";
import { RouteName, RoutePath } from "@/router/route_constants";
import { Route } from "vue-router";

export function create_person_routes(base_path: string, builder: RouteBuilder): RouteBuilder {
  return builder
    .add({
      path: `${base_path}/:security_id`,
      components: {
        default: () => import("@/vue/views/person/Person.vue"),
        left_sidebar: () => import("@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"),
        right_sidebar: () => import("@/vue/components/general/LocalTreeNavigation.vue"),
      },
      props: {
        default: (route: Route) => ({
          security_id: security_id_from_route(route),
          route_root: {
            path: RoutePath.PERSON,
            path_suffix: route.params.security_id,
          },
        }),
        left_sidebar: (route: Route) => ({
          route_root: {
            path: RoutePath.PERSON,
            path_suffix: route.params.security_id,
          },
        }),
        right_sidebar: () => ({
          target_selector: "#person_content",
        }),
      },
    })
    .enter()
    .add({
      path: "holdings",
      name: RouteName.PERSON_HOLDINGS,
      component: () => import("@/vue/components/ownership/Ownership.vue"),
      meta: {
        translation_key: "router.holdings.name",
        role: "api_holdings",
      },
      props: (route: Route) => ({
        security_id: security_id_from_route(route),
        ownership_type: "holdings",
        entity_type: EntityType.PERSON,
      }),
    })
    .add({
      path: "knowledge-graph",
      component: () => import("@/vue/views/securities/KnowledgeGraph.vue"),
      meta: {
        translation_key: "router.company.knowledge_graph.name",
        role: "api_knowledge_graph",
      },
      props: props_security_id_from_route,
    })
    .add({
      path: "timeline",
      component: () => import("@/vue/views/entities/Timeline.vue"),
      meta: {
        translation_key: "router.person.timeline.name",
        role: "api_events",
      },
      props: props_security_id_from_route,
    })
    .add({
      path: "general-info",
      component: () => import("@/vue/views/person/general_info/GeneralInfo.vue"),
      meta: {
        translation_key: "router.person.general_info.name",
        role: "api_general_info",
      },
      props: props_security_id_from_route,
    })
    .exit();
}
