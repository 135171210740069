import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

export function install_icons(vue: typeof Vue): void {
  vue.use(Icons);
}

class Icons {
  static install(vue: typeof Vue): void {
    vue.component("font-awesome-icon", FontAwesomeIcon);
  }
}
