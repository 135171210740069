import { SecurityCategory } from "@/model/security";
import { create_security_id, SecurityID, SecurityIDType } from "@/model/security_id";
import {
  SecurityCategory as APISecurityCategory,
  SecurityId as APISecurityID,
  SecurityIdType as APISecurityIDType,
} from "@xelonic.com/xelonic-api";
import { verify_field_defined } from "@xelonic.com/trill";

export function security_id_from_api(security_id: APISecurityID): SecurityID {
  const type_name = "SecurityID";
  verify_field_defined(security_id, type_name, "type");
  verify_field_defined(security_id, type_name, "id");

  return create_security_id(security_id_type_from_api(security_id.type!), security_id.id!);
}

function security_id_type_from_api(id_type: APISecurityIDType): SecurityIDType {
  switch (id_type) {
    case APISecurityIDType.Cik:
      return SecurityIDType.CIK;
    case APISecurityIDType.Hr:
      return SecurityIDType.HR;
    case APISecurityIDType.Lei:
      return SecurityIDType.LEI;
  }
}

export function security_id_to_api(security_id: SecurityID): APISecurityID {
  return {
    id: security_id.id,
    type: security_id_type_to_api(security_id.id_type),
  };
}

function security_id_type_to_api(id_type: SecurityIDType): APISecurityIDType {
  switch (id_type) {
    case SecurityIDType.CIK:
      return APISecurityIDType.Cik;
    case SecurityIDType.HR:
      return APISecurityIDType.Hr;
    case SecurityIDType.LEI:
      return APISecurityIDType.Lei;
  }
}

export function security_category_to_api(category: SecurityCategory): APISecurityCategory {
  switch (category) {
    case SecurityCategory.DERIVATIVE:
      return APISecurityCategory.Derivative;
    case SecurityCategory.NON_DERIVATIVE:
      return APISecurityCategory.NonDerivative;
  }
}

export function security_category_from_api(category: APISecurityCategory): SecurityCategory {
  switch (category) {
    case APISecurityCategory.Derivative:
      return SecurityCategory.DERIVATIVE;
    case APISecurityCategory.NonDerivative:
      return SecurityCategory.NON_DERIVATIVE;
  }
}

export function security_group_to_api(group: SecurityGroup): "all" | "sector" | "industry" {
  return group;
}

export enum SecurityGroup {
  ALL = "all",
  SECTOR = "sector",
  INDUSTRY = "industry",
}
