import { entity_type_from_api } from "@/api/entities/entity_type";
import { possibly_defined, try_call, verify_field_defined } from "@xelonic.com/trill";
import { resource_from_api } from "@/api/resource";
import { security_id_from_api } from "@/api/securities";
import { Entity } from "@/model/entities/entity";
import { Logger } from "@xelonic.com/trill";
import { Entity as APIEntity } from "@xelonic.com/xelonic-api";

export function entity_from_api(entity: APIEntity, logger: Logger): Entity {
  const type_name = "Entity";
  verify_field_defined(entity, type_name, "id");
  verify_field_defined(entity, type_name, "type");

  const id = security_id_from_api(entity.id!);
  const type = entity_type_from_api(entity.type!);

  const logo = try_call(entity.logo, logger, resource_from_api);

  return new Entity(id, type, {
    name: possibly_defined(entity.name),
    logo: logo,
  });
}
