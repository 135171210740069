import { EntityType } from "@/model/entity_type";
import { SecurityID } from "@/model/security_id";
import { Location } from "vue-router";

export enum RouteName {
  ACCOUNT_DELETION_ERROR = "Account Deletion Error",
  COMPANY_FINANCES_BALANCE = "Company Finances Balance",
  COMPANY_FINANCES_CASHFLOW = "Company Finances Cashflow",
  COMPANY_FINANCES_OPERATIONS = "Company Finances Operations",
  COMPANY_FINANCES_INCOME = "Company Finances Income",
  COMPANY_FINANCES_COMPREHENSIVE_INCOME = "Company Finances Comprehensive Income",
  COMPANY_FINANCES = COMPANY_FINANCES_BALANCE,
  COMPANY_OWNERSHIP = "Company Ownership",
  COMPANY_HOLDINGS = "Company Holdings",
  COMPANY_RATIOS = "Company Ratios",
  COMPARE_DEFAULT = "Compare Default",
  DATA_SOURCES = "Data Sources",
  DISCOVER_DEFAULT = "Discover Default",
  DISCOVER_COMPANY_TAGS = "Discover Company Tags",
  PASSWORD_RESET_REQUEST = "Password Reset Request",
  PERSON = "Person",
  PERSON_HOLDINGS = "Person Holdings",
  PERSON_DEFAULT = PERSON_HOLDINGS,
  PROFILE = "Profile",
  SEARCH_RESULTS = "SearchResults",
  CONTENT_CREATION = "content creation",
  WATCH_LIST = "WatchList",
}

export enum RoutePath {
  AUTH = "/auth",
  LOGIN = "/login",
  COMPANY = "/company",
  COMPARE = "/compare",
  DISCOVER = "/discover",
  PERSON = "/person",
  PROFILE = "/profile",
  PUBLIC = "/public",
  RESET_PASSWORD = "/reset-password",
  SETTINGS = "/settings",
  TEST = "/test",
  CONTENT_CREATION = "/content-creation",
  WATCH_LIST = "/watchlist",
}

export interface RouteRoot {
  path: RoutePath;
  path_suffix?: string;
}

export function link_to_company(id: SecurityID, route_name?: RouteName): Location {
  return {
    name: route_name ?? "company-overview",
    params: {
      security_id: id.formatted(),
    },
  };
}

export function link_to_person(id: SecurityID, route_name?: RouteName): Location {
  return {
    name: route_name ?? RouteName.PERSON_DEFAULT,
    params: {
      security_id: id.formatted(),
    },
  };
}

export function link_to_entity(id: SecurityID, entity_type: EntityType, route_name?: RouteName): Location {
  switch (entity_type) {
    case EntityType.PERSON:
      return link_to_person(id, route_name);
    case EntityType.COMPANY:
      return link_to_company(id, route_name);
  }

  throw new Error(`unknown entity type: ${entity_type}`);
}
