import { TickerCode } from "@/model/ticker_code";
import { TickerCode as APITickerCode } from "@xelonic.com/xelonic-api";
import { verify_field_defined } from "@xelonic.com/trill";

export function ticker_code_from_api(ticker_code: APITickerCode): TickerCode {
  const type_name = "TickerCode";
  verify_field_defined(ticker_code, type_name, "symbol");

  return new TickerCode(ticker_code.symbol!, {
    exchange: ticker_code.exchange ?? "",
    cik: ticker_code.cik,
    isin: ticker_code.isin,
    cusip: ticker_code.cusip,
    wkn: ticker_code.wkn,
    irs: ticker_code.irs,
    sic: ticker_code.sic,
    lei: ticker_code.lei,
    figi: ticker_code.figi,
    bloomberg_gid: ticker_code.bloomberg_gid,
  });
}
