import { OwnerToCompanyRelationship } from "@/model/entities/ownership/owner_to_company_relationship";
import { possibly_defined } from "@xelonic.com/trill";
import { OwnerToCompanyRelationship as APIOwnerToCompanyRelationship } from "@xelonic.com/xelonic-api";

export function owner_to_company_relationship_from_api(
  relationship: APIOwnerToCompanyRelationship
): OwnerToCompanyRelationship {
  return new OwnerToCompanyRelationship({
    is_director: possibly_defined(relationship.is_director),
    is_officer: possibly_defined(relationship.is_officer),
    is_ten_percent_owner: possibly_defined(relationship.is_ten_percent_owner),
    is_other: possibly_defined(relationship.is_other),
    officer_title: possibly_defined(relationship.officer_title),
  });
}
