import { RepositoryStore } from "@/api/repository_store";
import { preferences_from_api, preferences_to_api } from "@/api/user/preferences/user_preferences";
import { UserPreferences } from "@/model/user/user_preferences";
import { LocalStorageItem } from "@/repositories/local_storage";
import { is_defined, try_call } from "@xelonic.com/trill";
import { Logger, new_logger } from "@xelonic.com/trill";
import { UserPreferencesApi, UserPreferences as APIUserPreferences } from "@xelonic.com/xelonic-api";
import { reactive } from "vue";

export function get_user_preferences_repository(repos: RepositoryStore): UserPreferencesRepository {
  return repos.get_repository("user_preferences", UserPreferencesApi, UserPreferencesRepository);
}

class ReactiveState {
  preferences: UserPreferences = new UserPreferences();
}

export class UserPreferencesRepository {
  constructor(options: { api: UserPreferencesApi }) {
    this.api_ = options.api;
    this.logger_ = new_logger("user_preferences_repository");

    this.state_ = reactive(new ReactiveState()) as ReactiveState;
    const preferences = get_user_preferences_from_local_storage(this.logger_);
    if (preferences) {
      this.state_.preferences = preferences;
    }
  }

  get(): UserPreferences {
    return this.state_.preferences;
  }

  async load_preferences(skip_backend_load = false): Promise<void> {
    let preferences: UserPreferences | undefined;

    if (skip_backend_load) {
      preferences = get_user_preferences_from_local_storage(this.logger_);
    } else {
      try {
        const result = await this.api_.getPreferences("from-cookie");
        preferences = preferences_from_api(result.data, this.logger_);
        save_api_user_preferences_to_local_storage(result.data);
      } catch (reason: unknown) {
        this.logger_.error(
          `failed to retrieve user preferences from backend. Try to retrieve them from local storage. Details: ${reason}`
        );
        preferences = get_user_preferences_from_local_storage(this.logger_);
      }
    }

    if (preferences) {
      this.state_.preferences = preferences;
    }
  }

  async save_preferences(skip_backend_save = false): Promise<void> {
    const api_prefs = preferences_to_api(this.state_.preferences);

    save_api_user_preferences_to_local_storage(api_prefs);

    if (!skip_backend_save) {
      await this.api_.updatePreferences("from-cookie", api_prefs);
    }
  }

  private readonly api_: UserPreferencesApi;
  private readonly logger_: Logger;
  private state_: ReactiveState;
}

function get_user_preferences_from_local_storage(logger: Logger): UserPreferences | undefined {
  const stored_json = localStorage.getItem(LocalStorageItem.USER_PREFERENCES);

  if (!is_defined(stored_json)) {
    return undefined;
  }

  const api_preferences = JSON.parse(stored_json) as APIUserPreferences;
  return try_call(api_preferences, logger, preferences_from_api);
}

function save_api_user_preferences_to_local_storage(prefs: APIUserPreferences): void {
  localStorage.setItem(LocalStorageItem.USER_PREFERENCES, JSON.stringify(prefs));
}
