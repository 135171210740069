<template>
  <language-choice-dropdown :languages="$languages.items" :selected_language.sync="selected_language" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Language } from "@/model/language";
import LanguageChoiceDropdown from "@/vue/components/LanguageChoiceDropdown.vue";

@Component({
  components: {
    LanguageChoiceDropdown,
  },
})
export default class DashLanguageChoiceDropdown extends Vue {
  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get selected_language(): Language {
    return this.$user_preferences.selected_language;
  }

  set selected_language(language: Language) {
    this.$user_preferences.set_selected_language(language);
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------
}
</script>

<style lang="scss" scoped></style>
