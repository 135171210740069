import { entity_from_api } from "@/api/entities/entity";
import { GeneralInfo } from "@/model/persons/general_info";
import { Logger } from "@xelonic.com/trill";
import { PersonGeneralInfo as APIGeneralInfo } from "@xelonic.com/xelonic-api";
import { address_from_api } from "../address";
import { verify_field_defined } from "@xelonic.com/trill";

export function general_info_from_api(info: APIGeneralInfo, logger: Logger): GeneralInfo {
  verify_field_defined(info, "PersonGeneralInfo", "entity");

  const entity = entity_from_api(info.entity!, logger);

  return new GeneralInfo(entity, {
    address: info.address ? address_from_api(info.address) : undefined,
  });
}
