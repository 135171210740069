<template>
  <b-dropdown variant="outline-secondary" no-caret toggle-class="px-0 py-0 border-0" right>
    <template #button-content>
      <span :class="flag_classes(current_language)" />
      {{ current_language.title }}
    </template>
    <b-dropdown-item
      v-for="language in languages"
      :key="language.locale"
      @click="current_language = language"
      :active="is_language_selected(language)"
    >
      <span :class="flag_classes(language)" />
      {{ language.title }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { BDropdown, BDropdownItem } from "bootstrap-vue/esm/components/dropdown";
import { Language } from "@/model/language";

@Component({
  components: {
    BDropdown,
    BDropdownItem,
  },
})
export default class LanguageChoiceDropdown extends Vue {
  @Prop({ required: true })
  readonly languages!: Language[];

  @PropSync("selected_language", { required: true })
  selected_language_prop!: Language;

  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get current_language(): Language {
    return this.selected_language_prop;
  }

  set current_language(language: Language) {
    this.selected_language_prop = language;
  }

  is_language_selected(language: Language): boolean {
    return language.equal_to(this.selected_language_prop);
  }

  flag_classes(language: Language): string {
    return `flag-icon flag-icon-${language.flag_class}`;
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------
}
</script>

<style lang="scss" scoped>
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 2rem;
  line-height: 1rem;
}

.flag-icon:before {
  content: "\00a0";
}

.flag-icon-de {
  background-image: url(assets/flags/4x3/de.svg);
}

.flag-icon-us {
  background-image: url(assets/flags/4x3/us.svg);
}

.flag-icon-gb {
  background-image: url(assets/flags/4x3/gb.svg);
}

.flag-icon-pl {
  background-image: url(assets/flags/4x3/pl.svg);
}

.flag-icon-es {
  background-image: url(assets/flags/4x3/es.svg);
}

.flag-icon-fr {
  background-image: url(assets/flags/4x3/fr.svg);
}

.flag-icon-it {
  background-image: url(assets/flags/4x3/it.svg);
}
</style>
