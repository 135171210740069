import { possibly_defined, verify_field_defined } from "@xelonic.com/trill";
import { TickerTag } from "@/model/companies/ticker_tag";
import { TickerTag as APITickerTag } from "@xelonic.com/xelonic-api";

export function ticker_tag_from_api(tag: APITickerTag): TickerTag {
  const type_name = "TickerTag";

  verify_field_defined(tag, type_name, "id");
  verify_field_defined(tag, type_name, "name");

  return new TickerTag(tag.id!, tag.name!, {
    important: possibly_defined(tag.important),
    rank: possibly_defined(tag.rank),
    unique: possibly_defined(tag.unique),
  });
}
