import { Address } from "@/model/address";
import { Address as APIAddress } from "@xelonic.com/xelonic-api";
import { location_from_api } from "./location";

export function address_from_api(address: APIAddress): Address {
  return new Address(
    address.formatted_address ? address.formatted_address : "",
    address.location ? location_from_api(address.location) : undefined,
    address.phone
  );
}
