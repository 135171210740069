import { Character } from "@/model/companies/character";
import { GeneralInfo } from "@/model/companies/general_info";
import { EntityConnection } from "@/model/entities/entity_connection";
import { EntityType } from "@/model/entity_type";
import { Price } from "@/model/price";
import { RankedEntity } from "@/model/ranked_securities/ranked_entity";
import { null_if_empty, null_if_not_defined } from "@xelonic.com/trill";

export class RankedCompany extends RankedEntity {
  constructor(
    rank: number,
    general_info: GeneralInfo,
    options?: {
      price?: Price;
      character?: Character;
      top_owners?: EntityConnection[];
      top_holdings?: EntityConnection[];
    }
  ) {
    super(rank, general_info.entity.id, EntityType.COMPANY, {
      name: general_info.entity.name,
      logo: general_info.entity.logo ?? undefined,
    });

    this.general_info_ = general_info;
    this.price_ = null_if_not_defined(options?.price);
    this.character_ = null_if_not_defined(options?.character);

    this.top_owners_ = null_if_empty(options?.top_owners);
    this.top_holdings_ = null_if_empty(options?.top_holdings);
  }

  get general_info(): GeneralInfo {
    return this.general_info_;
  }

  get price(): Price | null {
    return this.price_;
  }

  get character(): Character | null {
    return this.character_;
  }

  get top_owners(): EntityConnection[] | null {
    return this.top_owners_;
  }

  get top_holdings(): EntityConnection[] | null {
    return this.top_holdings_;
  }

  private readonly general_info_: GeneralInfo;
  private readonly price_: Price | null;
  private readonly character_: Character | null;
  private readonly top_owners_: EntityConnection[] | null;
  private readonly top_holdings_: EntityConnection[] | null;
}
