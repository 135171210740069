import { install_vue_i18n as install_vue_i18n_ } from "@xelonic.com/trill";
import Vue from "vue";
import VueI18n, { LocaleMessageObject, LocaleMessages } from "vue-i18n";

const loaded_messages: Record<string, LocaleMessageObject> = {};

export async function load_messages_async(locale: string): Promise<LocaleMessageObject> {
  if (loaded_messages[locale] !== undefined) {
    return Promise.resolve(loaded_messages[locale]);
  }

  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.yaml`);
  loaded_messages[locale] = messages;
  return messages;
}

function load_default_messages(): LocaleMessages {
  // we go a pretty complicated way here to resolve the yaml file. I just can't get webpack to find the file
  // when I just define the filename directly.
  const locales = require.context("@/locales", true, /en-us\.yaml$/i);

  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    messages["en-us"] = locales(key);
  });

  loaded_messages["en-us"] = messages["en-us"];

  return messages;
}

export function install_vue_i18n(instance: typeof Vue): VueI18n {
  return install_vue_i18n_(instance, "en-us", load_default_messages(), "en-us");
}
