import { create_person_routes } from "@/router/person_routes";
import { create_profile_routes } from "@/router/profile_routes";
import { create_public_routes } from "@/router/public_routes";
import { ReleaseStage, RouteBuilder } from "@xelonic.com/trill";
import { create_test_routes } from "@/router/test_routes";
import { RouteConfig } from "vue-router";
import { create_auth_routes } from "./auth_routes";
import { create_company_routes } from "./company_routes";
import { create_discover_routes } from "./discover_routes";
import { RouteName, RoutePath } from "./route_constants";
import { create_not_found_route } from "./special_routes";
import { create_content_creation_routes } from "@/router/content_creation_routes";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_routes(release_stage: ReleaseStage): RouteConfig[] {
  const builder = new RouteBuilder(release_stage);

  builder.create(RoutePath.AUTH, create_auth_routes);
  builder.create(RoutePath.PUBLIC, create_public_routes);

  create_logged_in_routes(builder);

  builder.create_if_stage_in(
    [ReleaseStage.DEVELOPMENT, ReleaseStage.LOCAL, ReleaseStage.STAGING],
    RoutePath.TEST,
    create_test_routes
  );
  builder.create(RoutePath.CONTENT_CREATION, create_content_creation_routes);

  builder.add(create_not_found_route("*"));

  return builder.build();
}

function create_logged_in_routes(builder: RouteBuilder): RouteBuilder {
  /* eslint-disable prettier/prettier */
  return builder
    .add({
      path: "/",
      name: "home",
      components: {
        default: () => import("@/vue/views/home/Home.vue"),
        left_sidebar: () => import("@/vue/views/home/LeftSidebar.vue"),
        right_sidebar: () => import("@/vue/views/home/RightSidebar.vue"),
      },
    })
    .create(RoutePath.COMPANY, create_company_routes)
    .create(RoutePath.PERSON, create_person_routes)
    .create(RoutePath.DISCOVER, create_discover_routes)
    .create(RoutePath.PROFILE, create_profile_routes)
    .add({
      path: "/search",
      name: RouteName.SEARCH_RESULTS,
      meta: {
        role: "api_search",
      },
      component: () => import("@/vue/views/search/SearchResults.vue"),
      props: (route) => ({
        search_term: route.query.search_term,
      }),
    })
    .add({
      path: RoutePath.WATCH_LIST,
      meta: {
        role: "api_watchlists",
      },
      components: {
        default: RouterViewContainer,
        left_sidebar: () =>
          import(
            "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
          ),
      },
      props: {
        left_sidebar: () => ({
          route_root: { path: RoutePath.WATCH_LIST },
        }),
      }
    })
    .enter()
      .add({
        path: "companies",
        component: () =>
          import("@/vue/components/watchlist/CompanyWatchlist.vue"),
        meta: {
          translation_key: "router.watchlist.companies",
        },
      })
      .add({
        path: "persons",
        component: () =>
          // import("@/vue/components/watchlist/PersonWatchlist.vue"),
          import("@/vue/views/NotFoundPage.vue"),
        meta: {
          translation_key: "router.watchlist.persons",
          disabled: true,
        },
      })
      .add({
        path: "",
        redirect: "companies",
      })
    .exit()
    .add({
      path: "/initial-sub",
      component: RouterViewContainer,
      meta: {
        hidden_in_navigation: true,
        accessible_before_initial_sub: true,
      },
    })
    .enter()
      .add({
        path: "choose-product",
        name: "initial-subscription",
        component: () => import("@/vue/views/subscriptions/initial/ChooseProduct.vue"),
      })
      .add({
        path: "choose-payment-method",
        name: "initial-sub-choose-payment-method",
        component: () => import("@/vue/views/subscriptions/initial/ChoosePaymentMethod.vue"),
        props: (route) => ({
          product_translation_key: route.query.product_translation_key,
          price_id: route.query.price_id,
        })
      })
      .add({
        path: "done",
        component: () => import("@/vue/views/subscriptions/initial/CreationDone.vue"),
        props: (route) => ({
          product_translation_key: route.query.product_translation_key,
          price_id: route.query.price_id,
          session_id: route.query.session_id,
        })
      })
    .exit()

  // {
  //   path: RoutePath.COMPARE,
  //   meta: {
  //     translation_key: "router.compare.name",
  //   },
  //   components: {
  //     default: () => import("@/vue/views/compare/Compare.vue"),
  //     left_sidebar: () =>
  //       import(
  //         "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
  //       ),
  //   },
  //   props: {
  //     default: (route: Route) => ({
  //       security_ids: security_id_list_from_route_query(route),
  //       route_root: { path: RoutePath.COMPARE },
  //     }),
  //     left_sidebar: () => ({
  //       route_root: { path: RoutePath.COMPARE },
  //     }),
  //   },
  //   children: [
  //     {
  //       path: "company-characters",
  //       name: RouteName.COMPARE_DEFAULT,
  //       meta: {
  //         translation_key: "router.compare.company_characters.name",
  //       },
  //       component: () => import("@/vue/views/compare/CompanyCharacters.vue"),
  //       props: (route: Route) => ({
  //         security_ids: security_id_list_from_route_query(route),
  //       }),
  //     },
  //     {
  //       path: "",
  //       redirect: "company-characters",
  //     },
  //   ],
  // },
  // {
  //   path: RoutePath.SETTINGS,
  //   components: {
  //     default: RouterViewContainer,
  //     left_sidebar: () =>
  //       import(
  //         "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
  //       ),
  //   },
  //   props: {
  //     left_sidebar: () => ({
  //       route_root: { path: RoutePath.SETTINGS },
  //     }),
  //   },
  //   children: [
  //     {
  //       path: "company-character",
  //       component: () => import("@/vue/views/settings/CompanyCharacter.vue"),
  //       meta: {
  //         translation_key: "router.settings.company_character.name",
  //       },
  //     },
  //     {
  //       path: "",
  //       redirect: "company-character",
  //     },
  //   ],
  // },
  /* eslint-enable prettier/prettier */
}
