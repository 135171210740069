export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export function get_breakpoints_from_css(): Record<Breakpoint, number> {
  return {
    xs: get_breakpoint_from_css("xs"),
    sm: get_breakpoint_from_css("sm"),
    md: get_breakpoint_from_css("md"),
    lg: get_breakpoint_from_css("lg"),
    xl: get_breakpoint_from_css("xl"),
    xxl: get_breakpoint_from_css("xxl"),
  };
}

function get_breakpoint_from_css(breakpoint: Breakpoint): number {
  let width = getComputedStyle(document.body).getPropertyValue(`--breakpoint-${breakpoint}`);
  const pxIndex = width.toLowerCase().indexOf("px");
  if (pxIndex > 0) {
    width = width.substring(0, pxIndex);
  }

  return parseFloat(width);
}
