export interface AuthState {
  readonly is_logged_in: boolean;
  readonly roles: string[];
  readonly ever_had_subs: boolean;
  readonly has_alive_sub: boolean;
}

export function default_auth_state(): AuthState {
  return {
    is_logged_in: false,
    roles: [],
    ever_had_subs: false,
    has_alive_sub: false,
  };
}
