import { RouteBuilder } from "@xelonic.com/trill";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_test_routes(path: string, builder: RouteBuilder): void {
  /* eslint-disable prettier/prettier */
  builder.add({
    path: path,
    components: {
      default: RouterViewContainer,
      left_sidebar: () =>
        import(
          "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
        ),
    },
    props: {
      left_sidebar: () => ({
        route_root: { path: path },
      }),
    },
    meta: {
      internal: true,
    }
  })
  .enter()
    .add({
      path: "charts",
      component: () => import("@/vue/views/test/Charts.vue"),
      meta: {
        label: "Charts",
      },
    })
    .add({
      path: "components",
      component: RouterViewContainer,
      meta: {
        label: "Components",
      },
    })
    .enter()
      .add({
        path: "bootstrap",
        component: () => import("@/vue/views/test/components/general/Bootstrap.vue"),
        meta: {
          label: "Bootstrap",
        },
      })
      .add({
        path: "tree-table",
        component: () => import("@/vue/views/test/components/general/TreeTable.vue"),
        meta: {
          label: "TreeTable",
        },
      })
      .add({
        path: "page-tour",
        component: () => import("@/vue/views/test/components/general/PageTour.vue"),
        meta: {
          label: "Page Tour",
        },
      })
    .exit()
    .add({
      path: "activity-feed",
      component: () => import("@/vue/views/test/ActivityFeed.vue"),
      meta: {
        label: "Activity Feed",
      },
    })
    .add({
      path: "search-results",
      component: () => import("@/vue/views/test/SearchResults.vue"),
      meta: {
        label: "Search Results",
      },
    })
    .add({
      path: "growth",
      component: () => import("@/vue/views/test/Growth.vue"),
      meta: {
        label: "Growth",
      },
    })
    .add({
      path: "companies",
      component: RouterViewContainer,
      meta: {
        label: "Companies",
      },
    })
    .enter()
      .add({
        path: "overview",
        component: () =>
          import("@/vue/views/test/companies/Overview.vue"),
        meta: {
          label: "Overview",
        },
      })
      .add({
        path: "tags",
        component: () =>
          import("@/vue/views/test/companies/CompanyTags.vue"),
        meta: {
          label: "Company Tags",
        },
      })
      .add({
        path: "finances-chart",
        component: () =>
          import("@/vue/views/test/companies/FinancesChart.vue"),
        meta: {
          label: "Finances Chart",
        },
      })
      .add({
        path: "fundamental-chart",
        component: () =>
          import("@/vue/views/test/companies/FundamentalChart.vue"),
        meta: {
          label: "Fundamental Chart",
        },
      })
      .add({
        path: "market-cdf-chart",
        component: () =>
          import("@/vue/views/test/companies/MarketCDFChart.vue"),
        meta: {
          label: "Market CDF",
        },
      })
    .exit()
    .add({
      path: "subscriptions",
      component: RouterViewContainer,
      meta: {
        label: "Subscriptions",
      },
    })
    .enter()
      .add({
        path: "products",
        component: () =>
          import("@/vue/views/test/subscriptions/Products.vue"),
        meta: {
          label: "Products",
        },
      })
      .add({
        path: "",
        redirect: "products"
      })
    .exit()
    .add({
      path: "discovery",
      component: RouterViewContainer,
      meta: {
        label: "Discovery",
      },
    })
    .enter()
      .add({
        path: "companies",
        component: () =>
          import("@/vue/views/test/CompanyDiscoveryCards.vue"),
        meta: {
          label: "Company Discovery",
        },
      })
    .exit()
    .add({
      path: "colors",
      component: () =>
        import("@/vue/views/test/Colors.vue"),
      meta: {
        label: "Colors",
      },
    })
    .add({
      path: "",
      redirect: "charts",
    })
  .exit()
  /* eslint-enable prettier/prettier */
}
