import { ticker_tag_from_api } from "@/api/companies/ticker_tag";
import { entity_from_api } from "@/api/entities/entity";
import { GeneralInfo } from "@/model/companies/general_info";
import { NanoInfo } from "@/model/companies/nano_info";
import { map_unthrown, possibly_defined, try_call } from "@xelonic.com/trill";
import { Logger } from "@xelonic.com/trill";
import { CompanyGeneralInfo as APIGeneralInfo, CompanyNanoInfo as APINanoInfo } from "@xelonic.com/xelonic-api";
import { address_from_api } from "../address";
import { verify_field_defined } from "@xelonic.com/trill";
import { security_id_from_api } from "../securities";
import { ticker_code_from_api } from "../ticker_code";
import { company_environment_from_api } from "./company_environment";

export function general_info_from_api(info: APIGeneralInfo, logger: Logger): GeneralInfo {
  const type_name = "CompanyGeneralInfo";
  verify_field_defined(info, type_name, "entity");

  const entity = entity_from_api(info.entity!, logger);

  const similar_companies = info.similar_companies ? nano_infos_from_api(info.similar_companies, logger) : [];

  return new GeneralInfo(entity, {
    description: info.description,
    description_source_url: info.description_source_url,
    address: try_call(info.address, logger, address_from_api),
    url: info.url,
    environment: try_call(info.environment, logger, company_environment_from_api),
    primary_ticker_code: try_call(info.primary_ticker_code, logger, ticker_code_from_api),
    employees_count: info.details?.employees_count,
    tags: map_unthrown(info.tags, logger, ticker_tag_from_api),
    similar_companies: similar_companies,
    has_holdings: possibly_defined(info.has_holdings),
    has_ownership: possibly_defined(info.has_ownership),
  });
}

function nano_infos_from_api(infos: APINanoInfo[], logger: Logger): NanoInfo[] {
  return map_unthrown(infos, logger, nano_info_from_api);
}

function nano_info_from_api(info: APINanoInfo): NanoInfo {
  const type_name = "CompanyNanoInfo";
  verify_field_defined(info, type_name, "name");
  verify_field_defined(info, type_name, "primary_symbol");
  verify_field_defined(info, type_name, "entity_id");

  const entity_id = security_id_from_api(info.entity_id!);

  return new NanoInfo(info.name!, info.primary_symbol!, entity_id);
}
