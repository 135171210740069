import { Configuration } from "@/model/config/configuration";
import { create_axios_instance, Tracker } from "@xelonic.com/trill";
import { Backend, install_backend } from "@/plugins/Backend";
import { install_configuration } from "@/plugins/ConfigurationPlugin";
import { install_icons } from "@/plugins/Icons";
import { install_vue_i18n } from "@/plugins/i18n";
import { install_languages } from "@/plugins/Languages";
import { install_loader } from "@xelonic.com/trill";
import { install_navigation_history } from "@/plugins/NavigationHistory";
import { install_util } from "@/plugins/Util";
import { install_value_formatter } from "@/plugins/ValueFormatter";
import { install_xrouter } from "@/plugins/XRouter";
import { TrillTrackingSettingsRepository } from "@/repositories/trill_tracking_settings_repository";
import { install_localization, install_logger, install_tracking, install_vee_validate } from "@xelonic.com/trill";

import Vue from "vue";
import VueForceNextTick from "vue-force-next-tick";
import VueI18n from "vue-i18n";
import VueRouter, { RouteConfig } from "vue-router";
import { install_event_bus } from "./plugins/EventBus";
import { install_route_root } from "./plugins/RouteRoot";
import { install_user_preferences, UserPreferences } from "./plugins/UserPreferences";
import { ModalPlugin } from "bootstrap-vue/esm/components/modal";
import { PopoverPlugin } from "bootstrap-vue/esm/components/popover";
import { TooltipPlugin } from "bootstrap-vue/esm/components/tooltip";
import { get_user_preferences_repository } from "@/repositories/user/user_preferences_repository";
import { TrackingApi } from "@xelonic.com/xelonic-api";
import { install_auth } from "@/plugins/AuthPlugin";
import { get_authorization_service } from "@/services/authorization_service";

interface Globals {
  i18n: VueI18n;
  router: VueRouter;
}

export function register_vue_plugins(
  instance: typeof Vue,
  routes: RouteConfig[],
  config: Configuration,
  i18n?: VueI18n
): Globals {
  if (!i18n) {
    i18n = install_vue_i18n(instance);
  }

  const router = register_3rd_party_vue_plugins(instance, routes);

  install_icons(instance);
  install_vee_validate(instance, i18n.locale, i18n);
  const logger = install_logger(instance);
  install_configuration(instance, config);
  const event_bus = install_event_bus(instance);

  const axios = create_axios_instance(config.api_url);
  const backend = install_backend(instance, axios, config.api_url);

  const authz = get_authorization_service(backend, router, config.subscriptions_enabled);
  const auth = install_auth(instance, backend, router, authz);

  install_xrouter(instance, router, routes);

  const languages = install_languages(instance);

  const user_prefs_repo = get_user_preferences_repository(backend);

  const user_prefs = install_user_preferences(instance, i18n, user_prefs_repo, languages, auth, event_bus);

  setup_tracking(instance, user_prefs, router, backend, config);

  install_loader(instance, logger);
  install_navigation_history(instance, router);
  install_localization(instance, i18n);
  install_value_formatter(instance, i18n);
  install_util(instance);
  install_route_root(instance);

  install_bootstrap_vue_plugins(instance);

  return { i18n, router };
}

function register_3rd_party_vue_plugins(instance: typeof Vue, routes: RouteConfig[]): VueRouter {
  const router = new VueRouter({
    mode: "history",
    routes,
  });

  instance.config.productionTip = false;

  instance.use(VueRouter);
  instance.use(VueForceNextTick);

  return router;
}

function setup_tracking(
  instance: typeof Vue,
  user_prefs: UserPreferences,
  router: VueRouter,
  backend: Backend,
  config: Configuration
): Tracker {
  const tracking = backend.get_api("tracking", TrackingApi);
  return install_tracking(instance, user_prefs, router, new TrillTrackingSettingsRepository({ api: tracking }), {
    gtag_id: config.gtag_id,
    matomo:
      config.matomo_url && config.matomo_site_id
        ? {
            url: config.matomo_url,
            site_id: config.matomo_site_id,
          }
        : undefined,
  });
}

function install_bootstrap_vue_plugins(instance: typeof Vue) {
  instance.use(ModalPlugin);
  instance.use(PopoverPlugin);
  instance.use(TooltipPlugin);
}
