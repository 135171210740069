import { Languages } from "@/plugins/Languages";
import { UserPreferencesRepository } from "@/repositories/user/user_preferences_repository";
import { AuthenticationService } from "@/services/authentication_service";
import { Logger, new_logger } from "@xelonic.com/trill";
import { localize as vee_localize } from "vee-validate";
import Vue, { reactive, watchEffect } from "vue";
import VueI18n from "vue-i18n";
import debounce from "lodash.debounce";
import { CompanyWatchlistSettings } from "@/model/user/company_watchlist_settings";
import { Language } from "@/model/language";
import { EventBus } from "@/plugins/EventBus";
import { load_messages_async } from "@/plugins/i18n";

declare module "vue/types/vue" {
  interface Vue {
    $user_preferences: UserPreferences;
  }
}

class ReactiveState {
  constructor(selected_language: Language) {
    this.selected_language = selected_language;
  }

  selected_language: Language;
}

export function install_user_preferences(
  vue: typeof Vue,
  i18n: VueI18n,
  preferences_repo: UserPreferencesRepository,
  languages: Languages,
  auth: AuthenticationService,
  event_bus: EventBus
): UserPreferences {
  vue.use(UserPreferences, { i18n, preferences_repo, languages, auth, event_bus });
  return vue.prototype.$user_preferences;
}

export class UserPreferences {
  static install(
    vue: typeof Vue,
    options: {
      i18n: VueI18n;
      preferences_repo: UserPreferencesRepository;
      languages: Languages;
      auth: AuthenticationService;
      event_bus: EventBus;
    }
  ): void {
    vue.prototype.$user_preferences = new UserPreferences(
      options.languages,
      options.i18n,
      options.preferences_repo,
      options.auth,
      options.event_bus
    );
  }

  constructor(
    languages: Languages,
    i18n: VueI18n,
    preferences_repo: UserPreferencesRepository,
    auth: AuthenticationService,
    event_bus: EventBus
  ) {
    this.i18n_ = i18n;
    this.preferences_repo_ = preferences_repo;
    this.auth_ = auth;
    this.languages_ = languages;
    this.event_bus_ = event_bus;
    this.logger_ = new_logger("user_preferences_repository");

    this.save_debounced_ = debounce(() => this.save_now(), 1000);

    watchEffect(() => {
      if (this.auth_.is_active) {
        this.load();
      }
    });

    this.state_ = reactive(new ReactiveState(this.get_language(i18n.locale))) as ReactiveState;
    this.load();
  }

  is_language_selected(language: Language): boolean {
    return this.selected_language.locale == language.locale;
  }

  get selected_language(): Language {
    return this.state_.selected_language;
  }

  async set_selected_language(language: Language) {
    const messages = await load_messages_async(language.locale);
    this.i18n_.setLocaleMessage(language.locale, messages);
    this.i18n_.locale = language.locale;

    this.state_.selected_language = language;
    vee_localize(language.locale);

    this.preferences_repo_.get().locale = language.locale;
    this.save_debounced_();

    this.event_bus_.emit("locale-changed", language.locale);
  }

  get accepted_cookies(): boolean {
    return this.preferences_repo_.get().accepted_cookies;
  }

  set accepted_cookies(value: boolean) {
    this.preferences_repo_.get().accepted_cookies = value;
    this.save_debounced_();
  }

  info_sifted(id: string, version: number): boolean {
    return this.preferences_repo_.get().info_sifted(id, version);
  }

  set_info_sifted(id: string, version: number): void {
    this.preferences_repo_.get().set_info_sifted(id, version);
    this.save_debounced_();
  }

  get company_watchlist(): CompanyWatchlistSettings {
    return this.preferences_repo_.get().company_watchlist;
  }

  save(): void {
    this.save_debounced_();
  }

  private async load(): Promise<void> {
    await this.preferences_repo_.load_preferences(!this.auth_.is_active);
    await this.select_language_by_locale(this.preferences_repo_.get().locale);
  }

  private save_now(): void {
    this.preferences_repo_.save_preferences(!this.auth_.is_active);
  }

  private async select_language_by_locale(locale?: string): Promise<void> {
    await this.set_selected_language(this.get_language(locale));
  }

  private get_language(locale?: string): Language {
    if (!locale) {
      locale = this.default_locale_;
    }

    if (!this.languages_.items[locale]) {
      const dash_index = locale.indexOf("-");
      if (dash_index > 0) {
        locale = locale.substring(0, dash_index);
      }
    }

    if (!this.languages_.items[locale]) {
      this.logger_.error(`unknown locale: ${locale}. Falling back to english.`);
      locale = this.default_locale_;
    }

    return this.languages_.items[locale];
  }

  private readonly default_locale_ = "en-us";
  private readonly i18n_: VueI18n;
  private readonly preferences_repo_: UserPreferencesRepository;
  private readonly auth_: AuthenticationService;
  private readonly languages_: Languages;
  private readonly event_bus_: EventBus;
  private readonly logger_: Logger;
  private readonly save_debounced_: () => void;
  private readonly state_: ReactiveState;
}
