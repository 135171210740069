import { EntityType } from "@/model/entity_type";
import { EntityType as APIEntityType } from "@xelonic.com/xelonic-api";

export function entity_type_from_api(entity_type: APIEntityType): EntityType {
  switch (entity_type) {
    case APIEntityType.Person:
      return EntityType.PERSON;
    case APIEntityType.Company:
      return EntityType.COMPANY;
  }
}

export function entity_type_to_api(entity_type: EntityType): APIEntityType {
  switch (entity_type) {
    case EntityType.PERSON:
      return APIEntityType.Person;
    case EntityType.COMPANY:
      return APIEntityType.Company;
  }
}

export function entity_type_to_entity_group(entity_type: EntityType): "persons" | "companies" {
  switch (entity_type) {
    case EntityType.PERSON:
      return "persons";
    case EntityType.COMPANY:
      return "companies";
  }
}
