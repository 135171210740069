<template>
  <li class="node">
    <div>
      <div
        class="pr-1 d-inline-block sidebar-no-close-element"
        style="position: relative"
        :class="{ invisible: !has_children }"
        @click="toggle_children_collapsed"
      >
        <span class="valign-text-bottom" :class="{ invisible: are_children_collapsed }">
          <font-awesome-icon :icon="chevron_down_icon" />
        </span>
        <span
          class="valign-text-bottom"
          style="position: absolute; top: 0; left: 0"
          :class="{ invisible: !are_children_collapsed }"
        >
          <font-awesome-icon :icon="chevron_right_icon" />
        </span>
      </div>
      <span v-if="item.disabled" class="link--disabled disabled-text">
        {{ item.label }}
      </span>
      <b-link v-else :to="{ path: item.path }" class="link" :class="link_classes">{{ item.label }}</b-link>
    </div>
    <ul v-if="has_children" :class="{ 'd-none': are_children_collapsed }">
      <router-tree-navigation-node
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :level="level + 1"
        :auto_collapse="auto_collapse"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { RouteName } from "@/router/route_constants";
import "reflect-metadata";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BLink } from "bootstrap-vue/esm/components/link";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface RouteNode {
  label: string;
  path: string;
  children: RouteNode[];
  disabled: boolean;
  name?: RouteName;
}

@Component({
  name: "router-tree-navigation-node",
  components: { BLink },
})
export default class RouterTreeNavigationNode extends Vue {
  @Prop({ required: true })
  readonly item!: RouteNode;

  @Prop({ default: 0 })
  readonly level!: number;

  /**
   * If defined, the children are collapsed/expanded automatically based on the current path.
   */
  @Prop({ default: null })
  readonly auto_collapse!: string;

  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get link_classes(): string {
    let classes = `link--level-${this.level}`;
    if (this.is_active) {
      classes += " link--active";
    }

    return classes;
  }

  get is_active(): boolean {
    return this.$route.path.startsWith(this.item.path);
  }

  get has_children(): boolean {
    return this.item.children.length > 0;
  }

  get are_children_collapsed(): boolean {
    return this.are_children_collapsed_;
  }

  toggle_children_collapsed(): void {
    this.are_children_collapsed_ = !this.are_children_collapsed_;
  }

  get chevron_down_icon(): IconDefinition {
    return faChevronDown;
  }

  get chevron_right_icon(): IconDefinition {
    return faChevronRight;
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------

  @Watch("$route", { immediate: true, deep: true })
  on_route_changed(): void {
    this.are_children_collapsed_ = this.auto_collapse !== null && !this.is_active;
  }

  private are_children_collapsed_ = false;
}
</script>

<style lang="scss" scoped>
.node ul {
  list-style-type: none;
  padding-left: 1.25rem;
}
.node {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.link {
  &--level-0 {
    font-weight: 700;
  }

  &--level-1.link--active {
    font-weight: 600;
  }
  &--level-2.link {
    color: $gray-600;
    font-size: 0.9rem;
    margin-right: 0;
  }
  &--level-2.link--active {
    font-weight: 600;
    color: $blue;
  }
  &--active {
    color: $blue;
  }

  &--disabled {
    font-weight: 500;
  }
}

.valign-text-bottom {
  vertical-align: text-bottom;
}
</style>
