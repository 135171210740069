import { RouteBuilder } from "@xelonic.com/trill";
import { RouteName } from "./route_constants";
import { RouterViewContainer } from "@xelonic.com/trill";

const login_path = "login";
const credentials_path = "credentials";

export function create_auth_routes(base_path: string, builder: RouteBuilder): void {
  /* eslint-disable prettier/prettier */
  builder.add({
      path: base_path,
      component: () => import("@/vue/views/auth/Authentication.vue"),
    })
    .enter()
      .add({
        path: login_path,
        component: RouterViewContainer,
      })
      .enter()
        .add({
          path: credentials_path,
          name: "login",
          component: () => import("@/vue/views/auth/Login.vue"),
          props: (route) => ({
            redirect_path: route.query.redirect_path,
          }),
        })
        .add({
          path: "auth-code",
          component: () => import("@/vue/views/auth/LoggingIn.vue"),
          props: (route) => ({
            source: "auth_code",
            auth_code: route.query.code,
          }),
        })
        .add({
          path: "logging-in-credentials",
          name: "logging-in-credentials",
          component: () => import("@/vue/views/auth/LoggingIn.vue"),
          props: (route) => ({
              source: "credentials",
              username: route.query.username,
              password: route.query.password,
          }),
        })
        .add({
          path: "",
          redirect: credentials_path,
        })
      .exit()
      .add({
        path: "password-reset-request",
        name: RouteName.PASSWORD_RESET_REQUEST,
        component: () => import("@/vue/views/auth/PasswordResetRequest.vue"),
      })
      .add({
        path: "password-reset",
        component: () => import("@/vue/views/auth/PasswordReset.vue"),
      })
      .add({
        path: "registration",
        name: "registration",
        component: () => import("@/vue/views/auth/Registration.vue"),
      })
      .add({
        path: "",
        redirect: login_path,
      })
      .add({
        path: "account-deletion-error",
        component: () => import("@/vue/views/auth/AccountDeletionError.vue"),
        name: RouteName.ACCOUNT_DELETION_ERROR,
        props: (route) => ({
          user_id: route.query.user_id,
        }),
      })
    .exit()
  /* eslint-enable prettier/prettier */
}
