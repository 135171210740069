import { Identifier } from "@/model/identifier";
import { compare_less } from "@xelonic.com/trill";

export class TickerTag implements Identifier {
  constructor(
    id: string,
    name: string,
    options?: {
      important?: boolean;
      rank?: number;
      unique?: boolean;
    }
  ) {
    this.id_ = id;
    this.name_ = name;
    this.important_ = options?.important ?? false;
    this.rank_ = options?.rank ?? Number.MAX_VALUE;
    this.unique_ = options?.unique ?? false;
    this.label_ = id;
  }

  get id(): string {
    return this.id_;
  }

  get name(): string {
    return this.name_;
  }

  get label(): string {
    return this.label_;
  }

  set label(value: string) {
    this.label_ = value;
  }

  get important(): boolean {
    return this.important_;
  }

  get rank(): number {
    return this.rank_;
  }

  get unique(): boolean {
    return this.unique_;
  }

  private readonly id_: string;
  private readonly name_: string;
  private readonly important_: boolean;
  private readonly rank_: number;
  private readonly unique_: boolean;
  private label_: string;
}

export function compare_ticker_tag_importance_desc(lhs: TickerTag, rhs: TickerTag): number {
  if (lhs.important && !rhs.important) {
    return -1;
  }

  if (!lhs.important && rhs.important) {
    return 1;
  }

  return compare_less(lhs.rank, rhs.rank);
}
