import { RouteName } from "@/router/route_constants";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";

declare module "vue/types/vue" {
  interface Vue {
    $navigation_history: NavigationHistory;
  }
}

export function install_navigation_history(vue: typeof Vue, router: VueRouter): NavigationHistory {
  vue.use(NavigationHistory, { router });
  return vue.prototype.$navigation_history;
}

export default class NavigationHistory {
  static install(vue: typeof Vue, options: { router: VueRouter }): void {
    vue.prototype.$navigation_history = new NavigationHistory(options.router);
  }

  constructor(router: VueRouter) {
    router.beforeEach(this.handle_route_change.bind(this));
  }

  get previous_route(): Route | undefined {
    return this._previous_route;
  }

  previous_route_equal_to(route_name: RouteName): boolean {
    if (this._previous_route === undefined) {
      return false;
    }

    return this._previous_route.name === route_name;
  }

  private handle_route_change(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    this._previous_route = from;
    next();
  }

  private _previous_route?: Route;
}
