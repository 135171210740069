export const Epsilon = 0.00001;

export function is_equal(a: number, b: number): boolean {
  return Math.abs(a - b) < Epsilon;
}

export class Point {
  constructor(x?: number, y?: number) {
    if (x && y) {
      this.x_ = x;
      this.y_ = y;
    }
  }

  get x(): number {
    return this.x_;
  }

  set x(value: number) {
    this.x_ = value;
  }

  get y(): number {
    return this.y_;
  }

  set y(value: number) {
    this.y_ = value;
  }

  equal_to(other: Point): boolean {
    return is_equal(this.x, other.x) && is_equal(this.y, other.y);
  }

  toString(): string {
    return `(${this.x},${this.y})`;
  }

  private x_ = 0;
  private y_ = 0;
}

export class Dimensions {
  readonly width: number = 0;
  readonly height: number = 0;

  constructor(width?: number, height?: number) {
    if (width && height) {
      this.width = width;
      this.height = height;
    }
  }

  equal_to(other: Dimensions): boolean {
    return is_equal(this.width, other.width) && is_equal(this.height, other.height);
  }

  center(): Point {
    return new Point(this.width / 2, this.height / 2);
  }

  toString(): string {
    return `${this.width}x${this.height}`;
  }

  get is_empty(): boolean {
    return this.width < 1 && this.height < 1;
  }
}

export class Box {
  static empty(): Box {
    return new Box(new Point(+Infinity, +Infinity), new Point(-Infinity, -Infinity));
  }

  constructor(top_left: Point, bottom_right: Point) {
    this.top_left_ = top_left;
    this.bottom_right_ = bottom_right;
  }

  get top_left(): Point {
    return this.top_left_;
  }

  get bottom_right(): Point {
    return this.bottom_right_;
  }

  extend(other: Box): void {
    this.top_left_.x = Math.min(this.top_left_.x, other.top_left.x);
    this.top_left_.y = Math.min(this.top_left_.y, other.top_left.y);

    this.bottom_right_.x = Math.max(this.bottom_right_.x, other.bottom_right.x);
    this.bottom_right_.y = Math.max(this.bottom_right_.y, other.bottom_right.y);
  }

  intersects(other: Box): boolean {
    if (other.top_left.x > this.bottom_right.x || other.bottom_right.x < this.top_left.x) {
      return false;
    }

    if (other.top_left.y > this.bottom_right.y || other.bottom_right.y < this.top_left.y) {
      return false;
    }

    return true;
  }

  private readonly top_left_;
  private readonly bottom_right_;
}
