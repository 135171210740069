import {
  CompanyWatchlistSettings as APICompanyWatchlistSettings,
  SortedByField as APISortedByField,
} from "@xelonic.com/xelonic-api";
import { CompanyWatchlistSettings } from "@/model/user/company_watchlist_settings";
import { has_items } from "@xelonic.com/trill";
import { Logger } from "@xelonic.com/trill";

export function company_watchlist_settings_from_api(
  settings: APICompanyWatchlistSettings,
  logger: Logger
): CompanyWatchlistSettings {
  let sorted_by_field: string | undefined;
  let sort_ascending: boolean | undefined;

  if (has_items(settings.sorted_by_fields)) {
    if (settings.sorted_by_fields.length > 1) {
      logger.warn(
        "company watchlist settings' sorted_by_fields contains multiple items, but can handle only one. Taking first one."
      );
    } else {
      const field = settings.sorted_by_fields[0];
      sorted_by_field = field.name;
      sort_ascending = field.sort_ascending;
    }
  }

  return new CompanyWatchlistSettings({
    visible_fields: settings.visible_fields,
    sorted_by: sorted_by_field,
    sort_ascending: sort_ascending,
  });
}

export function company_watchlist_settings_to_api(settings: CompanyWatchlistSettings): APICompanyWatchlistSettings {
  let sorted_by_fields: APISortedByField[] | undefined;

  if (settings.sorted_by) {
    sorted_by_fields = [
      {
        name: settings.sorted_by,
        sort_ascending: settings.sort_ascending,
      },
    ];
  }

  return {
    visible_fields: settings.visible_fields,
    sorted_by_fields: sorted_by_fields,
  };
}
