import { create_loader } from "@xelonic.com/trill";
import { Configuration as APIConfiguration } from "@xelonic.com/xelonic-api";
import { AxiosInstance } from "axios";
import Vue from "vue";
import { APIConstructor, RepositoryStore, RepositoryConstructor } from "@/api/repository_store";

declare module "vue/types/vue" {
  interface Vue {
    $backend: Backend;
  }
}

export function install_backend(vue: typeof Vue, axios: AxiosInstance, api_base_url: string): Backend {
  vue.use(Backend, { axios, api_base_url });
  return vue.prototype.$backend;
}

/**
 * Manages connections to APIs of dash-back.
 *
 * Use this to get an instance to an API of dash-back.
 */
export class Backend implements RepositoryStore {
  static install(
    vue: typeof Vue,
    options: {
      axios: AxiosInstance;
      api_base_url: string;
    }
  ): void {
    vue.prototype.$backend = new Backend(options.axios, options.api_base_url);
  }

  constructor(axios: AxiosInstance, api_base_url: string) {
    this.axios = axios;
    this.api_base_url = api_base_url;
  }

  get_api<APIType>(name: string, apiType: APIConstructor<APIType>): APIType {
    if (!this.apis_[name]) {
      this.apis_[name] = new apiType(this.create_api_configuration(), this.api_base_url, this.axios);
    }

    return this.apis_[name] as APIType;
  }

  get_repository<APIType, RepositoryType>(
    name: string,
    apiConstructor: APIConstructor<APIType>,
    repoConstructor: RepositoryConstructor<APIType, RepositoryType>
  ): RepositoryType {
    if (!this.repositories_[name]) {
      this.repositories_[name] = new repoConstructor({
        api: this.get_api(name, apiConstructor),
        load: create_loader(),
        timestep_mins: this.timestep_mins_,
      });
    }

    return this.repositories_[name] as RepositoryType;
  }

  get_service<ServiceType>(name: string, svc_constructor: () => ServiceType): ServiceType {
    if (!this.services_[name]) {
      this.services_[name] = svc_constructor();
    }

    return this.services_[name] as ServiceType;
  }

  private create_api_configuration(): APIConfiguration {
    const config = new APIConfiguration();
    config.baseOptions = { withCredentials: true }; // send httponly cookies
    return config;
  }

  private readonly axios: AxiosInstance;
  private readonly api_base_url: string;
  private readonly timestep_mins_ = 15;

  private apis_: Record<string, unknown> = {};
  private repositories_: Record<string, unknown> = {};
  private services_: Record<string, unknown> = {};
}
