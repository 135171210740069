import { NanoInfo } from "@/model/companies/nano_info";
import { TickerTag } from "@/model/companies/ticker_tag";
import { Entity } from "@/model/entities/entity";
import { null_if_empty, null_if_not_defined } from "@xelonic.com/trill";
import { Address } from "../address";
import { TickerCode } from "../ticker_code";
import { CompanyEnvironment } from "./company_environment";

export class GeneralInfo {
  constructor(
    entity: Entity,
    options?: {
      description?: string;
      description_source_url?: string;
      address?: Address;
      url?: string;
      environment?: CompanyEnvironment;
      primary_ticker_code?: TickerCode;
      employees_count?: number;
      tags?: TickerTag[];
      similar_companies?: NanoInfo[];
      has_holdings?: boolean;
      has_ownership?: boolean;
    }
  ) {
    this.entity_ = entity;
    this.description_ = options?.description ?? "";
    this.description_source_url_ = null_if_empty(options?.description_source_url);
    this.address_ = null_if_not_defined(options?.address);
    this.url_ = null_if_empty(options?.url);
    this.environment_ = null_if_not_defined(options?.environment);
    this.primary_ticker_code_ = null_if_not_defined(options?.primary_ticker_code);
    this.employees_count_ = null_if_not_defined(options?.employees_count);
    this.tags_ = null_if_empty(options?.tags);
    this.similar_companies_ = null_if_empty(options?.similar_companies);
    this.has_holdings_ = null_if_not_defined(options?.has_holdings);
    this.has_ownership_ = null_if_not_defined(options?.has_ownership);
  }

  get entity(): Entity {
    return this.entity_;
  }

  get description(): string | null {
    return this.description_;
  }

  get description_source_url(): string | null {
    return this.description_source_url_;
  }

  get address(): Address | null {
    return this.address_;
  }

  get url(): string | null {
    return this.url_;
  }

  get environment(): CompanyEnvironment | null {
    return this.environment_;
  }

  get primary_ticker_code(): TickerCode | null {
    return this.primary_ticker_code_;
  }

  get employees_count(): number | null {
    return this.employees_count_;
  }

  get similar_companies(): NanoInfo[] | null {
    return this.similar_companies_;
  }

  get tags(): TickerTag[] | null {
    return this.tags_;
  }

  get has_holdings(): boolean | null {
    return this.has_holdings_;
  }

  get has_ownership(): boolean | null {
    return this.has_ownership_;
  }

  private readonly entity_: Entity;
  private readonly description_: string | null;
  private readonly description_source_url_: string | null;
  private readonly address_: Address | null;
  private readonly url_: string | null;
  private readonly environment_: CompanyEnvironment | null;
  private readonly primary_ticker_code_: TickerCode | null;
  private readonly employees_count_: number | null;
  private readonly similar_companies_: NanoInfo[] | null;
  private readonly tags_: TickerTag[] | null;
  private readonly has_holdings_: boolean | null;
  private readonly has_ownership_: boolean | null;
}
