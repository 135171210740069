import { EntityConnection } from "@/model/entities/entity_connection";
import { EntityType } from "@/model/entity_type";
import { GeneralInfo } from "@/model/persons/general_info";
import { RankedEntity } from "@/model/ranked_securities/ranked_entity";
import { defined_if_has_items } from "@/util/util";

export class RankedPerson extends RankedEntity {
  constructor(rank: number, general_info: GeneralInfo, top_holdings?: EntityConnection[]) {
    super(rank, general_info.entity.id, EntityType.PERSON, {
      name: general_info.entity.name,
      logo: general_info.entity.logo ?? undefined,
    });

    this.general_info_ = general_info;
    this.top_holdings_ = defined_if_has_items(top_holdings);
  }

  get general_info(): GeneralInfo {
    return this.general_info_;
  }

  get top_holdings(): EntityConnection[] | undefined {
    return this.top_holdings_;
  }

  private readonly general_info_: GeneralInfo;
  private readonly top_holdings_?: EntityConnection[];
}
