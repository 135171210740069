import { EntityType } from "@/model/entity_type";
import { Resource } from "@/model/resource";
import { SecurityID } from "@/model/security_id";
import { null_if_not_defined } from "@xelonic.com/trill";

export class Entity {
  constructor(id: SecurityID, type: EntityType, options: { name?: string; logo?: Resource }) {
    this.id_ = id;
    this.type_ = type;
    this.name_ = options?.name ?? this.id_.formatted();
    this.logo_ = null_if_not_defined(options.logo);
  }

  get id(): SecurityID {
    return this.id_;
  }

  get type(): EntityType {
    return this.type_;
  }

  get name(): string {
    return this.name_;
  }

  get logo(): Resource | null {
    return this.logo_;
  }

  private readonly id_: SecurityID;
  private readonly type_: EntityType;
  private readonly name_: string;
  private readonly logo_: Resource | null;
}
