import { Entity } from "@/model/entities/entity";
import { EntityType } from "@/model/entity_type";
import { Resource } from "@/model/resource";
import { SecurityID } from "@/model/security_id";

export class RankedEntity extends Entity {
  constructor(rank: number, id: SecurityID, type: EntityType, options: { name?: string; logo?: Resource }) {
    super(id, type, options);

    this.rank_ = rank;
  }

  /**
   * Search result items are sorted by this. Highest rank first.
   */
  get rank(): number {
    return this.rank_;
  }

  private readonly rank_: number;
}
