export class CompanyEnvironment {
  constructor(industry?: string, sector?: string) {
    this.industry_ = industry ? industry : "";
    this.sector_ = sector ? sector : "";
  }

  get industry(): string {
    return this.industry_;
  }

  get sector(): string {
    return this.sector_;
  }

  private readonly industry_: string;
  private readonly sector_: string;
}
