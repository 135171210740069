<template>
  <b-alert :show="show" :variant="variant" class="xelonic-alert">
    <b-row>
      <b-col md="auto">
        <font-awesome-icon :icon="icon" size="2xl" />
      </b-col>
      <b-col class="d-flex align-items-center">
        <div>
          <slot></slot>
        </div>
      </b-col>
    </b-row>
  </b-alert>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BAlert } from "bootstrap-vue/esm/components/alert";
import { BRow, BCol } from "bootstrap-vue/esm/components/layout";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faPartyHorn } from "@fortawesome/pro-regular-svg-icons/faPartyHorn";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

/**
 * The InfoBox does the obvious, plus it syncs the "don't show again" state to the user preferences repository.
 *
 * slots:
 * - default: info box content
 */
@Component({ components: { BAlert, BCol, BRow } })
export default class Alert extends Vue {
  @Prop({ default: "info" })
  readonly variant!: "info" | "success" | "danger";

  @Prop({ default: true })
  readonly show!: boolean;

  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get icon(): IconDefinition {
    switch (this.variant) {
      case "info":
        return faCircleInfo;
      case "success":
        return faPartyHorn;
      case "danger":
        return faTriangleExclamation;
    }

    return faCircleInfo;
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------
}
</script>
<style lang="scss">
.xelonic-alert a {
  font-weight: bold;
}
</style>
