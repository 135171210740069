export class Location {
  constructor(latitude: number, longitude: number) {
    this.latitude_ = latitude;
    this.longitude_ = longitude;
  }

  get latitude(): number {
    return this.latitude_;
  }

  get longitude(): number {
    return this.longitude_;
  }

  private readonly latitude_: number;
  private readonly longitude_: number;
}
