import { create_configuration } from "@/model/config/configuration";
import { register_vue_plugins } from "@/vue";
import App from "@/vue/App.vue";
import { init_logging } from "@xelonic.com/trill";
import Vue from "vue";
import { create_routes } from "./router";

const config = create_configuration();
init_logging();

// register_service_worker(config.node_environment);

export const routes = create_routes(config.release_stage);

const globals = register_vue_plugins(Vue, routes, config);

new Vue({
  router: globals.router,
  i18n: globals.i18n,
  render: (h) => h(App),
}).$mount("#app");
