import { RouteBuilder } from "@xelonic.com/trill";
import { RouteName } from "@/router/route_constants";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_discover_routes(base_path: string, builder: RouteBuilder): RouteBuilder {
  /* eslint-disable prettier/prettier */
  return builder.add({
      path: base_path,
      name: "discovery",
      meta: {
        translation_key: "router.discover.name",
        role: "api_suggestions",
      },
      components: {
        default: RouterViewContainer,
        left_sidebar: () =>
          import(
            "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
          ),
      },
      props: {
        left_sidebar: () => ({
          route_root: { path: base_path },
        }),
      },
    })
    .enter()
      .add({
        path: "companies",
        meta: {
          translation_key: "router.discover.companies.name",
        },
        component: () =>
          import(
            "@/vue/components/discovery/cards/companies/CompanyDiscoveryCards.vue"
          ),
        // RouterViewContainer,
        // children: [
        //   {
        //     path: "companies",
        //     component: () =>
        //       import(
        //         "@/vue/components/discovery/cards/companies/CompanyDiscoveryCards.vue"
        //       ),
        //     meta: {
        //       translation_key: "router.discover.companies.companies",
        //     },
        //   },
        //   {
        //     path: "persons",
        //     component: () =>
        //       import(
        //         "@/vue/components/discovery/cards/persons/PersonDiscoveryCards.vue"
        //       ),
        //     meta: {
        //       translation_key: "router.discover.companies.persons",
        //     },
        //     props: {
        //       entity_type: EntityType.PERSON,
        //     },
        //   },
        //   {
        //     path: "",
        //     redirect: "companies",
        //   },
        // ],
      })
      // {
      //   path: "company-map",
      //   meta: {
      //     translation_key: "router.discover.company_map.name",
      //   },
      //   component: () => import("@/vue/views/discover/CompanyMap.vue"),
      // },
      .add({
        path: "tags",
        name: RouteName.DISCOVER_COMPANY_TAGS,
        meta: {
          translation_key: "router.discover.companies_by_tags.name",
        },
        component: () => import("@/vue/views/discover/CompaniesByTags.vue"),
        props: (route) => ({
          tags: route.query?.tags ? (route.query.tags as string).split(",") : [],
        })
      })
      .add({
        path: "",
        redirect: "companies",
      })
    .exit()
  /* eslint-enable prettier/prettier */
}
