import { compare_less } from "@xelonic.com/trill";

export interface CharacterComponent {
  id: string;
  value: number;
}

export class Character {
  constructor(components: Record<string, number>) {
    this.components_ = [];

    for (const component_id in components) {
      this.components_.push({
        id: component_id,
        value: components[component_id],
      });
    }

    this.components_.sort((lhs, rhs) => compare_less(lhs.id, rhs.id));
  }

  get components(): CharacterComponent[] {
    return this.components_;
  }

  private readonly components_: CharacterComponent[];
}
