import { ranked_security_list_from_api } from "@/api/ranked_securities";
import { ResourceNotFound } from "@xelonic.com/trill";
import { RankedSecurityList } from "@/model/ranked_securities/ranked_security_list";
import { reactive } from "vue";
import { Logger, new_logger } from "@xelonic.com/trill";
import { SearchApi } from "@xelonic.com/xelonic-api";
import { RepositoryStore } from "@/api/repository_store";

export function get_search_repository(repos: RepositoryStore): SearchRepository {
  return repos.get_repository("search", SearchApi, SearchRepository);
}

class ReactiveState {
  search_running = false;
}

export class SearchRepository {
  constructor(options: { api: SearchApi }) {
    this.logger_ = new_logger("search_repository");
    this.api_ = options.api;
    this.state_ = reactive(new ReactiveState()) as ReactiveState;
  }

  get search_running(): boolean {
    return this.state_.search_running;
  }

  async search(search_term: string, locale: string): Promise<RankedSecurityList> {
    this.state_.search_running = true;

    try {
      const response = await this.api_.search(search_term, undefined, locale);
      const security_list = ranked_security_list_from_api(response.data, this.logger_);

      if (security_list.is_empty) {
        throw new ResourceNotFound("search");
      }

      return security_list;
    } finally {
      this.state_.search_running = false;
    }
  }

  private readonly logger_: Logger;
  private readonly api_: SearchApi;
  private readonly state_: ReactiveState;
}
