import Big from "big.js";

export enum ValueTypeIdentifier {
  CURRENCY = "currency",
  DATE = "date",
  IDENTIFIER = "identifier",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  STRING = "string",
}

export function is_numeric_type_id(id: ValueTypeIdentifier): boolean {
  switch (id) {
    case ValueTypeIdentifier.CURRENCY:
    case ValueTypeIdentifier.NUMBER:
    case ValueTypeIdentifier.PERCENTAGE:
      return true;
  }

  return false;
}

export class ValueType {
  constructor(
    id: ValueTypeIdentifier,
    currency?: string,
    fixed_point?: number,
    // TODO: this and currency should be part of the unit
    scale_divisor?: number
  ) {
    this.id_ = id;
    this.currency_ = currency;
    this.fixed_point_ = fixed_point;
    this.scale_divisor_ = scale_divisor;
  }

  get id(): ValueTypeIdentifier {
    return this.id_;
  }

  get currency(): string | undefined {
    return this.currency_;
  }

  get fixed_point(): number | undefined {
    return this.fixed_point_;
  }

  set fixed_point(value: number | undefined) {
    this.fixed_point_ = value;
  }

  /**
   * Defines a number by which the underlying number value could be divided to be shortened.
   * 1,000 could have a scale of 1,000. This is useful for shortening the huge numbers in a financial statement.
   */
  get scale_divisor(): number | undefined {
    return this.scale_divisor_;
  }

  set scale_divisor(value: number | undefined) {
    this.scale_divisor_ = value;
  }

  private readonly id_: ValueTypeIdentifier;
  private readonly currency_?: string;
  private fixed_point_?: number;
  private scale_divisor_?: number;
}

export class TypedNumber {
  static from_typed_value(value: Big | number, value_type: ValueType): TypedNumber {
    return new TypedNumber(value, value_type.id, value_type.fixed_point, value_type.currency);
  }

  constructor(
    value: Big | number,
    value_type_identifier?: ValueTypeIdentifier,
    fixed_point?: number,
    currency?: string
  ) {
    this.value_ = value;
    this.value_type_ = new ValueType(value_type_identifier ?? ValueTypeIdentifier.NUMBER, currency, fixed_point);
  }

  get value(): number | Big {
    return this.value_;
  }

  get value_type(): ValueType {
    return this.value_type_;
  }

  private readonly value_: number | Big;
  private readonly value_type_: ValueType;
}

export interface KeyedValueType {
  key: string;
  value_type: ValueType;
}

// TODO: turn into proper model classes independent of API

export class PercentageValue extends TypedNumber {
  constructor(value: number, fixed_point?: number) {
    super(value, ValueTypeIdentifier.PERCENTAGE, fixed_point);
  }
}

export class NumberValue extends TypedNumber {
  constructor(value: number, fixed_point?: number) {
    super(value, ValueTypeIdentifier.NUMBER, fixed_point);
  }
}

export class CurrencyValue extends TypedNumber {
  constructor(value: number, currency: string, fixed_point?: number) {
    super(value, ValueTypeIdentifier.CURRENCY, fixed_point, currency);
  }
}

export class TypedValue {
  constructor(value: string | number, value_type: ValueType) {
    this.value_ = value;
    this.value_type_ = value_type;
  }

  get value(): string | number {
    return this.value_;
  }

  get value_type(): ValueType {
    return this.value_type_;
  }

  private readonly value_: string | number;
  private readonly value_type_: ValueType;
}

export class StringValue extends TypedValue {
  constructor(value: string) {
    super(value, new ValueType(ValueTypeIdentifier.STRING));
  }
}

export class DateValue extends TypedValue {
  constructor(value: string) {
    super(value, new ValueType(ValueTypeIdentifier.DATE));
  }
}

export class IdentifierValue extends TypedValue {
  constructor(value: string) {
    super(value, new ValueType(ValueTypeIdentifier.IDENTIFIER));
  }
}

export class TypedRatio {
  constructor(numerator: TypedNumber, denominator: TypedNumber) {
    this.numerator_ = numerator;
    this.denominator_ = denominator;
  }

  get numerator(): TypedNumber {
    return this.numerator_;
  }

  get denominator(): TypedNumber {
    return this.denominator_;
  }

  private numerator_: TypedNumber;
  private denominator_: TypedNumber;
}

export function is_percentage(value_type: ValueType): boolean {
  return value_type.id == ValueTypeIdentifier.PERCENTAGE;
}
