<template>
  <div class="sidebar-container" :style="style_attributes">
    <div
      class="sidebar bg-light"
      :class="{
        'd-none': !is_visible,
        'sidebar-fading-in': is_fading_in,
        'sidebar-fading-out': is_fading_out,
      }"
    >
      <div class="px-3 py-2">
        <b-button-close size="lg" @click="close_sidebar" />
      </div>
      <div class="sidebar-body" @click="on_body_clicked">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "reflect-metadata";
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";

import { BButtonClose } from "bootstrap-vue/esm/components/button";

enum SidebarState {
  CLOSED,
  FADING_IN,
  OPEN,
  FADING_OUT,
}

@Component({
  components: {
    BButtonClose,
  },
})
export default class Sidebar extends Vue {
  @PropSync("visible", { default: false })
  visible_synced!: boolean;

  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get is_visible(): boolean {
    return this.state_ !== SidebarState.CLOSED;
  }

  close_sidebar(): void {
    if (this.state_ !== SidebarState.OPEN) {
      return;
    }

    this.state_ = SidebarState.FADING_OUT;
    setTimeout(() => {
      this.state_ = SidebarState.CLOSED;
      this.visible_synced = false;
    }, this.fadeout_duration_ms_);
  }

  on_body_clicked(event: Event): void {
    if (event.target === event.currentTarget) {
      this.close_sidebar();
    }
  }

  get style_attributes(): Record<string, string> {
    return {
      "--sidebar-fadein-duration": `${this.fadein_duration_ms_}ms`,
      "--sidebar-fadeout-duration": `${this.fadeout_duration_ms_}ms`,
    };
  }

  get is_fading_in(): boolean {
    return this.state_ === SidebarState.FADING_IN;
  }

  get is_fading_out(): boolean {
    return this.state_ === SidebarState.FADING_OUT;
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------

  @Watch("visible")
  on_visible_changed(): void {
    this.handle_visible_changed();
  }

  mounted(): void {
    this.handle_visible_changed();
  }

  private handle_visible_changed(): void {
    if (this.visible_synced && this.state_ === SidebarState.CLOSED) {
      this.state_ = SidebarState.FADING_IN;
      setTimeout(() => {
        this.state_ = SidebarState.OPEN;
      }, this.fadein_duration_ms_);
    } else if (!this.visible_synced && this.state_ === SidebarState.OPEN) {
      this.state_ = SidebarState.FADING_OUT;
      setTimeout(() => {
        this.state_ = SidebarState.CLOSED;
      }, this.fadeout_duration_ms_);
    }
  }

  private readonly fadein_duration_ms_ = 500;
  private readonly fadeout_duration_ms_ = 500;

  private state_ = SidebarState.CLOSED;
}
</script>

<style lang="scss" scoped>
$sidebar-width: 320px;

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 320px;
  z-index: 1035;
}

.sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 320px;

  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
}

.sidebar-body {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.sidebar-fading-in {
  animation-name: sidebar-fadein;
  animation-duration: var(--sidebar-fadein-duration);
  animation-fill-mode: forwards;
}

.sidebar-fading-out {
  animation-name: sidebar-fadeout;
  animation-duration: var(--sidebar-fadeout-duration);
  animation-fill-mode: forwards;
}

@keyframes sidebar-fadein {
  0% {
    left: -$sidebar-width;
  }
  100% {
    left: 0;
  }
}

@keyframes sidebar-fadeout {
  0% {
    left: 0;
  }
  100% {
    left: -$sidebar-width;
  }
}
</style>
