import {
  TrackingSettings,
  TrackingSettingsRepository as TrackingSettingsRepository,
  possibly_defined,
} from "@xelonic.com/trill";
import { TrackingApi } from "@xelonic.com/xelonic-api";

export class TrillTrackingSettingsRepository implements TrackingSettingsRepository {
  constructor(options: { api: TrackingApi }) {
    this.api_ = options.api;
  }

  async get(): Promise<TrackingSettings> {
    const api_settings = (await this.api_.getInitialSettings()).data;

    const settings: TrackingSettings = {};

    settings.needs_cookie_consent = possibly_defined(api_settings.cookie_consent_needed);

    return settings;
  }

  private readonly api_: TrackingApi;
}
