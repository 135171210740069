<template>
  <ul class="tree-navigation">
    <router-tree-navigation-node
      v-for="item in navigation_items"
      :key="item.path"
      :item="item"
      :auto_collapse="auto_collapse"
    />
  </ul>
</template>

<script lang="ts">
import { IllegalStateError } from "@xelonic.com/trill";
import { RouteName, RouteRoot } from "@/router/route_constants";
import RouterTreeNavigationNode, {
  RouteNode,
} from "@/vue/components/general/router_tree_navigation/RouterTreeNavigationNode.vue";
import "reflect-metadata";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";

@Component({
  components: {
    RouterTreeNavigationNode,
  },
})
export default class RouterTreeNavigation extends Vue {
  @Prop({ required: true })
  readonly route_root!: RouteRoot | null;

  /**
   * If defined, the children are collapsed/expanded automatically based on the current path.
   */
  @Prop({ default: null })
  readonly auto_collapse!: string;

  @Watch("route_root", { immediate: true })
  route_root_changed(): void {
    this.disabled_route_names_ = [];
    this.update_navigation_items();
    this.$route_root.value = this.route_root;
  }

  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get navigation_items(): RouteNode[] {
    return this.navigation_items_;
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------

  created(): void {
    this.$bus.on("router-tree-navigation.disable-route-names", (route_names: RouteName[]) =>
      this.disable_route_names(route_names)
    );
  }

  beforeDestroy(): void {
    this.$route_root.clear();
  }

  private disable_route_names(route_names: RouteName[]): void {
    this.disabled_route_names_.push(...route_names);
    this.update_navigation_items();
  }

  private update_navigation_items(): void {
    if (!this.route_root) {
      this.navigation_items_ = [];
      return;
    }

    let route_roots = this.$xrouter.routes.filter((route_config) => {
      return route_config.path.startsWith(this.route_root!.path);
    });

    if (route_roots.length != 1) {
      throw new IllegalStateError(`there's more than one route root for ${this.route_root}`);
    }

    let route_root = route_roots[0];

    if (!route_root.children || route_root.children.length < 1) {
      throw new IllegalStateError(`route root ${this.route_root} has no children`);
    }

    let base_path = this.route_root.path.toString();
    if (this.route_root.path_suffix) {
      base_path += `/${this.route_root.path_suffix}`;
    }

    this.navigation_items_ = this.create_navigation_items(route_root.children, base_path);
  }

  private create_navigation_items(route_configs: RouteConfig[], base_path: string): RouteNode[] {
    return route_configs
      .filter((route_config: RouteConfig) => {
        return route_config.path.length > 0; // ignore redirects
      })
      .map((route_config: RouteConfig) => {
        if (!route_config.meta) {
          throw new IllegalStateError(`route config '${route_config.path}' has no meta information`);
        }

        if (route_config.meta.hidden_in_navigation) {
          return undefined;
        }

        if (!route_config.meta.translation_key && !route_config.meta.label) {
          throw new IllegalStateError("route config's meta information has no property 'translation_key' nor 'label'");
        }

        if (route_config.path.includes(":")) {
          throw new IllegalStateError("this algorithm doesn't support dynamic routes");
        }

        const path = `${base_path}/${route_config.path}`;
        const name = route_config.meta.translation_key
          ? this.$ls(route_config.meta.translation_key)
          : route_config.meta.label;

        let children: RouteNode[] = [];

        if (route_config.children && route_config.children.length > 0) {
          children = this.create_navigation_items(route_config.children, path);
        }

        const route_name = route_config.name ? (route_config.name as RouteName) : undefined;
        let disabled = route_name ? this.disabled_route_names_.includes(route_name) : false;

        if (route_config.meta.disabled) {
          disabled = true;
        }

        return {
          path,
          label: name,
          children,
          disabled,
          name: route_name,
        };
      })
      .filter((node: RouteNode | undefined) => node !== undefined)
      .map((node: RouteNode | undefined) => node!);
  }

  private navigation_items_: RouteNode[] = [];
  private disabled_route_names_: RouteName[] = [];
}
</script>
<style lang="scss" scoped>
.tree-navigation {
  list-style-type: none;
}
</style>
