import { EntityType } from "@/model/entity_type";
import { SecurityID } from "@/model/security_id";
import { link_to_company, link_to_entity, link_to_person, RouteName } from "@/router/route_constants";
import { Breakpoint, get_breakpoints_from_css } from "@/util/breakpoints";
import { is_on_exact_path, is_on_path, is_touch_device } from "@/util/browser_util";
import { capitalize_begin, capitalize_words } from "@/util/string";
import { is_true_prop } from "@/util/util";
import { has_items } from "@xelonic.com/trill";
import Vue from "vue";
import { Location } from "vue-router";

export interface StringUtils {
  capitalize_words(str: string): string;
  cw(str: string): string;
  c(str: string): string;
  capitalize_begin(str: string): string;
}

declare module "vue/types/vue" {
  interface Vue {
    /**
     * Create a global identifier for a local identifier.
     *
     * This uses the component's _uid to generate a global id.
     */
    $id: (id?: string) => string;
    $href: (id?: string) => string;

    $scroll_to_top: () => void;
    $link_to_company: (id: SecurityID, route_name?: RouteName | string) => Location;
    $link_to_person: (id: SecurityID, route_name?: RouteName) => Location;
    $link_to_entity: (id: SecurityID, entity_type: EntityType, route_name?: RouteName) => Location;

    $is_touch_device(): () => boolean;
    $is_true_prop: (prop: unknown) => boolean;

    $is_on_path(path: string): boolean;
    $is_on_exact_path(path: string): boolean;

    $has_items<Item>(items: Item[] | string | undefined | null, min_item_count?: number): items is Item[] | string;

    $width_wider_than(width: number, breakpoint: Breakpoint): boolean;
    $wider_than(breakpoint: Breakpoint): boolean;

    $str: StringUtils;
  }
}

export function install_util(vue: typeof Vue): void {
  vue.use(Util);
}

class Util {
  static install(vue: typeof Vue): void {
    vue.prototype.$id = function (id?: string): string {
      const local_id_suffix = id ? `-${id}` : "";
      return `id-${this._uid}${local_id_suffix}`;
    };

    vue.prototype.$href = function (id?: string): string {
      return `#${this.$id(id)}`;
    };

    vue.prototype.$scroll_to_top = function (): void {
      window.document.getElementById("app")?.scrollTo(0, 0);
    };

    vue.prototype.$link_to_company = link_to_company;
    vue.prototype.$link_to_person = link_to_person;
    vue.prototype.$link_to_entity = link_to_entity;

    vue.prototype.$is_touch_device = is_touch_device;
    vue.prototype.$is_true_prop = is_true_prop;

    vue.prototype.$is_on_path = is_on_path;
    vue.prototype.$is_on_exact_path = is_on_exact_path;

    vue.prototype.$str = {
      capitalize_words: capitalize_words,
      cw: capitalize_words,
      capitalize_begin: capitalize_begin,
      c: capitalize_begin,
    };

    vue.prototype.$has_items = has_items;

    const breakpoints = get_breakpoints_from_css();
    vue.prototype.$wider_than = function (breakpoint: Breakpoint) {
      return window.innerWidth >= breakpoints[breakpoint];
    };

    vue.prototype.$width_wider_than = function (width: number, breakpoint: Breakpoint) {
      return width >= breakpoints[breakpoint];
    };
  }
}

export default Util;
