import { EntityType } from "@/model/entity_type";
import { SecurityID } from "@/model/security_id";
import { RankedCompany } from "./ranked_company";
import { RankedPerson } from "./ranked_person";

export class RankedSecurityList {
  constructor(persons?: RankedPerson[], companies?: RankedCompany[]) {
    this.persons_ = persons ? persons : [];
    this.companies_ = companies ? companies : [];
  }

  get persons(): RankedPerson[] {
    return this.persons_;
  }

  get companies(): RankedCompany[] {
    return this.companies_;
  }

  get is_empty(): boolean {
    return this.persons_.length < 1 && this.companies_.length < 1;
  }

  contains(entity_id: SecurityID, entity_type?: EntityType): boolean {
    if (entity_type === undefined || entity_type === EntityType.PERSON) {
      if (this.contains_person(entity_id)) {
        return true;
      }
    }

    if (entity_type === undefined || entity_type === EntityType.COMPANY) {
      if (this.contains_company(entity_id)) {
        return true;
      }
    }

    return false;
  }

  private contains_person(entity_id: SecurityID): boolean {
    const found_person = this.persons_.find((ranked_person: RankedPerson) => {
      return ranked_person.id.equal_to(entity_id);
    });

    return found_person !== undefined;
  }

  private contains_company(entity_id: SecurityID): boolean {
    const found_company = this.companies_.find((ranked_company: RankedCompany) => {
      return ranked_company.id.equal_to(entity_id);
    });

    return found_company !== undefined;
  }

  private readonly persons_: RankedPerson[];
  private readonly companies_: RankedCompany[];
}
