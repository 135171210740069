import { BadRequest, Unauthorized } from "@xelonic.com/trill";
import { IdentityProvider } from "@/model/auth/identity_provider";
import { LoginData } from "@/model/auth/login_data";
import { AuthRepository } from "@/repositories/user/auth_repository";
import { RouteName } from "@/router/route_constants";
import { Logger, new_logger } from "@xelonic.com/trill";
import VueRouter from "vue-router";
import { RepositoryStore } from "@/api/repository_store";
import { AuthenticationApi } from "@xelonic.com/xelonic-api";
import { AuthorizationService } from "@/services/authorization_service";
import { AuthState } from "@/model/auth/auth_state";

export function get_authentication_service(
  repos: RepositoryStore,
  router: VueRouter,
  authz: AuthorizationService
): AuthenticationService {
  return repos.get_service("auth", () => {
    const api = repos.get_api("auth", AuthenticationApi);
    const repo = new AuthRepository({ api });
    return new AuthenticationService(router, repo, authz);
  });
}

/**
 * Provides authentication methods, such as login. It will talk to the auth repository behind the scenes. The idea is
 * that you only talk to the auth service, not to the repository itself. So some calls are just routing through the
 * repository.
 */
export class AuthenticationService {
  constructor(router: VueRouter, auth: AuthRepository, authorization: AuthorizationService) {
    this.logger_ = new_logger("auth_service");
    this.router_ = router;
    this.auth_ = auth;
    this.authz_ = authorization;

    this.auth_.on_local_storage_changed(() => this.on_local_storage_changed());

    this.handle_auth_state_changed();
  }

  /** This property is reactive. */
  get is_logged_in(): boolean {
    return this.auth_.is_logged_in;
  }

  /** This property is reactive. */
  get is_active(): boolean {
    return this.auth_.is_active;
  }

  /** This property is reactive. */
  get is_activating(): boolean {
    return this.auth_.is_logged_in && !this.auth_.is_active;
  }

  user_has_role(role_prefix: string): boolean {
    return this.is_active && this.auth_.user_roles.find((role) => role.startsWith(role_prefix)) !== undefined;
  }

  /** This property is reactive. */
  get user_ever_had_subs(): boolean {
    return this.auth_.ever_had_subs;
  }

  /** This property is reactive. */
  get user_has_alive_sub(): boolean {
    return this.auth_.has_alive_sub;
  }

  async login_with_credentials(username: string, password: string, redirect_path?: string): Promise<void> {
    const login_data = await this.auth_.login_with_credentials(username, password);
    await this.handle_successful_login({ login_data, redirect_path });
  }

  async get_identity_provider_auth_code_url(identity_provider: IdentityProvider): Promise<string> {
    return await this.auth_.get_identity_provider_auth_code_url(identity_provider);
  }

  async login_with_auth_code(auth_code: string): Promise<LoginData> {
    const login_data = await this.auth_.login_with_auth_code(auth_code);
    await this.handle_successful_login({ login_data });
    return login_data;
  }

  async logout(): Promise<void> {
    try {
      this.cancel_refresh_tokens();
      await this.auth_.logout();
    } finally {
      await this.handle_logout();
    }
  }

  async logout_and_delete(): Promise<void> {
    try {
      this.cancel_refresh_tokens();
      await this.auth_.delete_user();
      await this.handle_logout();
    } catch (reason: unknown) {
      if (reason instanceof BadRequest) {
        // only in this case we don't want to log out. Because it's probably
        // related to the user not meeting the deletion requirements, e.g.
        // no running subscription
        throw reason;
      }

      this.logger_.error("Failed to logout and delete:", reason);
      this.auth_.handle_logout();
      this.handle_user_deletion_error(reason);
    }
  }

  async create_password_reset_token(username_or_email: string): Promise<void> {
    return await this.auth_.create_password_reset_token(username_or_email);
  }

  async reset_password(reset_token: string, password: string): Promise<void> {
    await this.auth_.reset_password(reset_token, password);
  }

  async is_password_reset_token_valid(token: string): Promise<boolean> {
    return await this.auth_.is_password_reset_token_valid(token);
  }

  async register_user(username: string, email: string, password: string): Promise<void> {
    await this.auth_.register_user(username, email, password);
  }

  async refresh_tokens(): Promise<void> {
    clearTimeout(this.refresh_tokens_timeout_handle_);
    await this.execute_refresh_tokens();
  }

  private cancel_refresh_tokens(): void {
    if (this.refresh_tokens_timeout_handle_ !== null) {
      clearTimeout(this.refresh_tokens_timeout_handle_);
      this.refresh_tokens_timeout_handle_ = null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handle_user_deletion_error(reason: any): void {
    let user_id = "";
    if (reason.response && reason.response.data) {
      const user_id_prefix = "user_id:";
      const index = reason.response.data.indexOf(user_id_prefix);
      if (index >= 0) {
        user_id = reason.response.data.substring(index + user_id_prefix.length);
      }
    }

    this.redirect_to_account_deletion_error(user_id);
  }

  private async handle_auth_state_changed(): Promise<void> {
    if (this.auth_.is_logged_in) {
      this.kickoff_refresh_tokens();
    } else {
      this.try_cancel_refresh_tokens();
    }

    await this.propagate_auth_state_changed();
  }

  private async handle_successful_login(options?: { login_data?: LoginData; redirect_path?: string }): Promise<void> {
    this.kickoff_refresh_tokens();
    await this.propagate_login_changed(options?.redirect_path);
  }

  private async handle_logout(): Promise<void> {
    this.auth_.handle_logout();
    await this.propagate_login_changed();
  }

  private kickoff_refresh_tokens(refresh_in_ms?: number): void {
    try {
      this.try_cancel_refresh_tokens();

      if (!refresh_in_ms || refresh_in_ms < 1) {
        refresh_in_ms = Math.max(this.auth_.expires_in_milliseconds - this.refresh_trigger_before_expiry_, 1);
      }

      this.refresh_tokens_timeout_handle_ = setTimeout(this.execute_refresh_tokens.bind(this), refresh_in_ms);
    } catch (reason: unknown) {
      this.logger_.error("Failed to refresh tokens:", reason);
      this.logout();
    }
  }

  private try_cancel_refresh_tokens(): void {
    clearTimeout(this.refresh_tokens_timeout_handle_);
  }

  private async execute_refresh_tokens(): Promise<void> {
    if (!this.auth_.is_logged_in) {
      return;
    }

    try {
      await this.auth_.refresh_access_token();
      await this.handle_auth_state_changed();
    } catch (reason: unknown) {
      this.handle_refresh_token_failure(reason);
    }
  }

  private async propagate_login_changed(redirect_path?: string): Promise<void> {
    await this.authz_.on_login_changed(this.create_auth_state(), redirect_path);
  }

  private async propagate_auth_state_changed(): Promise<void> {
    await this.authz_.on_auth_state_changed(this.create_auth_state());
  }

  private create_auth_state(): AuthState {
    return {
      is_logged_in: this.auth_.is_logged_in,
      roles: this.auth_.user_roles,
      ever_had_subs: this.auth_.ever_had_subs,
      has_alive_sub: this.auth_.has_alive_sub,
    };
  }

  private handle_refresh_token_failure(error: unknown): void {
    if (error instanceof Unauthorized) {
      this.logger_.error("Not authorized to refresh tokens; logging out. Details:", error);
      this.logout();
    } else {
      this.logger_.error("Failed to refresh tokens:", error);
      this.kickoff_refresh_tokens(this.refresh_trigger_on_failure_ms_);
    }
  }

  private on_local_storage_changed(): void {
    this.handle_auth_state_changed();
  }

  private redirect_to_account_deletion_error(user_id: string): void {
    this.router_.push({
      name: RouteName.ACCOUNT_DELETION_ERROR,
      query: {
        user_id: user_id,
      },
    });
  }

  private readonly logger_: Logger;
  private readonly router_: VueRouter;
  private readonly auth_: AuthRepository;
  private readonly authz_: AuthorizationService;

  // Number of milliseconds before the expiry where the token refresh shall be kicked off.
  private readonly refresh_trigger_before_expiry_ = 30 * 1000;

  // Time to wait until token refresh is retried after a refresh failure.
  private readonly refresh_trigger_on_failure_ms_ = 1000;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refresh_tokens_timeout_handle_: any | null = null;
}
