export function truncate_range_by_minutes(from: Date, to: Date, minutes: number): [Date, Date] {
  return [truncate_by_minutes(from, minutes, false), truncate_by_minutes(to, minutes, true)];
}

export function truncate_by_minutes(date: Date, minutes: number, use_ceil: boolean): Date {
  const rounder = use_ceil ? Math.floor : Math.ceil;
  const date_minutes = minutes * rounder(date.getUTCMinutes() / minutes);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date_minutes);
}

export function years_ago(date: Date, years: number): Date {
  return new Date(date.valueOf() - years * 365 * 24 * 60 * 60 * 1000);
}

export function days_forward(date: Date, days: number): Date {
  return new Date(date.valueOf() + days * 24 * 60 * 60 * 1000);
}
