export class RelativeValue {
  constructor(absolute: number, relative: number, value_denominator?: number) {
    this.absolute_ = absolute / (value_denominator ?? 1);
    this.relative_ = relative;
  }

  get absolute(): number {
    return this.absolute_;
  }

  get relative(): number {
    return this.relative_;
  }

  private absolute_: number;
  private relative_: number;
}

export class ChangedValue {
  constructor(start: number, end: RelativeValue) {
    this.start_ = start;
    this.end_ = end;
  }

  get start(): number {
    return this.start_;
  }

  get end(): RelativeValue {
    return this.end_;
  }

  private start_: number;
  private end_: RelativeValue;
}
