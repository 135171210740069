import { ReleaseStage, RouteBuilder } from "@xelonic.com/trill";
import { create_test_routes } from "@/router/test_routes";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_public_routes(base_path: string, builder: RouteBuilder): RouteBuilder {
  /* eslint-disable prettier/prettier */
  return builder
    .add({
      path: base_path,
      component: RouterViewContainer,
      meta: {
        accessible_before_initial_sub: true,
      },
    })
    .enter()
      .add({
        path: "imprint",
        name: "imprint",
        component: () => import("@/vue/views/ImprintView.vue"),
      })
      .add({
        path: "privacy-notice",
        name: "privacy-notice",
        component: () => import("@/vue/views/PrivacyNotice.vue"),
      })
      .add({
        path: "agb",
        name: "agb",
        component: () => import("@/vue/views/AGB.vue"),
      })
      .add({
        path: "cookie-policy",
        name: "cookie-policy",
        component: () => import("@/vue/views/CookiePolicy.vue"),
      })
      // {
      //   path: "/data-sources",
      //   name: RouteName.DATA_SOURCES,
      //   component: () => import("@/vue/views/DataSources.vue"),
      // },
      // external links
      .add({
        path: "linkedin",
        name: "linkedin",
        beforeEnter: (): void => {
          window.open("https://de.linkedin.com/company/xelonic");
        },
      })
      .add({
        path: "github",
        name: "github",
        beforeEnter: (): void => {
          window.open("https://github.com/xelonic");
        },
      })
      .add({
        path: "xing",
        name: "xing",
        beforeEnter: (): void => {
          window.open("https://www.xing.com/companies/xelonic");
        },
      })
      .add({
        path: "facebook",
        name: "facebook",
        beforeEnter: (): void => {
          window.open("https://www.facebook.com/xelonic");
        },
      })
      .add({
        path: "youtube",
        name: "youtube",
        beforeEnter: (): void => {
          window.open("https://www.youtube.com/@xelonic");
        },
      })
      .add({
        path: "not-authorized",
        name: "not-authorized",
        component: () => import("@/vue/views/NotAuthorizedPage.vue"),
        meta: {
          translation_key: "router.not_authorized",
        },
        props: (route) => ({
          subscribable: route.query.subscribable === "true",
        }),
      })
      .add({
        path: "not-authenticated",
        name: "not-authenticated",
        component: () => import("@/vue/views/NotAuthenticatedPage.vue"),
        meta: {
          translation_key: "router.not_authenticated",
        },
        props: (route) => ({
          redirect_path: route.query.redirect_path,
        }),
      })
      .create_if_stage_in([ReleaseStage.DEVELOPMENT, ReleaseStage.LOCAL], "test", create_test_routes)
    .exit()
  /* eslint-enable prettier/prettier */
}
