import { SubscriptionsInfo } from "@/model/auth/subscriptions_info";

export interface APISubscriptionsInfo {
  readonly ever_had_subscriptions?: boolean | null;
  readonly has_alive_subscription?: boolean | null;
}

export function subscriptions_info_from_api(data: APISubscriptionsInfo): SubscriptionsInfo {
  return {
    ever_had_one: data.ever_had_subscriptions ?? false,
    has_alive: data.has_alive_subscription ?? false,
  };
}
