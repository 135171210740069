import { Location } from "@/model/location";
import { Location as APILocation } from "@xelonic.com/xelonic-api";
import { verify_field_defined } from "@xelonic.com/trill";

export function location_from_api(location: APILocation): Location {
  const type_name = "Location";
  verify_field_defined(location, type_name, "latitude");
  verify_field_defined(location, type_name, "longitude");

  return new Location(location.latitude!, location.longitude!);
}
