import { EntityType } from "@/model/entity_type";
import { RouteBuilder, is_defined } from "@xelonic.com/trill";
import { Route } from "vue-router";
import { props_security_id_from_route, security_id_from_route } from "./router_util";
import { RouteName } from "./route_constants";
import { FinancialStatementType } from "@/model/companies/finances/financial_statement_type";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_company_routes(base_path: string, builder: RouteBuilder): RouteBuilder {
  /* eslint-disable prettier/prettier */
  return (
    builder
      .add({
        path: `${base_path}/:security_id`,
        components: {
          default: () => import("@/vue/views/company/Company.vue"),
          left_sidebar: () => import("@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"),
          right_sidebar: RouterViewContainer,
        },
        props: {
          default: (route: Route) => ({
            security_id: security_id_from_route(route),
          }),
          left_sidebar: (route: Route) => ({
            route_root: {
              path: base_path,
              path_suffix: route.params.security_id,
            },
          }),
          right_sidebar: () => ({
            name: "right_sidebar",
          }),
        },
      })
      .enter()
        .add({
          path: "overview",
          name: "company-overview",
          components: {
            default: () => import("@/vue/views/company/overview/Overview.vue"),
            right_sidebar: () => import("@/vue/components/general/LocalTreeNavigation.vue"),
          },
          props: {
            default: (route: Route) => ({
              security_id: security_id_from_route(route),
            }),
            right_sidebar: () => ({
              target_selector: "#company_content",
            }),
          },
          meta: {
            translation_key: "router.company.overview.name",
            role: "api_overview",
          },
        })
        .add({
          path: "fundamental",
          components: {
            default: RouterViewContainer,
            right_sidebar: () => import("@/vue/components/general/LocalTreeNavigation.vue"),
          },
          meta: {
            translation_key: "router.company.fundamental.name",
          },
          props: {
            default: (route: Route) => ({
              security_id: security_id_from_route(route),
            }),
            right_sidebar: () => ({
              target_selector: "#company_content",
            }),
          },
        })
        .enter()
          .add({
            path: "finances",
            component: RouterViewContainer,
            props: props_security_id_from_route,
            meta: {
              translation_key: "router.company.fundamental.finances.name",
              role: "api_finances",
            },
          })
          .enter()
            .add({
              path: "balance",
              name: RouteName.COMPANY_FINANCES_BALANCE,
              component: () => import("@/vue/components/company/finances/FinancialStatements.vue"),
              props: (route: Route) => ({
                security_id: security_id_from_route(route),
                statement_type: FinancialStatementType.BALANCE_SHEET,
              }),
              meta: {
                translation_key: "router.company.fundamental.finances.balancesheet.name",
              },
            })
            .add({
              path: "cashflow",
              name: RouteName.COMPANY_FINANCES_CASHFLOW,
              component: () => import("@/vue/components/company/finances/FinancialStatements.vue"),
              props: (route: Route) => ({
                security_id: security_id_from_route(route),
                statement_type: FinancialStatementType.CASH_FLOW,
              }),
              meta: {
                translation_key: "router.company.fundamental.finances.cashflow.name",
              },
            })
            .add({
              path: "operations",
              name: RouteName.COMPANY_FINANCES_OPERATIONS,
              component: () => import("@/vue/components/company/finances/FinancialStatements.vue"),
              props: (route: Route) => ({
                security_id: security_id_from_route(route),
                statement_type: FinancialStatementType.OPERATIONS,
              }),
              meta: {
                translation_key: "router.company.fundamental.finances.operations.name",
              },
            })
            .add({
              path: "income",
              name: RouteName.COMPANY_FINANCES_INCOME,
              component: () => import("@/vue/components/company/finances/FinancialStatements.vue"),
              props: (route: Route) => ({
                security_id: security_id_from_route(route),
                statement_type: FinancialStatementType.INCOME,
              }),
              meta: {
                translation_key: "router.company.fundamental.finances.income.name",
              },
            })
            .add({
            path: "comprehensive-income",
            name: RouteName.COMPANY_FINANCES_COMPREHENSIVE_INCOME,
            component: () => import("@/vue/components/company/finances/FinancialStatements.vue"),
            props: (route: Route) => ({
              security_id: security_id_from_route(route),
              statement_type: FinancialStatementType.COMPREHENSIVE_INCOME,
            }),
            meta: {
              translation_key: "router.company.fundamental.finances.comprehensive_income.name",
            },
          })
          .exit()
          .add({
            path: "growth",
            component: () => import("@/vue/views/company/fundamental/growth/Growth.vue"),
            meta: {
              translation_key: "router.company.fundamental.growth.name",
              role: "api_growth",
            },
            props: props_security_id_from_route,
          })
          .add({
            path: "ratios",
            name: RouteName.COMPANY_RATIOS,
            component: () => import("@/vue/views/company/fundamental/Ratios.vue"),
            meta: {
              translation_key: "router.company.fundamental.ratios.name",
              role: "api_ratios",
            },
            props: (route) => ({
              security_id: security_id_from_route(route),
              ratio_or_tag_ids: is_defined(route.query?.ratio_or_tag_ids) ? (route.query.ratio_or_tag_ids as string).split(",") : ["assets"],
            })
          })
          .exit()
        .add({
          path: "ownership",
          name: RouteName.COMPANY_OWNERSHIP,
          component: () => import("@/vue/components/ownership/Ownership.vue"),
          meta: {
            translation_key: "router.ownership.name",
            role: "api_ownership",
          },
          props: (route: Route) => ({
            security_id: security_id_from_route(route),
            ownership_type: "ownership",
            entity_type: EntityType.COMPANY,
          }),
        })
        .add({
          path: "holdings",
          name: RouteName.COMPANY_HOLDINGS,
          component: () => import("@/vue/components/ownership/Ownership.vue"),
          meta: {
            translation_key: "router.holdings.name",
            role: "api_holdings",
          },
          props: (route: Route) => ({
            security_id: security_id_from_route(route),
            ownership_type: "holdings",
            entity_type: EntityType.COMPANY,
          }),
        })
        .add({
          path: "knowledge-graph",
          component: () => import("@/vue/views/securities/KnowledgeGraph.vue"),
          meta: {
            translation_key: "router.company.knowledge_graph.name",
            role: "api_knowledge_graph",
          },
          props: props_security_id_from_route,
        })
        // {
        //   path: "correlations",
        //   component: () => import("@/vue/views/securities/Correlations.vue"),
        //   meta: {
        //     translation_key: "router.company.correlations.name",
        //   },
        //   props: props_security_id_from_route,
        // },
        .add({
          path: "timeline",
          components: {
            default: () => import("@/vue/views/entities/Timeline.vue"),
            right_sidebar: () => import("@/vue/views/home/RightSidebar.vue"),
          },
          meta: {
            translation_key: "router.company.timeline.name",
            role: "api_events",
          },
          props: {
            default: (route: Route) => ({
              security_id: security_id_from_route(route),
            }),
          },
        })
        .add({
          path: "general-info",
          component: () => import("@/vue/views/company/general_info/GeneralInfo.vue"),
          meta: {
            translation_key: "router.company.general_info.name",
            role: "api_general_info",
          },
          props: props_security_id_from_route,
        })
      .exit()
  );
  /* eslint-enable prettier/prettier */
}
