export class Resource {
  constructor(
    url: string,
    options?: {
      source_name?: string;
      source_url?: string;
      license_url?: string;
    }
  ) {
    this.url_ = url;
    this.source_name_ = options?.source_name;
    this.source_url_ = options?.source_url;
    this.license_url_ = options?.license_url;
  }

  get url(): string {
    return this.url_;
  }

  get source_name(): string | undefined {
    return this.source_name_;
  }

  get source_url(): string | undefined {
    return this.source_url_;
  }

  get license_url(): string | undefined {
    return this.license_url_;
  }

  private readonly url_: string;
  private readonly source_name_?: string;
  private readonly source_url_?: string;
  private readonly license_url_?: string;
}
