import { general_info_from_api as company_general_info_from_api } from "@/api/companies/general_info";
import { entity_connection_from_api } from "@/api/ownership/entity_connection";
import { general_info_from_api as person_general_info_from_api } from "@/api/persons/general_info";
import { Character } from "@/model/companies/character";
import { RankedCompany } from "@/model/ranked_securities/ranked_company";
import { RankedPerson } from "@/model/ranked_securities/ranked_person";
import { RankedSecurityList } from "@/model/ranked_securities/ranked_security_list";
import { map_unthrown } from "@xelonic.com/trill";
import { Logger } from "@xelonic.com/trill";
import {
  RankedCompany as APIRankedCompany,
  RankedPerson as APIRankedPerson,
  RankedSecurityList as APIRankedSecurityList,
} from "@xelonic.com/xelonic-api";
import { verify_field_defined } from "@xelonic.com/trill";
import { price_from_api } from "./price";

export function ranked_security_list_from_api(list: APIRankedSecurityList, logger: Logger): RankedSecurityList {
  const persons = map_unthrown(list.persons, logger, (person) => ranked_person_from_api(person, logger));
  const companies = map_unthrown(list.companies, logger, (company) => ranked_company_from_api(company, logger));

  return new RankedSecurityList(persons, companies);
}

export function ranked_person_from_api(person: APIRankedPerson, logger: Logger): RankedPerson {
  const type_name = "RankedPerson";
  verify_field_defined(person, type_name, "general_info");

  const rank = person.rank ?? 0;

  const info = person_general_info_from_api(person.general_info!, logger);
  const top_holdings = map_unthrown(person.top_holdings, logger, (connection) =>
    entity_connection_from_api(connection, logger)
  );

  return new RankedPerson(rank, info, top_holdings);
}

export function ranked_company_from_api(company: APIRankedCompany, logger: Logger): RankedCompany {
  const type_name = "RankedCompany";
  verify_field_defined(company, type_name, "general_info");

  const rank = company.rank ?? 0;

  const info = company_general_info_from_api(company.general_info!, logger);
  const top_owners = map_unthrown(company.top_owners, logger, (c) => entity_connection_from_api(c, logger));
  const top_holdings = map_unthrown(company.top_owners, logger, (c) => entity_connection_from_api(c, logger));

  return new RankedCompany(rank, info, {
    price: company.price ? price_from_api(company.price) : undefined,
    character: company.character ? new Character(company.character) : undefined,
    top_owners: top_owners,
    top_holdings: top_holdings,
  });
}
