import { RouteName } from "@/router/route_constants";
import mitt, { Emitter as MittEmitter } from "mitt";
import Vue from "vue";

export type EventBusEvents = {
  "main-area-contents-changed": void;
  "local-tree-navigation-click": string;
  "router-tree-navigation.disable-route-names": RouteName[];
  "locale-changed": string;
  // when the events of the activity feed are rendered. Parameter: number of events rendered.
  "activity-feed-content-updated": number;
};

export type EventBus = MittEmitter<EventBusEvents>;

function create_event_bus(): EventBus {
  return mitt<EventBusEvents>();
}

declare module "vue/types/vue" {
  interface Vue {
    $bus: EventBus;
  }
}

export function install_event_bus(vue: typeof Vue): EventBus {
  vue.use(EventBusPlugin);
  return vue.prototype.$bus;
}

class EventBusPlugin {
  static install(vue: typeof Vue): void {
    vue.prototype.$bus = create_event_bus();
  }
}

export default EventBusPlugin;
