import { RouteBuilder } from "@xelonic.com/trill";
import { RouteName } from "@/router/route_constants";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_content_creation_routes(path: string, builder: RouteBuilder): void {
  /* eslint-disable prettier/prettier */
  builder.add({
    path,
    components: {
      default: RouterViewContainer,
    },
    meta: {
      internal: true,
    }
  })
  .enter()
    .add({
      path: "ratio",
      name: RouteName.CONTENT_CREATION,
      components: {
        default: () => import("@/vue/views/content_creation/Company.vue"),
      },
      meta: {
        label: "company",
        role: "content_creation"
      },
    })
  .exit()
  /* eslint-enable prettier/prettier */
}
