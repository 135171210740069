import { NodeEnvironment, parse_node_environment, parse_release_stage, ReleaseStage } from "@xelonic.com/trill";

export interface YoutubeIDs {
  readonly feed: string;
  readonly company_overview: string;
  readonly discovery: string;
  readonly company_watchlist: string;
}

export interface Configuration {
  readonly node_environment: NodeEnvironment;
  readonly release_stage: ReleaseStage;
  readonly api_url: string;
  readonly stripe_public_key: string | undefined;
  readonly tracking_enabled: boolean;

  readonly gtag_id: string;
  readonly matomo_url: string | undefined;
  readonly matomo_site_id: number;

  readonly app_version: string;

  readonly suggestions_fade_in_msecs: number;
  readonly suggestions_fade_out_msecs: number;

  readonly market_cdf_charts_enabled: boolean;
  readonly subscriptions_enabled: boolean;

  readonly youtube_ids: YoutubeIDs;

  readonly signup_trial_days: number;
  readonly stripe_customer_portal_link: string;
}

export function create_configuration(): Configuration {
  const config = new ConfigImpl();

  config.node_environment = parse_node_environment(process.env.NODE_ENV);
  config.release_stage = parse_release_stage(process.env.RELEASE_STAGE);
  config.tracking_enabled = config.release_stage === ReleaseStage.PRODUCTION;
  config.api_url = create_api_url(config.release_stage);

  config.stripe_public_key = process.env.STRIPE_PUBLIC_KEY ? process.env.STRIPE_PUBLIC_KEY : undefined;
  if (config.release_stage !== ReleaseStage.LOCAL && !config.stripe_public_key) {
    throw new Error("STRIPE_PUBLIC_KEY not defined");
  }

  config.matomo_url = create_matomo_url(config.release_stage);

  config.app_version = process.env.APP_VERSION;

  config.market_cdf_charts_enabled = config.release_stage !== ReleaseStage.PRODUCTION;
  config.subscriptions_enabled = true;

  config.stripe_customer_portal_link =
    config.release_stage === ReleaseStage.PRODUCTION
      ? "https://billing.stripe.com/p/login/5kAcPx2yA4lP7Xq6oo"
      : "https://billing.stripe.com/p/login/test_7sI8Aqf323becTKfYY";

  return config;
}

class ConfigImpl implements Configuration {
  node_environment = NodeEnvironment.DEVELOPMENT;
  release_stage = ReleaseStage.DEVELOPMENT;
  api_url = "";
  stripe_public_key: string | undefined;
  tracking_enabled = false;
  gtag_id = "AW-11004885001";
  matomo_url: string | undefined;
  matomo_site_id = 2;
  app_version = "<not set>";
  suggestions_fade_in_msecs = 500;
  suggestions_fade_out_msecs = 500;
  market_cdf_charts_enabled = false;
  subscriptions_enabled = false;
  youtube_ids: YoutubeIDs = {
    feed: "2MXttxptQZc?si=Qtybals-r2x_gmiL",
    company_overview: "RPrFI0Q31Zw?si=FLoMiIoLH54pw2XZ",
    discovery: "cCmjwJR7YWg?si=6lmL20uPtVO84hNx",
    company_watchlist: "3QQeDeHyeto?si=uEzg8OGd0QrGPqbc",
  };
  signup_trial_days = 14;
  stripe_customer_portal_link = "https://billing.stripe.com/p/login/5kAcPx2yA4lP7Xq6oo";
}

function create_api_url(release_stage: ReleaseStage): string {
  const use_localhost_backend = process.env.USE_LOCALHOST_BACKEND === "true";

  let api_base_url = "";
  const api_base_path = "/market-data/1.0.0";

  switch (release_stage) {
    case ReleaseStage.PRODUCTION:
      api_base_url = "https://api.xelonic.com";
      break;
    case ReleaseStage.STAGING:
      api_base_url = "https://api.sol4.xelonic.de";
      break;
    case ReleaseStage.DEVELOPMENT:
      api_base_url = "https://api.sol3.xelonic.de";
      break;
    case ReleaseStage.LOCAL:
      api_base_url = use_localhost_backend ? "http://localhost:8081" : "https://api.sol3.xelonic.de";
      break;
  }

  let api_url = api_base_url ?? "";
  if (api_url.length < 1) {
    api_url = "https://api" + location.hostname.substr(location.hostname.indexOf("."));
  }

  api_url += api_base_path ?? "";

  return api_url;
}

function create_matomo_url(release_stage: ReleaseStage): string | undefined {
  switch (release_stage) {
    case ReleaseStage.PRODUCTION:
      return "https://cm.xelonic.com";
    case ReleaseStage.STAGING:
      return undefined;
      break;
    case ReleaseStage.DEVELOPMENT:
      return "https://cm.sol3.xelonic.de";
    case ReleaseStage.LOCAL:
      return "https://cm.sol3.xelonic.de";
  }
}
