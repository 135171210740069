import { RepositoryStore } from "@/api/repository_store";
import { AuthenticationService, get_authentication_service } from "@/services/authentication_service";
import { AuthorizationService } from "@/services/authorization_service";
import Vue from "vue";
import VueRouter from "vue-router";

declare module "vue/types/vue" {
  interface Vue {
    $auth: AuthenticationService;
  }
}

export function install_auth(
  vue: typeof Vue,
  repos: RepositoryStore,
  router: VueRouter,
  authz: AuthorizationService
): AuthenticationService {
  vue.use(AuthPlugin, { repos, router, authz });
  return vue.prototype.$auth;
}

export default class AuthPlugin {
  static install(
    vue: typeof Vue,
    options: { repos: RepositoryStore; router: VueRouter; authz: AuthorizationService }
  ): void {
    vue.prototype.$auth = get_authentication_service(options.repos, options.router, options.authz);
  }
}
