import { RouteRoot } from "@/router/route_constants";
import { reactive } from "vue";
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $route_root: RouteRootPlugin;
  }
}

class ReactiveState {
  route_root: RouteRoot | null = null;
}

export function install_route_root(vue: typeof Vue): RouteRootPlugin {
  vue.use(RouteRootPlugin);
  return vue.prototype.$route_root;
}

/**
 * Used to communicate the route root of RouteTreeNavigation to the navbar's sidebar.
 *
 * The root value is reactive.
 */
export class RouteRootPlugin {
  static install(vue: typeof Vue): void {
    vue.prototype.$route_root = new RouteRootPlugin();
  }

  constructor() {
    this.state_ = reactive(new ReactiveState()) as ReactiveState;
  }

  get value(): RouteRoot | null {
    return this.state_.route_root;
  }

  set value(route_root: RouteRoot | null) {
    this.state_.route_root = route_root;
  }

  get is_set(): boolean {
    return this.state_.route_root !== null;
  }

  clear(): void {
    this.value = null;
  }

  private readonly state_: ReactiveState;
}
