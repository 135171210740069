import { RouteBuilder } from "@xelonic.com/trill";
import { RouterViewContainer } from "@xelonic.com/trill";

export function create_profile_routes(base_path: string, builder: RouteBuilder): void {
  /* eslint-disable prettier/prettier */
  builder.add({
      path: base_path,
      components: {
        default: RouterViewContainer,
        left_sidebar: () =>
          import(
            "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue"
          ),
      },
      props: {
        left_sidebar: () => ({
          route_root: { path: base_path },
        }),
      },
    })
    .enter()
      .add({
        path: "personal-info",
        name: "personal-info",
        component: () => import("@/vue/views/user_profile/PersonalInfo.vue"),
        meta: {
          translation_key: "router.profile.personal_info",
          accessible_before_initial_sub: true,
        },
      })
      // {
      //   path: "invoices",
      //   component: () => import("@/vue/views/user_profile/Invoices.vue"),
      //   meta: {
      //     translation_key: "router.profile.invoices",
      //   },
      // },
      .add({
        path: "",
        redirect: "personal-info",
      })
      .add({
        path: "subscriptions",
        component: RouterViewContainer,
        meta: {
          translation_key: "router.profile.subscriptions",
        },
      })
      .enter()
        .add({
          path: "overview",
          name: "subscriptions",
          component: () => import("@/vue/views/subscriptions/Subscriptions.vue"),
          meta: {
            hidden_in_navigation: true,
          },
        })
        .add({
          path: "creation",
          component: RouterViewContainer,
          meta: {
            hidden_in_navigation: true,
            accessible_before_initial_sub: true,
          },
        })
        .enter()
          .add({
            path: "choose-payment-method",
            name: "sub-choose-payment-method",
            component: () => import("@/vue/views/subscriptions/CreateSubscription.vue"),
            props: (route) => ({
              product_translation_key: route.query.product_translation_key,
              price_id: route.query.price_id,
            })
          })
          .add({
            path: "done",
            component: () => import("@/vue/views/subscriptions/CreationDone.vue"),
            props: (route) => ({
              product_translation_key: route.query.product_translation_key,
              price_id: route.query.price_id,
              session_id: route.query.session_id,
            })
          })
        .exit()
        .add({
          path: "cancel",
          name: "subs-cancel",
          component: () => import("@/vue/views/subscriptions/CancelSubscription.vue"),
          meta: {
            hidden_in_navigation: true,
          },
          props: (route) => ({
            product_translation_key: route.query.product_translation_key,
            subscription_id: route.query.subscription_id,
            transaction_id: route.query.transaction_id,
          })
        })
        .add({
          path: "end",
          name: "subs-end",
          component: () => import("@/vue/views/subscriptions/EndSubscription.vue"),
          meta: {
            hidden_in_navigation: true,
          },
          props: (route) => ({
            product_translation_key: route.query.product_translation_key,
            subscription_id: route.query.subscription_id,
            transaction_id: route.query.transaction_id,
          })
        })
        .add({
          path: "",
          redirect: "overview",
        })
      .exit()
    .exit();
  /* eslint-enable prettier/prettier */
}
