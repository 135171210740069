import { identity_provider_to_api } from "@/api/auth/identity_provider";
import { login_data_from_api } from "@/api/auth/login_data";
import { IdentityProvider } from "@/model/auth/identity_provider";
import { LoginData } from "@/model/auth/login_data";
import { load_unauthorized } from "@xelonic.com/trill";
import { LoadState } from "@xelonic.com/trill";
import { reactive } from "vue";
import { Logger, new_logger } from "@xelonic.com/trill";
import { AuthenticationApi, UserRegistrationData } from "@xelonic.com/xelonic-api";
import { LocalStorageItem } from "../local_storage";
import { refresh_token_data_from_api } from "@/api/auth/refresh_token_data";
import { SubscriptionsInfo } from "@/model/auth/subscriptions_info";

interface LocalStorageUserData {
  readonly expiry: Date;
  readonly roles: string[];
  readonly ever_had_subscriptions: boolean;
  readonly has_alive_subscription: boolean;
}

function try_create_local_storage_user_data(options?: {
  expiry_string?: string;
  roles?: string[];
  ever_had_subscriptions?: boolean;
  has_alive_subscription?: boolean;
}): LocalStorageUserData | null {
  if (!options?.expiry_string) {
    return null;
  }

  const expiry = new Date(options.expiry_string);

  return {
    expiry,
    roles: options.roles ?? [],
    ever_had_subscriptions: options.ever_had_subscriptions ?? false,
    has_alive_subscription: options.has_alive_subscription ?? false,
  };
}

class ReactiveState {
  is_logged_in = false;
  is_active = false;
  roles: string[] = [];
  ever_had_subs = false;
  has_alive_sub = false;
}

/**
 * Wraps the authentication api from dash-back and handles login-state persistence to local storage.
 */
export class AuthRepository {
  constructor(options: { api: AuthenticationApi }) {
    this.logger_ = new_logger("auth_repository");
    this.api_ = options.api;
    this.state_ = reactive(new ReactiveState()) as ReactiveState;

    const user_data = this.get_user_data_from_local_storage();
    if (user_data) {
      this.init_from_local_storage_data(user_data);
    }

    window.addEventListener("storage", () => this.handle_local_storage_changed());
  }

  get expires_in_milliseconds(): number {
    return this.expiry_.getTime() - new Date().getTime();
  }

  /** This property is reactive. */
  get is_logged_in(): boolean {
    return this.state_.is_logged_in;
  }

  /**
   * Active means the user has a valid access token. All auth-dependent backend requests should be done only when
   * the user is active.
   *
   * This property is reactive.
   */
  get is_active(): boolean {
    return this.state_.is_active;
  }

  /** This property is reactive. */
  get user_roles(): string[] {
    return this.state_.roles;
  }

  /** This property is reactive. */
  get ever_had_subs(): boolean {
    return this.state_.ever_had_subs;
  }

  /** This property is reactive. */
  get has_alive_sub(): boolean {
    return this.state_.has_alive_sub;
  }

  async login_with_credentials(username: string, password: string): Promise<LoginData> {
    const response = await this.api_.loginWithCredentials({
      username,
      password,
    });
    const login_data = login_data_from_api(response.data);
    this.handle_successful_login(login_data.expires_in_seconds, login_data.roles, login_data.subs_info);
    return login_data;
  }

  async get_identity_provider_auth_code_url(identity_provider: IdentityProvider): Promise<string> {
    const api_ip = identity_provider_to_api(identity_provider);
    const response = await this.api_.getIdentityProviderAuthCodeURL(api_ip);
    return response.data;
  }

  async login_with_auth_code(auth_code: string): Promise<LoginData> {
    const response = await this.api_.loginWithAuthorizationCode(auth_code);
    const login_data = login_data_from_api(response.data);
    this.handle_successful_login(login_data.expires_in_seconds, login_data.roles, login_data.subs_info);
    return login_data;
  }

  async logout(): Promise<void> {
    try {
      await this.api_.logout("from-cookie");
    } finally {
      this.handle_logout();
    }
  }

  async delete_user(): Promise<void> {
    await this.api_.deleteUser("from-cookie");
  }

  async refresh_access_token(): Promise<void> {
    const response = await this.api_.refreshAccessToken("from-cookie");
    const refresh_token_data = refresh_token_data_from_api(response.data);
    this.handle_successful_login(
      refresh_token_data.expires_in_seconds,
      refresh_token_data.roles,
      refresh_token_data.subs_info
    );
  }

  async create_password_reset_token(username_or_email: string): Promise<void> {
    await this.api_.createPasswordResetToken(username_or_email);
  }

  async reset_password(reset_token: string, password: string): Promise<void> {
    await this.api_.resetPassword(reset_token, password);
  }

  async is_password_reset_token_valid(token: string): Promise<boolean> {
    const load_state = await load_unauthorized(this.logger_, async () => {
      await this.api_.isResetPasswordTokenValid(token);
    });

    return load_state === LoadState.FINISHED_SUCCESS;
  }

  async register_user(username: string, email: string, password: string): Promise<void> {
    const registration_data: UserRegistrationData = {
      username,
      email,
      password,
    };

    await this.api_.registerUser(registration_data);
  }

  on_local_storage_changed(listener: () => void): void {
    if (this.on_local_storage_changed_listener_) {
      throw new Error("listener already registered");
    }

    this.on_local_storage_changed_listener_ = listener;
  }

  handle_logout(): void {
    if (!this.state_.is_logged_in) {
      return;
    }

    localStorage.removeItem(LocalStorageItem.USER);

    this.expiry_ = new Date();
    this.state_.roles = [];
    this.state_.is_logged_in = false;
    this.state_.is_active = false;
    this.state_.ever_had_subs = false;
    this.state_.has_alive_sub = false;
  }

  private handle_successful_login(expires_in_seconds: number, roles: string[], subs_info: SubscriptionsInfo): void {
    this.expiry_ = new Date(new Date().getTime() + expires_in_seconds * 1000);
    this.state_.roles = roles;
    this.state_.is_logged_in = true;
    this.state_.is_active = this.expiry_ > new Date();
    this.state_.ever_had_subs = subs_info.ever_had_one;
    this.state_.has_alive_sub = subs_info.has_alive;

    localStorage.setItem(LocalStorageItem.USER, this.to_json());
  }

  private init_from_local_storage_data(storage_data: LocalStorageUserData | null): void {
    if (!storage_data) {
      this.expiry_ = new Date("2000-02-18:00:00.000000000-00:00"); // any date that's definitely in the past
      this.state_.roles = [];
      this.state_.is_logged_in = false;
      this.state_.is_active = false;
      this.state_.ever_had_subs = false;
      this.state_.has_alive_sub = false;
    } else {
      this.expiry_ = storage_data.expiry;
      this.state_.roles = storage_data.roles;
      this.state_.is_logged_in = true;
      this.state_.is_active = this.expiry_ > new Date();
      this.state_.ever_had_subs = storage_data.ever_had_subscriptions;
      this.state_.has_alive_sub = storage_data.has_alive_subscription;
    }
  }

  private handle_local_storage_changed(): void {
    const user_data = this.get_user_data_from_local_storage();
    const new_is_logged_in = user_data !== null;

    if (this.state_.is_logged_in === new_is_logged_in) {
      return;
    }

    this.init_from_local_storage_data(user_data);

    if (this.on_local_storage_changed_listener_) {
      this.on_local_storage_changed_listener_();
    }
  }

  private get_user_data_from_local_storage(): LocalStorageUserData | null {
    const stored_user_json = localStorage.getItem(LocalStorageItem.USER);

    if (stored_user_json === null) {
      return null;
    }

    const user_data = JSON.parse(stored_user_json);

    return try_create_local_storage_user_data({
      expiry_string: user_data.expiry,
      roles: user_data.roles,
      ever_had_subscriptions: user_data.ever_had_subscriptions,
      has_alive_subscription: user_data.has_alive_subscription,
    });
  }

  private to_json(): string {
    return JSON.stringify({
      expiry: this.expiry_,
      roles: this.state_.roles,
      ever_had_subscriptions: this.state_.ever_had_subs,
      has_alive_subscription: this.state_.has_alive_sub,
    });
  }

  private readonly logger_: Logger;
  private readonly api_: AuthenticationApi;
  private readonly state_: ReactiveState;
  private expiry_: Date = new Date();
  private on_local_storage_changed_listener_: (() => void) | null = null;
}
