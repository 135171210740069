import { SecurityID, security_id_from_string } from "@/model/security_id";
import { Route } from "vue-router";

export function security_id_from_route(route: Route): SecurityID {
  return security_id_from_string(route.params.security_id);
}

export function props_security_id_from_route(route: Route): {
  security_id: SecurityID;
} {
  return {
    security_id: security_id_from_route(route),
  };
}

export function security_id_list_from_route_query(route: Route): SecurityID[] {
  const ids = route.query["security_ids"];
  if (!ids) {
    return [];
  }

  return (ids as string)
    .split(",")
    .map((security_id: string) => {
      try {
        return security_id_from_string(security_id);
      } catch (e) {
        return undefined;
      }
    })
    .filter((security_id: SecurityID | undefined) => {
      if (!security_id) {
        // TODO: this sucks, can't see a way to get a log instance here
        throw new Error("Security ID in invalid format.");
      }

      return true;
    })
    .map((value: SecurityID | undefined) => {
      return value!;
    });
}
