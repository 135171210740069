import Vue, { reactive } from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";

declare module "vue/types/vue" {
  interface Vue {
    $xrouter: XRouter;
  }
}

export function install_xrouter(vue: typeof Vue, router: VueRouter, routes: RouteConfig[]): XRouter {
  vue.use(XRouter, { router, routes });
  return vue.prototype.$xrouter;
}

class ReactiveState {
  path = "/";
}

export class XRouter {
  static install(
    vue: typeof Vue,
    options: {
      router: VueRouter;
      routes: RouteConfig[];
    }
  ): void {
    vue.prototype.$xrouter = new XRouter(options.router, options.routes);
  }

  constructor(router: VueRouter, routes: RouteConfig[]) {
    this.router_ = router;
    this.routes_ = routes;

    this.state_ = reactive(new ReactiveState()) as ReactiveState;
    this.state_.path = window.location.pathname;

    router.afterEach((to) => this.handle_route_changed(to));
  }

  push(location: RawLocation): void {
    this.router_.push(location);
  }

  get routes(): RouteConfig[] {
    return this.routes_;
  }

  // this property is reactive
  get path(): string {
    return this.state_.path;
  }

  private handle_route_changed(to: Route): void {
    this.state_.path = to.path;
  }

  private readonly router_: VueRouter;
  private readonly state_: ReactiveState;
  private routes_: RouteConfig[] = [];
}
