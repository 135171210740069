import { render, staticRenderFns } from "./DashLanguageChoiceDropdown.vue?vue&type=template&id=3a8f1fb0&scoped=true"
import script from "./DashLanguageChoiceDropdown.vue?vue&type=script&lang=ts"
export * from "./DashLanguageChoiceDropdown.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8f1fb0",
  null
  
)

export default component.exports