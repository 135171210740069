export class Language {
  readonly language_code: string;
  readonly country_code: string;
  readonly title: string;
  readonly locale: string;
  readonly flag_class: string;

  constructor(language_code: string, country_code: string, title: string, flag_class?: string) {
    this.language_code = language_code.toLowerCase();
    this.country_code = country_code.toLowerCase();
    this.title = title;
    this.locale = `${this.language_code}-${this.country_code}`;
    this.flag_class = flag_class ?? this.language_code;
  }

  equal_to(other: Language): boolean {
    return this.language_code === other.language_code && this.country_code === other.country_code;
  }

  toString(): string {
    return this.locale;
  }
}
