export function capitalize_words(str: string): string {
  return str
    .split(" ")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}

export function capitalize_begin(str: string): string {
  return str[0].toUpperCase() + str.substring(1);
}
