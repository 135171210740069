import { CompanyWatchlistSettings } from "@/model/user/company_watchlist_settings";
import { get_locale, has_items, is_defined } from "@xelonic.com/trill";

export class UserPreferences {
  constructor(options?: {
    locale?: string;
    sifted_infos?: Record<string, number>;
    accepted_cookies?: boolean;
    company_watchlist?: CompanyWatchlistSettings;
  }) {
    this.locale_ = has_items(options?.locale) ? options!.locale : get_locale();
    this.sifted_infos_ = options?.sifted_infos ?? {};
    this.accepted_cookies_ = options?.accepted_cookies ?? false;
    this.company_watchlist_ = options?.company_watchlist ?? new CompanyWatchlistSettings();
  }

  get locale(): string {
    return this.locale_;
  }

  set locale(value: string) {
    this.locale_ = value;
  }

  info_sifted(id: string, version: number): boolean {
    const sifted_version = this.sifted_infos_[id];
    return is_defined(sifted_version) && sifted_version >= version;
  }

  set_info_sifted(id: string, version: number): void {
    if (this.info_sifted(id, version)) {
      return;
    }

    this.sifted_infos_[id] = version;
  }

  get sifted_infos(): Record<string, number> {
    return this.sifted_infos_;
  }

  get accepted_cookies(): boolean {
    return this.accepted_cookies_;
  }

  set accepted_cookies(value: boolean) {
    this.accepted_cookies_ = value;
  }

  get company_watchlist(): CompanyWatchlistSettings {
    return this.company_watchlist_;
  }

  private locale_: string;
  private readonly sifted_infos_: Record<string, number>;
  private accepted_cookies_: boolean;
  private readonly company_watchlist_: CompanyWatchlistSettings;
}
