import { Entity } from "@/model/entities/entity";
import { Address } from "../address";

export class GeneralInfo {
  constructor(
    entity: Entity,
    options?: {
      address?: Address;
    }
  ) {
    this.entity_ = entity;
    this.address_ = options?.address;
  }

  get entity(): Entity {
    return this.entity_;
  }

  get address(): Address | undefined {
    return this.address_;
  }

  private readonly entity_: Entity;
  private readonly address_?: Address;
}
