import { SecurityId, SecurityIdType } from "@xelonic.com/xelonic-api";

export function security_id_to_string(security_id: SecurityId): string {
  return `${security_id.type}-${security_id.id}`;
}

export enum SecurityCategory {
  NON_DERIVATIVE = "non_derivative",
  DERIVATIVE = "derivative",
}

export enum SecurityGroup {
  ALL = "all",
  SECTOR = "sector",
  INDUSTRY = "industry",
}

export function security_id_from_string(str: string): SecurityId | undefined {
  const dash_index = str.indexOf("-");
  if (dash_index < 1) {
    // 1: empty ID is also ill-formed
    return undefined;
  }
  const id_type_str = str.substring(0, dash_index);
  const id = str.substring(dash_index + 1);

  const id_type = security_id_type_from_string(id_type_str);
  if (!id_type) {
    return undefined;
  }

  return {
    type: id_type,
    id: id,
  };
}

function security_id_type_from_string(str: string): SecurityIdType | undefined {
  str = str.toLowerCase();

  for (const id_type_index of enumKeys(SecurityIdType)) {
    if (SecurityIdType[id_type_index] == str) {
      return SecurityIdType[id_type_index];
    }
  }

  return undefined;
}

function enumKeys<O extends Record<string, unknown>, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj) as K[];
}
