import { RelativeValue } from "@/model/changed_value";
import { Price } from "@/model/price";
import { DateTimePoint } from "@/model/time/date_time_point";
import { TimePoint } from "@/model/time/time_point";
import { YearMonthDay } from "@/model/time/year_month_day";
import { Quarter, YearQuarter } from "@/model/time/year_quarter";
import { ValueComponent } from "@/model/values/value_component";
import { TypedNumber, TypedValue, ValueTypeIdentifier } from "@/model/values/value_type";
import { Logger, new_logger } from "@xelonic.com/trill";
import Big from "big.js";
import Vue from "vue";
import VueI18n, { DateTimeFormatOptions, NumberFormatOptions } from "vue-i18n";
import { ValueType } from "../model/values/value_type";

declare module "vue/types/vue" {
  interface Vue {
    $fmt: ValueFormatter;
  }
}

export type AdditionalStyle = NumberFormatOptions | DateTimeFormatOptions;

export function install_value_formatter(vue: typeof Vue, i18n: VueI18n): ValueFormatter {
  vue.use(ValueFormatter, { i18n });
  return vue.prototype.$fmt;
}

export default class ValueFormatter {
  static install(vue: typeof Vue, options: { i18n: VueI18n }): void {
    const formatter = new ValueFormatter(options.i18n);

    vue.prototype.$fmt = formatter;
  }

  constructor(i18n: VueI18n) {
    this.i18n = i18n;
    this.logger = new_logger("value_formatter");
  }

  typed_number(value: TypedNumber, additional_style?: NumberFormatOptions): string {
    return this.value(value.value, value.value_type, additional_style);
  }

  typed_number_long(value: TypedNumber): string {
    return this.value_long(value.value, value.value_type);
  }

  typed_value(value: TypedValue, additional_style?: NumberFormatOptions): string {
    return this.value(value.value, value.value_type, additional_style);
  }

  typed_value_long(value: TypedValue): string {
    return this.value_long(value.value, value.value_type);
  }

  currency(value: number | string, currency: string, fixed_point?: number): string {
    return this.value(value, new ValueType(ValueTypeIdentifier.CURRENCY, currency, fixed_point));
  }

  currency_long(value: number | string, currency: string, fixed_point?: number): string {
    return this.value_long(value, new ValueType(ValueTypeIdentifier.CURRENCY, currency, fixed_point));
  }

  date(value: number | string | Date | YearMonthDay, additional_style?: DateTimeFormatOptions): string {
    return this.value(value, new ValueType(ValueTypeIdentifier.DATE), additional_style);
  }

  time_point(value: TimePoint): string {
    if (value instanceof YearMonthDay) {
      return this.format_year_month_day(value);
    }

    if (value instanceof YearQuarter) {
      return this.format_year_quarter(value);
    }

    if (value instanceof DateTimePoint) {
      return this.date(value.date);
    }

    this.logger.info("cannot format time point: invalid type; resorting to n/a");
    return "n/a";
  }

  number(value: number | Big, additional_style?: AdditionalStyle): string {
    return this.value(value, new ValueType(ValueTypeIdentifier.NUMBER), additional_style);
  }

  number_long(value: number | Big, exactDigits?: number): string {
    let additional_style = undefined;
    if (exactDigits) {
      additional_style = {
        minimumFractionDigits: exactDigits,
        maximumFractionDigits: exactDigits,
      };
    }
    return this.value_long(value, new ValueType(ValueTypeIdentifier.NUMBER), additional_style);
  }

  percentage(value: number): string {
    return this.value(value, new ValueType(ValueTypeIdentifier.PERCENTAGE));
  }

  identifier(value: string): string {
    return this.value(value, new ValueType(ValueTypeIdentifier.IDENTIFIER));
  }

  price(price: Price): string {
    return this.currency_long(price.value, price.currency);
  }

  relative_currency(value: RelativeValue, currency: string): string {
    return `${this.currency_long(value.absolute, currency)} (${this.percentage(value.relative)})`;
  }

  relative_value(value: RelativeValue): string {
    return `${value.absolute} (${this.percentage(value.relative)})`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value(
    value: number | string | Date | Big | YearMonthDay,
    type?: ValueType,
    additional_style?: NumberFormatOptions | DateTimeFormatOptions
  ): string {
    const style: NumberFormatOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      notation: "compact",
    };

    if (type !== undefined) {
      switch (type.id) {
        case ValueTypeIdentifier.STRING:
          return value as string;
        case ValueTypeIdentifier.DATE:
          if (value instanceof YearMonthDay) {
            return this.format_year_month_day(value as YearMonthDay);
          }

          if (value instanceof Big) {
            value = value.toNumber();
          }

          return this.format_date(new Date(value as number | string | Date));
        case ValueTypeIdentifier.IDENTIFIER:
          return this.i18n.t(value as string).toString();
        case ValueTypeIdentifier.CURRENCY:
          Object.assign(style, {
            style: "currency",
            currency: type.currency,
          });
          break;
        case ValueTypeIdentifier.PERCENTAGE:
          Object.assign(style, {
            style: "percent",
            notation: "standard",
          });
          break;
        case ValueTypeIdentifier.NUMBER:
          break;
        default:
          this.logger.error(`Unknown type id: '${type.id}'`);
          return value.toString();
      }

      if (type.fixed_point && type.fixed_point > 0) {
        if (value instanceof Big) {
          value = (value as Big).div(type.fixed_point);
        } else {
          value = (value as number) / type.fixed_point;
        }
      }
    }

    if (additional_style !== undefined) {
      Object.assign(style, additional_style);
    }

    if (value instanceof Big) {
      const b_value = value as Big;
      if (b_value.gt(Number.MAX_VALUE)) {
        return b_value.toString();
      }

      value = b_value.toNumber();
    }

    return this.i18n.n(value as number, style);
  }

  value_long(value: number | string | Big, type: ValueType, additional_style?: NumberFormatOptions): string {
    let style: NumberFormatOptions = {
      notation: "standard",
    };

    if (additional_style) {
      style = { ...style, ...additional_style };
    }

    return this.value(value, type, style);
  }

  format_date(date: Date): string {
    // We apply a non-locale formatting here because at least in the tables and the charts
    // we want the date components sorted as below. So we just apply it everywhere to have a coherent
    // representation.

    const year = date.getUTCFullYear().toString().padStart(4, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  format_year_month_day(ymd: YearMonthDay): string {
    let str = `${ymd.year}`.padStart(4, "0");

    if (ymd.month === undefined) {
      return str;
    }

    const month = `${ymd.month}`.padStart(2, "0");
    str += `-${month}`;

    if (ymd.day === undefined) {
      return str;
    }

    const day = `${ymd.day}`.padStart(2, "0");
    str += `-${day}`;

    return str;
  }

  format_year_quarter(yq: YearQuarter): string {
    const year = `${yq.year}`.padStart(4, "0");
    return `${year}-${this.format_quarter(yq.quarter)}`;
  }

  format_quarter(quarter: Quarter): string {
    switch (quarter) {
      case Quarter.Q1:
        return "Q1";
      case Quarter.Q2:
        return "Q2";
      case Quarter.Q3:
        return "Q3";
      case Quarter.Q4:
        return "Q4";
    }
  }

  currency_symbol(currency: string): string {
    const fmt = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currency,
    });
    return fmt.formatToParts(1).find((part) => (part.type = "currency"))?.value ?? "";
  }

  /**
   * Format the given formula instantiated. E.g. (a+b)/c becomes (3+5)/7.
   */
  ratio(ratio: ValueComponent[]): string {
    return this.format_ratio(ratio, this.value.bind(this));
  }

  /**
   * Format the given formula instantiated. E.g. (a+b)/c becomes (3+5)/7.
   */
  ratio_long(ratio: ValueComponent[]): string {
    return this.format_ratio(ratio, this.value_long.bind(this));
  }

  /**
   * Format the given formula. E.g. (a+b)/c.
   */
  ratio_formula(ratio: ValueComponent[]): string {
    if (ratio.length < 1) {
      return "";
    }

    function format_component(c: { namespace: string; tag: string; exponent: number }) {
      const exponent = c.exponent == 1 ? "" : `^${c.exponent}`;
      return `${c.namespace}:${c.tag}${exponent}`;
    }

    const numerators = ratio.filter((component: ValueComponent) => component.exponent >= 0);
    const denominators = ratio.filter((component: ValueComponent) => component.exponent < 0);

    let numerator = numerators.map(format_component).join("*");

    let denominator = denominators
      .map((component: ValueComponent) => {
        return {
          namespace: component.namespace,
          tag: component.tag,
          exponent: -component.exponent,
        };
      })
      .map(format_component)
      .join("*");

    if (numerators.length < 1) {
      numerator = "1";
    } else if (numerators.length > 1) {
      numerator = `(${numerator})`;
    }

    if (denominator.length < 1) {
      denominator = "";
    } else {
      if (denominators.length > 1) {
        denominator = `(${denominator})`;
      }
      denominator = ` / ${denominator}`;
    }

    return `${numerator}${denominator}`;
  }

  table_value(value: number | string | Big, value_type: ValueType, additional_style?: NumberFormatOptions): string {
    additional_style = {
      ...{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      ...additional_style,
    };
    switch (value_type.id) {
      case ValueTypeIdentifier.PERCENTAGE:
        return `${this.value_long(
          (value as number) * 100,
          new ValueType(ValueTypeIdentifier.NUMBER),
          additional_style
        )}%`;
      case ValueTypeIdentifier.CURRENCY: {
        const number_value_type = new ValueType(ValueTypeIdentifier.NUMBER, undefined, value_type.fixed_point);
        return this.value_long(value, number_value_type, additional_style);
      }
      default:
        return this.value_long(value, value_type);
    }
  }

  private format_ratio(
    ratio: ValueComponent[],
    value_formatter: (value: Big, value_type: ValueType) => string
  ): string {
    if (ratio.length < 1) {
      return "";
    }

    function format_value(c: { value: Big; value_type: ValueType; exponent: number }) {
      const value = value_formatter(c.value, c.value_type);
      const exponent = c.exponent == 1 ? "" : `^${c.exponent}`;
      return `${value}${exponent}`;
    }

    const numerators = ratio.filter((component: ValueComponent) => component.exponent >= 0);
    const denominators = ratio.filter((component: ValueComponent) => component.exponent < 0);

    let numerator = numerators.map(format_value).join("*");

    let denominator = denominators
      .map((component: ValueComponent) => {
        return {
          value: component.value,
          value_type: component.value_type,
          exponent: -component.exponent,
        };
      })
      .map(format_value)
      .join("*");

    if (numerators.length < 1) {
      numerator = "1";
    } else if (numerators.length > 1) {
      numerator = `(${numerator})`;
    }

    if (denominator.length < 1) {
      denominator = "";
    } else {
      if (denominators.length > 1) {
        denominator = `(${denominator})`;
      }
      denominator = ` / ${denominator}`;
    }

    return `${numerator}${denominator}`;
  }

  scale_divisor(value: number): string | undefined {
    const name = this.scale_names_[value];
    if (!name) {
      return undefined;
    }

    return this.i18n.t(`value_formatter.scales.${name}`) as string;
  }

  private i18n: VueI18n;
  private logger: Logger;
  private readonly scale_names_: Record<number, string> = {
    1000: "thousand",
    1000000: "million",
    1000000000: "billion",
    1000000000000: "trillion",
  };
}
