import { Location } from "@/model/location";

export class Address {
  constructor(formatted_address: string, location?: Location, phone?: string) {
    this.formatted_address_ = formatted_address;
    this.location_ = location;
    this.phone_ = phone;
  }

  get formatted_address(): string {
    return this.formatted_address_;
  }

  get location(): Location | undefined {
    return this.location_;
  }

  get phone(): string | undefined {
    return this.phone_;
  }

  private readonly formatted_address_: string;
  private readonly location_?: Location;
  private readonly phone_?: string;
}
