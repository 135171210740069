export enum TimePointType {
  TIME = "time",
  YEAR_MONTH_DAY = "year-month-day",
  YEAR_QUARTER = "year-quarter",
  TIME_CURRENT_DAY = "time-current-day",
}

export interface TimePoint {
  readonly type: TimePointType;

  before(other: TimePoint): boolean;
  after(other: TimePoint): boolean;
  equal_to(other: TimePoint): boolean;
  truncate_by_minutes(minutes: number, use_ceil: boolean): TimePoint;
  to_date(): Date;

  to_string(): string;
}

export function compare_time_points_descending(lhs: TimePoint, rhs: TimePoint): number {
  if (lhs.after(rhs)) {
    return -1;
  }

  if (lhs.before(rhs)) {
    return 1;
  }

  return 0;
}

export function compare_time_points_ascending(lhs: TimePoint, rhs: TimePoint): number {
  if (lhs.before(rhs)) {
    return -1;
  }

  if (lhs.after(rhs)) {
    return 1;
  }

  return 0;
}
