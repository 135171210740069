<template>
  <div>
    <b-navbar class="d-block d-lg-none bottom">
      <b-navbar-nav class="justify-content-center">
        <b-nav-item @click="scroll_to_top()">
          <router-link class="nav-link" to=""><font-awesome-icon :icon="arrow_up_icon" /></router-link>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="justify-content-center">
        <b-nav-item class="my-auto">
          <language-choice-dropdown />
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto justify-content-center flex-row">
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'imprint' }" target="_blank">{{
            $ls("router.imprint")
          }}</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'privacy-notice' }" target="_blank">{{
            $ls("router.privacy")
          }}</router-link>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto justify-content-center flex-row">
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'linkedin' }" target="_blank">
            <span><font-awesome-icon :icon="linkedin_icon" /></span
          ></router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'xing' }" target="_blank">
            <span><font-awesome-icon :icon="xing_icon" /></span
          ></router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'github' }" target="_blank">
            <span><font-awesome-icon :icon="github_icon" /></span>
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'youtube' }" target="_blank">
            <span><font-awesome-icon :icon="youtube_icon" /></span
          ></router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link class="nav-link pl-2 pr-2" :to="{ name: 'facebook' }" target="_blank">
            <span><font-awesome-icon :icon="facebook_icon" /></span
          ></router-link>
        </b-nav-item>
      </b-navbar-nav>
      <b-nav-text class="text-small text-center text-muted">
        {{ $ls("footer_nav.no_guarantee_correctness") }}
        <span class="whitespace-nowrap">© Copyright {{ year }} — {{ $ls("company.name") }}</span>
      </b-nav-text>
      <b-nav-text class="text-small d-flex justify-content-center">
        <a href="https://clearbit.com" target="_blank" class="text-muted">{{
          $ls("app.logos_provided_by_clearbit")
        }}</a>
      </b-nav-text>
    </b-navbar>
    <nav class="d-none d-lg-block navbar navbar-expand-lg bottom">
      <b-container class="flex-wrap">
        <b-row class="w-100">
          <b-col cols="5" class="justify-content-start text-small">
            {{ $ls("footer_nav.no_guarantee_correctness") }}
            <span class="whitespace-nowrap">© Copyright {{ year }} — {{ $ls("company.name") }} </span>
          </b-col>
          <b-col cols="1">
            <b-navbar-nav class="justify-content-end">
              <b-nav-item @click="scroll_to_top()">
                <router-link class="nav-link" to=""><font-awesome-icon :icon="arrow_up_icon" /></router-link>
              </b-nav-item>
            </b-navbar-nav>
          </b-col>
          <b-col cols="6">
            <b-navbar-nav class="justify-content-end">
              <b-nav-item>
                <language-choice-dropdown />
              </b-nav-item>
              <b-nav-text>|</b-nav-text>
              <b-nav-item :to="{ name: 'linkedin' }" target="_blank">
                <font-awesome-icon :icon="linkedin_icon" />
              </b-nav-item>
              <b-nav-item :to="{ name: 'xing' }" target="_blank">
                <font-awesome-icon :icon="xing_icon" />
              </b-nav-item>
              <b-nav-item :to="{ name: 'github' }" target="_blank">
                <font-awesome-icon :icon="github_icon" />
              </b-nav-item>
              <b-nav-item :to="{ name: 'youtube' }" target="_blank">
                <font-awesome-icon :icon="youtube_icon" />
              </b-nav-item>
              <b-nav-item :to="{ name: 'facebook' }" target="_blank">
                <font-awesome-icon :icon="facebook_icon" />
              </b-nav-item>
            </b-navbar-nav>
          </b-col>
        </b-row>
        <b-row class="w-100" align-h="center">
          <b-col class="d-flex justify-content-center">
            <b-nav-item :to="{ name: 'imprint' }" target="_blank">
              {{ $ls("router.imprint") }}
            </b-nav-item>
            <b-nav-item :to="{ name: 'agb' }" target="_blank">
              {{ $ls("router.agb") }}
            </b-nav-item>
            <b-nav-item :to="{ name: 'privacy-notice' }" target="_blank">
              {{ $ls("router.privacy") }}
            </b-nav-item>
            <b-nav-item :to="{ name: 'cookie-policy' }" target="_blank">
              {{ $ls("router.cookie_policy") }}
            </b-nav-item>
          </b-col>
        </b-row>
        <b-row class="w-100" align-h="center">
          <b-col>
            <b-nav-text class="text-small d-flex justify-content-center">
              <a href="https://clearbit.com" target="_blank" class="text-muted">{{
                $ls("app.logos_provided_by_clearbit")
              }}</a>
            </b-nav-text>
          </b-col>
        </b-row>
      </b-container>
    </nav>
  </div>
</template>

<script lang="ts">
import LanguageChoiceDropdown from "@/vue/components/DashLanguageChoiceDropdown.vue";
import { Component, Vue } from "vue-property-decorator";
import { BContainer, BRow, BCol } from "bootstrap-vue/esm/components/layout";
import { BNavbar, BNavbarNav } from "bootstrap-vue/esm/components/navbar";
import { BNavItem, BNavText } from "bootstrap-vue/esm/components/nav";

import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faXing } from "@fortawesome/free-brands-svg-icons/faXing";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

@Component({
  components: {
    BCol,
    BContainer,
    BNavbar,
    BNavbarNav,
    BNavItem,
    BNavText,
    BRow,
    LanguageChoiceDropdown,
  },
})
export default class FooterNav extends Vue {
  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get year(): number {
    return new Date().getFullYear();
  }

  get arrow_up_icon(): IconDefinition {
    return faArrowUp;
  }

  get linkedin_icon(): IconDefinition {
    return faLinkedin;
  }

  get xing_icon(): IconDefinition {
    return faXing;
  }

  get github_icon(): IconDefinition {
    return faGithub;
  }

  get youtube_icon(): IconDefinition {
    return faYoutube;
  }

  get facebook_icon(): IconDefinition {
    return faFacebook;
  }

  scroll_to_top(): void {
    const top_el = document.getElementById("top");
    if (top_el) {
      top_el.scrollIntoView();
    }
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------
}
</script>

<style lang="scss" scoped>
.footer-container {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

a {
  color: #2c3e50c2;
}

a:link {
  color: #2c3e50c2;
}

a:hover {
  color: #192531;
  text-decoration: none;
}

li {
  text-decoration: none;
  display: inline-block;
  position: relative;
}
li:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #ccc;
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}
li:hover:after {
  width: 88%;
  left: 6%;
}
</style>
