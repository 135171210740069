import { is_object } from "@xelonic.com/trill";

export class TooltipService {
  constructor(root: Vue) {
    this.tooltips_ = new TooltipManager(root, "tooltip");
    this.popovers_ = new TooltipManager(root, "popover");
  }

  hide_all(): void {
    this.tooltips_.hide_all();
    this.popovers_.hide_all();
  }

  private readonly tooltips_: TooltipManager;
  private readonly popovers_: TooltipManager;
}

class TooltipManager {
  constructor(root: Vue, type: string) {
    this.root_ = root;
    this.type_ = type;

    this.root_.$on(`bv::${type}::shown`, (event: unknown) => this.on_tooltip_shown(event));
    this.root_.$on(`bv::${type}::hidden`, (event: unknown) => this.on_tooltip_hidden(event));
  }

  hide_all(): void {
    this.root_.$emit(`bv::hide::${this.type_}`);
  }

  private on_tooltip_shown(event: unknown): void {
    const tooltip_id = this.get_tooltip_id(event);
    if (!tooltip_id) {
      return;
    }

    this.shown_tooltips_[tooltip_id] = true;

    this.hide_all_but(tooltip_id);
  }

  private on_tooltip_hidden(event: unknown): void {
    const tooltip_id = this.get_tooltip_id(event);
    if (!tooltip_id) {
      return;
    }

    delete this.shown_tooltips_[tooltip_id];
  }

  private get_tooltip_id(event: unknown): string | undefined {
    if (!is_object(event)) {
      return undefined;
    }

    if (typeof event.componentId !== "string") {
      return undefined;
    }

    return event.componentId;
  }

  private hide_all_but(tooltip_id: string): void {
    for (const shown_tooltip_id in this.shown_tooltips_) {
      if (shown_tooltip_id === tooltip_id) {
        continue;
      }

      this.root_.$emit(`bv::hide::${this.type_}`, shown_tooltip_id);
    }
  }

  private readonly root_: Vue;
  private readonly type_: string;
  private shown_tooltips_: Record<string, boolean> = {};
}
