import { TimePoint, TimePointType } from "@/model/time/time_point";

export enum Quarter {
  Q1 = "q1",
  Q2 = "q2",
  Q3 = "q3",
  Q4 = "q4",
}

function quarter_from_string(str: string): Quarter {
  switch (str.toLowerCase()) {
    case Quarter.Q1:
      return Quarter.Q1;
    case Quarter.Q2:
      return Quarter.Q2;
    case Quarter.Q3:
      return Quarter.Q3;
    case Quarter.Q4:
      return Quarter.Q4;
  }

  // to satisfy a typescript compiler error
  return Quarter.Q1;
}

export class YearQuarter implements TimePoint {
  static from_string(str: string): YearQuarter {
    const year_quarter_regex = /^(\d{4})-(q[1-4])$/i;

    const match = str.match(year_quarter_regex);
    if (!match) {
      throw new Error(`invalid year-quarter: ${str}`);
    }

    return new YearQuarter(parseInt(match[1]), quarter_from_string(match[2]));
  }

  constructor(year: number, quarter: Quarter) {
    this.year_ = year;
    this.quarter_ = quarter;
  }

  get type(): TimePointType {
    return TimePointType.YEAR_QUARTER;
  }

  get year(): number {
    return this.year_;
  }

  get quarter(): Quarter {
    return this.quarter_;
  }

  before(other: TimePoint): boolean {
    if (!(other instanceof YearQuarter)) {
      throw new Error("cannot compare timepoint. Not a YearQuarter.");
    }

    if (this.year_ < other.year) {
      return true;
    }

    if (this.year > other.year) {
      return false;
    }

    return this.quarter_ < other.quarter;
  }

  after(other: TimePoint): boolean {
    if (!(other instanceof YearQuarter)) {
      throw new Error("cannot compare timepoint. Not a YearQuarter.");
    }

    if (this.year_ < other.year) {
      return false;
    }

    if (this.year > other.year) {
      return true;
    }

    return this.quarter_ > other.quarter;
  }

  equal_to(other: TimePoint): boolean {
    if (!(other instanceof YearQuarter)) {
      return false;
    }

    return this.year_ === other.year_ && this.quarter_ === other.quarter_;
  }

  truncate_by_minutes(): TimePoint {
    return new YearQuarter(this.year_, this.quarter_);
  }

  to_date(): Date {
    throw new Error("cannot convert year-quarter to date");
  }

  to_string(): string {
    return `${this.year_}-${this.quarter_}`;
  }

  private readonly year_: number;
  private readonly quarter_: Quarter;
}
