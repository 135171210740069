<template>
  <div class="navbar-light bg-white" id="navbar">
    <!--
      Navbar Mobile Concept
      --------------

      There's a single design for mobile and desktop. We'll leverage the option to define orders of flex items.
      On mobile we'll reorder the search bar to be at the end and expand it to full width so that it gets wrapped
      to the next line.

      The rest of the items is then aligned and padded/margined so that it looks good on mobile.
    -->
    <b-navbar type="light" variant="" class="px-0 d-flex flex-wrap flex-xl-nowrap justify-content-between">
      <!--
        flex-max-md-1: make the three top-row items equal width (so that the logo is in the middle)
      -->
      <b-navbar-nav class="order-1 flex-max-md-1" :class="left_sidebar_display_class">
        <span v-show="$route_root.is_set">
          <b-nav-item
            id="left-sidebar-button"
            class="sidebar-no-close-element d-flex"
            :aria-label="$ls('navbar.aria.sidebar_button')"
            link-classes="d-flex align-items-center"
            @click="sidebar_visible = !sidebar_visible"
          >
            <font-awesome-icon :icon="bars_icon" size="2xl" />
          </b-nav-item>
          <sidebar :visible="sidebar_visible">
            <router-tree-navigation :route_root="$route_root.value" />
          </sidebar>
        </span>
      </b-navbar-nav>

      <!--
        flex-max-lg-1: make the three top-row items equal width (so that the logo is in the middle)
        mr-auto: push the item to the left
      -->
      <b-navbar-nav class="mr-auto order-2 flex-max-md-1">
        <b-navbar-brand :to="{ name: 'home' }" class="mr-0 brand"> <strong>xe</strong> / dash </b-navbar-brand>
      </b-navbar-nav>

      <!--
        xl-* attributes: make the search bar grow on focus, together with the .search-active class
        order-4, flex-max-lg-100: move the bar to the very right and span it to full width (wrapping it to
          the next row)
      -->
      <b-navbar-nav
        v-if="$auth.user_has_role('api_search')"
        class="ml-xl-auto flex-xl-grow order-4 order-xl-3 flex-max-lg-100 mt-3 mt-xl-0"
        :class="{ 'search-active': is_searchbar_active }"
      >
        <b-nav-form @submit.stop.prevent="submit_search" form-class="px-xl-2 flex-nowrap flex-grow-1">
          <b-form-input
            size="sm"
            class="mr-sm-2 search flex-grow-1"
            :placeholder="$ls('navbar.search_placeholder')"
            v-model="search_term"
            @focus="is_searchbar_active = true"
            @blur="is_searchbar_active = false"
          ></b-form-input>
          <b-button
            size="sm"
            class="my-2 my-sm-0 ml-1"
            type="submit"
            :disabled="is_search_running"
            :aria-label="$ls('navbar.aria.search_button')"
          >
            <font-awesome-icon :icon="search_icon" />
          </b-button>
        </b-nav-form>
      </b-navbar-nav>

      <!--
        order-*: flip this item and the search bar on mobile
        flex-max-md-1: make the three top-row items equal width (so that the logo is in the middle)
        justify-content-end: right-align children
      -->
      <b-navbar-nav class="order-3 order-xl-4 d-flex flex-max-md-1 justify-content-end">
        <b-nav-item
          v-if="!is_on_initial_sub_path"
          :class="{ 'nav-item-selected': is_on_home_path }"
          to="/"
          :aria-label="$ls('navbar.aria.home_button')"
          class="d-flex nav-item"
          link-classes="d-flex align-items-center"
        >
          <font-awesome-icon :icon="home_icon" />
        </b-nav-item>
        <b-nav-item
          v-if="$auth.user_has_role('api_suggestions')"
          :class="{ 'nav-item-selected': is_on_discover_path }"
          to="/discover"
          :aria-label="$ls('navbar.aria.discover_button')"
          class="d-flex nav-item"
          link-classes="d-flex align-items-center"
        >
          <font-awesome-icon :icon="globe_icon" />
        </b-nav-item>
        <b-nav-item
          v-if="$auth.user_has_role('api_watchlists')"
          :class="{ 'nav-item-selected': is_on_watchlist_path }"
          to="/watchlist"
          :aria-label="$ls('navbar.aria.watchlist_button')"
          class="d-flex nav-item"
          link-classes="d-flex align-items-center"
        >
          <font-awesome-icon :icon="list_icon" />
        </b-nav-item>
        <b-nav-item-dropdown
          v-if="$auth.is_active"
          right
          :class="{ 'nav-item-selected': is_on_profile_path }"
          class="d-flex align-items-center ml-2 nav-item"
          toggle-class="pr-0"
          :aria-label="$ls('navbar.aria.user_button')"
        >
          <template #button-content>
            <font-awesome-icon :icon="user_icon" />
          </template>
          <b-dropdown-item to="/profile/personal-info">
            {{ $ls("router.profile.personal_info") }}
          </b-dropdown-item>
          <b-dropdown-item to="/profile/subscriptions">
            {{ $ls("router.profile.subscriptions") }}
          </b-dropdown-item>
          <b-dropdown-divider v-if="$auth.user_has_role('content_creation')"></b-dropdown-divider>
          <b-dropdown-item v-if="$auth.user_has_role('content_creation')" to="/content-creation">
            {{ $ls("router.profile.content_creation") }}
          </b-dropdown-item>
          <!-- <b-dropdown-item to="/settings">
            {{ $ls("router.settings.name") }}
          </b-dropdown-item> -->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-on:click="logout">
            {{ $ls("router.logout") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item
          v-else
          :to="{ name: 'login' }"
          :aria-label="$ls('navbar.aria.login_button')"
          class="d-flex nav-item"
          :class="{ 'nav-item-selected': is_on_auth_path }"
          link-classes="d-flex align-items-center"
        >
          <font-awesome-icon :icon="right_to_bracket_icon" />
        </b-nav-item>
        <b-nav-item
          v-if="!$auth.is_logged_in"
          :to="{ name: 'registration' }"
          :aria-label="$ls('navbar.aria.login_button')"
          link-classes="pr-0"
        >
          <b-button>{{ $ls("navbar.register_button") }}</b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import { RouteName, RoutePath } from "@/router/route_constants";
import { event_path_contains_class } from "@/util/browser_util";
import RouterTreeNavigation from "@/vue/components/general/router_tree_navigation/RouterTreeNavigation.vue";
import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import { BNavbar, BNavbarBrand, BNavbarNav } from "bootstrap-vue/esm/components/navbar";
import { BNavForm, BNavItem, BNavItemDropdown } from "bootstrap-vue/esm/components/nav";
import { BFormInput } from "bootstrap-vue/esm/components/form-input";
import { BButton } from "bootstrap-vue/esm/components/button";
import { BDropdownDivider, BDropdownItem } from "bootstrap-vue/esm/components/dropdown";
import Sidebar from "@/vue/components/bootstrap/Sidebar.vue";
import { get_search_repository } from "@/repositories/search_repository";
import { get_charts_service } from "@/services/charts_service";

import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons/faGlobe";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons/faRightToBracket";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";

@Component({
  components: {
    BButton,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BNavForm,
    BNavItem,
    BNavItemDropdown,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    RouterTreeNavigation,
    Sidebar,
  },
})
export default class NavBar extends Vue {
  // --------------------------------------------
  // Template dependencies
  // --------------------------------------------

  get search_term(): string {
    return this.search_term_;
  }

  set search_term(value: string) {
    this.search_term_ = value;
  }

  is_searchbar_active = false;

  get is_search_running(): boolean {
    return get_search_repository(this.$backend).search_running;
  }

  get is_login_page(): boolean {
    // we can't use the router's current route: it's null on initial load
    return window.location.pathname == RoutePath.LOGIN;
  }

  get left_sidebar_display_class(): string {
    const charts = get_charts_service(this.$backend);

    // d-xl-none: then we'll have a left sidebar
    return charts.maximized_chart ? "" : "d-xl-none";
  }

  get sidebar_visible(): boolean {
    return this.sidebar_visible_;
  }

  set sidebar_visible(value: boolean) {
    this.sidebar_visible_ = value;
  }

  logout(): void {
    this.$auth.logout();
  }

  submit_search(): void {
    if (this.search_term.length < 1) {
      return;
    }

    this.$xrouter.push({
      name: RouteName.SEARCH_RESULTS,
      query: { search_term: this.search_term },
    });
  }

  get bars_icon(): IconDefinition {
    return faBars;
  }

  get search_icon(): IconDefinition {
    return faSearch;
  }

  get home_icon(): IconDefinition {
    return faHome;
  }

  get globe_icon(): IconDefinition {
    return faGlobe;
  }

  get list_icon(): IconDefinition {
    return faList;
  }

  get user_icon(): IconDefinition {
    return faUser;
  }

  get right_to_bracket_icon(): IconDefinition {
    return faRightToBracket;
  }

  get is_on_initial_sub_path(): boolean {
    return this.$xrouter.path.startsWith("/initial-sub");
  }

  get is_on_home_path(): boolean {
    return this.$xrouter.path === "/";
  }

  get is_on_discover_path(): boolean {
    return this.$xrouter.path.startsWith("/discover");
  }

  get is_on_watchlist_path(): boolean {
    return this.$xrouter.path.startsWith("/watchlist");
  }

  get is_on_profile_path(): boolean {
    return this.$xrouter.path.startsWith("/profile");
  }

  get is_on_auth_path(): boolean {
    return this.$xrouter.path.startsWith("/auth");
  }

  // --------------------------------------------
  // Internal
  // --------------------------------------------

  mounted(): void {
    document.documentElement.addEventListener("click", (event: Event) => this.hide_sidebar(event));
    this.remove_after_each_route_callback_ = this.$router.afterEach((to) => this.after_route_change(to));
  }

  beforeDestroy(): void {
    this.remove_after_each_route_callback_();
  }

  private hide_sidebar(event: Event): void {
    if (!this.sidebar_visible_) {
      return;
    }

    if (event_path_contains_class(event, "sidebar-no-close-element")) {
      return;
    }

    this.sidebar_visible = false;
  }

  private after_route_change(to: Route): void {
    if (!to.path.startsWith("/search")) {
      this.search_term_ = "";
    }
  }

  private sidebar_visible_ = false;
  private search_term_ = "";
  private remove_after_each_route_callback_ = () => {
    // initialized in mounted()
  };
}
</script>

<style scoped lang="scss">
/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: $navbar-logo-breakpoint) {
  .navbar-brand {
    display: none;
  }
}

.brand {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}

#left-sidebar-button {
  margin-right: 2em;
}

.form-inline {
  width: 100%;
}

.search {
  width: 100%;
  flex-grow: 1;
  display: flex;
}

.search-active {
  width: 100%;
  flex-grow: 1;
  display: flex;
}

.nav-item {
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0);
  border-bottom-left-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem;
}

.nav-item-selected {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}
</style>
