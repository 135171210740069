import { verify_field_defined } from "@xelonic.com/trill";
import { RelativeValue } from "@/model/changed_value";
import { Price, PriceChangeType } from "@/model/price";
import {
  Price as APIPrice,
  PriceChange as APIPriceChange,
  PriceChangeType as APIPriceChangeType,
} from "@xelonic.com/xelonic-api";

export function price_from_api(price: APIPrice): Price {
  const type_name = "Price";
  verify_field_defined(price, type_name, "currency");
  verify_field_defined(price, type_name, "value");

  const value_denominator = price.value_denominator ?? 1;

  return new Price(price.currency!, price.value!, {
    value_denominator,
    change: price.change ? price_change_from_api(price.change, value_denominator) : undefined,
    change_type: price.change?.change_type ? price_change_type_from_api(price.change.change_type) : undefined,
  });
}

function price_change_from_api(relative_value: APIPriceChange, value_denominator: number): RelativeValue {
  const type_name = "PriceChange";
  verify_field_defined(relative_value, type_name, "absolute");
  verify_field_defined(relative_value, type_name, "percentage");

  return new RelativeValue(relative_value.absolute!, relative_value.percentage!, value_denominator);
}

function price_change_type_from_api(change_type: APIPriceChangeType): PriceChangeType {
  switch (change_type) {
    case APIPriceChangeType.SincePreviousClose:
      return PriceChangeType.SINCE_PREVIOUS_CLOSE;
    case APIPriceChangeType.Yesterday:
      return PriceChangeType.YESTERDAY;
  }
}
