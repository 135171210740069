import { RepositoryStore } from "@/api/repository_store";
import { AuthState, default_auth_state } from "@/model/auth/auth_state";
import { RoutePath } from "@/router/route_constants";
import { has_items } from "@xelonic.com/trill";
import VueRouter, {
  Location,
  NavigationFailureType,
  NavigationGuardNext,
  Route,
  isNavigationFailure,
} from "vue-router";

export function get_authorization_service(
  repos: RepositoryStore,
  router: VueRouter,
  subscriptions_enabled: boolean
): AuthorizationService {
  return repos.get_service("authorization", () => {
    return new AuthorizationService(router, subscriptions_enabled);
  });
}

export class AuthorizationService {
  constructor(router: VueRouter, subscriptions_enabled: boolean) {
    this.router_ = router;
    this.subscriptions_enabled_ = subscriptions_enabled;
    this.auth_state_ = default_auth_state();

    router.beforeEach((to, from, next) => this.before_route_change(to, next));
    router.afterEach((to) => this.after_route_changed(to));
  }

  // on_login_changed means the user switched between being logged in and being logged out.
  // That means a redirect is necessary.
  async on_login_changed(new_state: AuthState, redirect_path?: string): Promise<void> {
    if (!redirect_path || redirect_path.startsWith(RoutePath.AUTH)) {
      redirect_path = "/";
    }

    let redirect_location = this.update_auth_state(new_state, { redirect_path, login_changed: true });

    // we override the redirect_location in two cases:
    // 1) user is not logged in: we always take the redirect_path directly, there's no necessary authz happening. The
    //    redirect tells where to go after the logout and we want to force that.
    // 2) the user is logged in: if no more auth is needed the redirect_location is empty, but we want to reroute
    //    from an auth route (most probably) to e.g. home
    if (!new_state.is_logged_in || !redirect_location) {
      redirect_location = { path: redirect_path };
    }

    try {
      await this.router_.push(redirect_location);
    } catch (error) {
      if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
        throw error;
      }
    }
  }

  // here we only need to verify whether the user is authorized. No strict redirect necessary.
  async on_auth_state_changed(new_state: AuthState): Promise<void> {
    const redirect_location = this.update_auth_state(new_state);

    if (redirect_location) {
      await this.router_.push(redirect_location);
    }
  }

  private update_auth_state(
    new_state: AuthState,
    options?: {
      redirect_path?: string;
      login_changed?: boolean;
    }
  ): Location | undefined {
    const old_state = this.auth_state_;
    this.auth_state_ = new_state;

    return get_authorized_location(this.auth_state_, this.subscriptions_enabled_, {
      old_state,
      route: this.current_route_,
      redirect_path: options?.redirect_path,
      login_changed: options?.login_changed,
    });
  }

  before_route_change(to: Route, next: NavigationGuardNext<Vue>): void {
    const redirect_location = get_authorized_location(this.auth_state_, this.subscriptions_enabled_, { route: to });
    next(redirect_location);
  }

  after_route_changed(to: Route): void {
    this.current_route_ = to;
  }

  private readonly router_: VueRouter;
  private readonly subscriptions_enabled_: boolean;
  private auth_state_: AuthState;
  private current_route_?: Route;
}

function get_authorized_location(
  new_state: AuthState,
  subscriptions_enabled: boolean,
  options?: {
    old_state?: AuthState;
    route?: Route;
    redirect_path?: string;
    login_changed?: boolean;
  }
): Location | undefined {
  const route_roles = get_route_roles(options?.route);
  const user_has_roles = route_roles.filter((route_role) => !new_state.roles.includes(route_role)).length < 1;
  const redirect_path = get_redirect_path(options);
  const is_internal_route = (options?.route?.meta?.internal as boolean) ?? false;

  // if the user doesn't have any subscription force the display of initial-subs-creation page
  if (
    new_state.is_logged_in &&
    subscriptions_enabled &&
    !new_state.ever_had_subs &&
    !is_route_accessible_before_initial_sub(options?.route)
  ) {
    return {
      name: "initial-subscription",
      query: { redirect_path },
    };
  }

  if (user_has_roles) {
    return undefined;
  }

  let route_name = "login";
  let subscribable = false;
  if (new_state.is_logged_in) {
    if (is_internal_route || !subscriptions_enabled) {
      route_name = "not-authorized";
    } else if (new_state.ever_had_subs) {
      route_name = "not-authorized";
      subscribable = true;
    } else {
      route_name = "initial-subscription";
    }
  }

  return {
    name: route_name,
    query: {
      redirect_path,
      subscribable: subscribable ? "true" : undefined,
    },
  };
}

function get_route_roles(route?: Route): string[] {
  if (!route) {
    return [];
  }

  return route.matched.map((r) => r.meta?.role ?? "").filter((s) => s.length > 0);
}

function is_route_accessible_before_initial_sub(route?: Route): boolean {
  if (!route) {
    return false;
  }

  return route?.matched.filter((r) => r.meta?.accessible_before_initial_sub).length > 0;
}

function get_redirect_path(options?: { redirect_path?: string; route?: Route }): string | undefined {
  if (has_items(options?.redirect_path)) {
    return options!.redirect_path;
  }

  if (options?.route) {
    return options.route.fullPath;
  }

  return undefined;
}
