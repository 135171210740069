import { Language } from "@/model/language";
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $languages: Languages;
  }
}

export function install_languages(vue: typeof Vue): Languages {
  vue.use(Languages);
  return vue.prototype.$languages;
}

export class Languages {
  static install(vue: typeof Vue): void {
    vue.prototype.$languages = new Languages();
  }

  readonly items: Record<string, Language> = {
    ["en"]: new Language("en", "us", "English", "us"),
    ["de"]: new Language("de", "de", "Deutsch"),
    // fr: new Language("fr", "fr", "Français"),
    // es: new Language("es", "es", "Español"),
    // pl: new Language("pl", "pl", "Polski"),
    // it: new Language("it", "it", "Italiano"),
  };
}
