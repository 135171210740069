import { Dimensions } from "@/model/geometry";

export function is_touch_device(): boolean {
  return window.matchMedia("(pointer: coarse)").matches;
}

export function is_on_path(path: string): boolean {
  return window.location.pathname.startsWith(path);
}

export function is_on_exact_path(path: string): boolean {
  return window.location.pathname === path;
}

export function event_path_contains_class(event: Event, class_name: string): boolean {
  for (let i = 0; i < event.composedPath().length; i++) {
    const target = event.composedPath()[i];
    if (!(target instanceof Element)) {
      continue;
    }

    if (target.classList.contains(class_name)) {
      return true;
    }
  }

  return false;
}

export function event_path_contains_element(event: Event, element: Element): boolean {
  for (let i = 0; i < event.composedPath().length; i++) {
    const target = event.composedPath()[i];
    if (!(target instanceof Element)) {
      continue;
    }

    if (target === element) {
      return true;
    }
  }

  return false;
}

export function get_element_dimensions(element: Element): Dimensions {
  let width = 0;
  let height = 0;

  while (width == 0 || height == 0) {
    if (width == 0) {
      width = element.clientWidth;
    }

    if (height == 0) {
      height = element.clientHeight;
    }

    if (!element.parentElement) {
      break;
    }

    element = element.parentElement;
  }

  return new Dimensions(width, height);
}
