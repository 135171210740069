import { SuccessfulRefreshTokenData as APIRefreshTokenData } from "@xelonic.com/xelonic-api";
import { possibly_defined, verify_field_defined } from "@xelonic.com/trill";
import { RefreshTokenData } from "@/model/auth/refresh_token_data";
import { subscriptions_info_from_api } from "@/api/auth/subscriptions_info";

export function refresh_token_data_from_api(data: APIRefreshTokenData): RefreshTokenData {
  const type_name = "SuccessfulRefreshTokenData";
  verify_field_defined(data, type_name, "expires_in_seconds");

  const subs_info = subscriptions_info_from_api(data);

  return new RefreshTokenData(data.expires_in_seconds!, {
    roles: possibly_defined(data.roles),
    subs_info: subs_info,
  });
}
