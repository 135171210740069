export class OwnerToCompanyRelationship {
  constructor(options?: {
    is_director?: boolean;
    is_officer?: boolean;
    is_ten_percent_owner?: boolean;
    is_other?: boolean;
    officer_title?: string;
  }) {
    this.is_director_ = options?.is_director ?? false;
    this.is_officer_ = options?.is_officer ?? false;
    this.is_ten_percent_owner_ = options?.is_ten_percent_owner ?? false;
    this.is_other_ = options?.is_other ?? false;
    this.officer_title_ = options?.officer_title;

    if (!this.is_director_ && !this.is_officer_ && !this.is_ten_percent_owner_) {
      this.is_other_ = true;
    }
  }

  get is_director(): boolean {
    return this.is_director_;
  }

  get is_officer(): boolean {
    return this.is_officer_;
  }

  get is_ten_percent_owner(): boolean {
    return this.is_ten_percent_owner_;
  }

  get is_other(): boolean {
    return this.is_other_;
  }

  get officer_title(): string | undefined {
    return this.officer_title_;
  }

  private readonly is_director_: boolean;
  private readonly is_officer_: boolean;
  private readonly is_ten_percent_owner_: boolean;
  private readonly is_other_: boolean;
  private readonly officer_title_?: string;
}
