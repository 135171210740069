export interface SecurityID {
  readonly id_type: SecurityIDType;
  readonly id: string;

  formatted(): string;
  equal_to(other: SecurityID | null): boolean;
  toString(): string;
}

export function security_id_from_string(str: string): SecurityID {
  const dash_index = str.indexOf("-");
  if (dash_index < 1) {
    // 1: empty ID is also ill-formed
    throw new Error(`no dash (-) found in security id '${str}'`);
  }
  const id_type_str = str.substring(0, dash_index);
  const id = str.substring(dash_index + 1);

  const id_type = security_id_type_from_string(id_type_str);
  if (id_type === undefined) {
    throw new Error(`invalid security id type: '${id_type}'`);
  }

  return create_security_id(id_type, id);
}

export function create_security_id(id_type: SecurityIDType, id: string): SecurityID {
  return new SecurityIDImpl(id_type, id);
}

class SecurityIDImpl implements SecurityID {
  constructor(id_type: SecurityIDType, id: string) {
    this.id_type_ = id_type;
    this.id_ = id;
  }

  get id_type(): SecurityIDType {
    return this.id_type_;
  }

  get id(): string {
    return this.id_;
  }

  formatted(): string {
    return `${this.id_type_}-${this.id_}`;
  }

  equal_to(other: SecurityID | null): boolean {
    if (!other) {
      return false;
    }

    return this.id_ == other.id && this.id_type_ == other.id_type;
  }

  toString(): string {
    return this.formatted();
  }

  private readonly id_type_: SecurityIDType;
  private readonly id_: string;
}

export enum SecurityIDType {
  CIK = "cik",
  LEI = "lei",
  HR = "hr",
}

function security_id_type_from_string(str: string): SecurityIDType | undefined {
  return SecurityIDType[str.toUpperCase() as keyof typeof SecurityIDType];
}

export function security_ids_contain(haystack: SecurityID[], needle: SecurityID): boolean {
  return undefined !== haystack.find((id: SecurityID) => id.equal_to(needle));
}

export function security_ids_equal(lhs?: SecurityID, rhs?: SecurityID): boolean {
  if (lhs === undefined && rhs === undefined) {
    return true;
  }

  if (lhs === undefined || rhs === undefined) {
    return false;
  }

  return lhs.equal_to(rhs);
}
