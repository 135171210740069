import { truncate_by_minutes } from "@/model/time/date";
import { TimePoint, TimePointType } from "./time_point";

export class DateTimePoint implements TimePoint {
  static from_string(str: string): DateTimePoint {
    try {
      const d = new Date(str);
      d.toISOString();
      return new DateTimePoint(d);
    } catch (reason: unknown) {
      throw new Error(`invalid time point string: ${str}: ${reason}`);
    }
  }

  constructor(date?: Date) {
    this.date_ = date ?? new Date();
  }

  get type(): TimePointType {
    return TimePointType.TIME;
  }

  get date(): Date {
    return this.date_;
  }

  before(other: TimePoint): boolean {
    if (!(other instanceof DateTimePoint)) {
      throw new Error("cannot compare timepoint. Not a date.");
    }

    return this.date_ < other.date;
  }

  after(other: TimePoint): boolean {
    if (!(other instanceof DateTimePoint)) {
      throw new Error("cannot compare timepoint. Not a date.");
    }

    return this.date_ > other.date;
  }

  equal_to(other: TimePoint): boolean {
    if (!(other instanceof DateTimePoint)) {
      return false;
    }

    return this.date_ === other.date_;
  }

  truncate_by_minutes(minutes: number, use_ceil: boolean): TimePoint {
    return new DateTimePoint(truncate_by_minutes(this.date_, minutes, use_ceil));
  }

  to_date(): Date {
    return this.date_;
  }

  to_string(): string {
    return this.date_.toISOString();
  }

  private readonly date_: Date;
}
