export enum FinancialStatementType {
  BALANCE_SHEET = "balancesheet",
  CASH_FLOW = "cashflow",
  INCOME = "income",
  COMPREHENSIVE_INCOME = "comprehensive_income",
  OPERATIONS = "operations",
}

export const financial_statement_types_order = [
  FinancialStatementType.BALANCE_SHEET,
  FinancialStatementType.CASH_FLOW,
  FinancialStatementType.OPERATIONS,
  FinancialStatementType.INCOME,
  FinancialStatementType.COMPREHENSIVE_INCOME,
];
