import { Entity } from "@/model/entities/entity";
import { OwnerToCompanyRelationship } from "@/model/entities/ownership/owner_to_company_relationship";
import { compare_less } from "@xelonic.com/trill";
import Big from "big.js";

export class EntityConnection {
  constructor(entity: Entity, relationship: OwnerToCompanyRelationship, shares?: Big) {
    this.entity_ = entity;
    this.relationship_ = relationship;
    this.shares_ = shares;
  }

  get entity(): Entity {
    return this.entity_;
  }

  get relationship(): OwnerToCompanyRelationship {
    return this.relationship_;
  }

  get shares(): Big | undefined {
    return this.shares_;
  }

  private readonly entity_: Entity;
  private readonly relationship_: OwnerToCompanyRelationship;
  private readonly shares_?: Big;
}

// Sorts connections in descending order of officers, directors, the rest.
export function sort_connections_by_importance(connections: EntityConnection[]): void {
  connections.sort((lhs, rhs) => {
    // officer

    if (lhs.relationship.is_officer && !rhs.relationship.is_officer) {
      return -1;
    }

    if (!lhs.relationship.is_officer && rhs.relationship.is_officer) {
      return 1;
    }

    // director

    if (lhs.relationship.is_director && !rhs.relationship.is_director) {
      return -1;
    }

    if (!lhs.relationship.is_director && rhs.relationship.is_director) {
      return 1;
    }

    // 10% owner

    if (lhs.relationship.is_ten_percent_owner && !rhs.relationship.is_ten_percent_owner) {
      return -1;
    }

    if (!lhs.relationship.is_ten_percent_owner && rhs.relationship.is_ten_percent_owner) {
      return 1;
    }

    return compare_less(lhs.entity.name ?? lhs.entity.id.formatted(), rhs.entity.name ?? rhs.entity.id.formatted());
  });
}
