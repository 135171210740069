import { has_items, is_defined } from "@xelonic.com/trill";

export function create_frontend_url(path: string): string {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  return `${window.location.origin}${path}`;
}

export function map_defined<InputElement, OutputElement>(
  list: InputElement[],
  f: (value: InputElement, index: number) => OutputElement | undefined
): OutputElement[] {
  return list!
    .map(f)
    .filter((value) => value !== undefined)
    .map((value) => value!);
}

export function filter_by_indices<Element>(list: Element[], indices: number[]): Element[] {
  return map_defined(list, (value, index) => (indices.includes(index) ? value : undefined));
}

/**
 * Shuffle array using Fisher-Yates.
 * Taken from https://www.w3docs.com/snippets/javascript/how-to-randomize-shuffle-a-javascript-array.html
 */
export function shuffle_array<Item>(arr: Item[]): void {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}

export function defined_if_has_items<Item, Items extends string | Item[]>(
  items: Items | undefined | null
): Items | undefined {
  return has_items(items) ? items! : undefined;
}

export function insert_line_breaks(str: string, break_after_count: number, break_character = "\n"): string {
  let current_char_count = 0;

  return str
    .split(" ")
    .map((current_word: string, index: number, words: string[]) => {
      current_char_count += current_word.length;
      if (current_char_count > break_after_count) {
        current_word += break_character;
        current_char_count = 0;
      } else if (index < words.length - 1) {
        current_word += " ";
      }

      return current_word;
    })
    .join("");
}

export function is_true_prop(prop: unknown): boolean {
  if (!is_defined(prop)) {
    return false;
  }

  if (prop === true) {
    return true;
  }

  // this handles the case where you write something like <my-component propertyx>
  // in that case the prop will be set to the empty string, even though you might declare it as boolean.
  if (prop === "") {
    return true;
  }

  return false;
}

export function get_or_default<Item>(
  items: Item[] | undefined,
  index: number,
  default_value: Item | undefined
): Item | undefined {
  if (!items || items.length <= index) {
    return default_value;
  }

  return items[index];
}
