export class TickerCode {
  constructor(
    symbol: string,
    options?: {
      exchange?: string;
      cik?: string;
      isin?: string;
      cusip?: string;
      wkn?: string;
      irs?: string;
      sic?: string;
      lei?: string;
      figi?: string;
      bloomberg_gid?: string;
    }
  ) {
    this.symbol_ = symbol;
    this.exchange_ = options?.exchange ?? "";
    this.cik_ = options?.cik ?? "";
    this.isin_ = options?.isin ?? "";
    this.cusip_ = options?.cusip ?? "";
    this.wkn_ = options?.wkn ?? "";
    this.irs_ = options?.irs ?? "";
    this.sic_ = options?.sic ?? "";
    this.lei_ = options?.lei ?? "";
    this.figi_ = options?.figi ?? "";
    this.bloomberg_gid_ = options?.bloomberg_gid ?? "";
  }

  get symbol(): string {
    return this.symbol_;
  }

  get exchange(): string {
    return this.exchange_;
  }

  get cik(): string {
    return this.cik_;
  }

  get isin(): string {
    return this.isin_;
  }

  get cusip(): string {
    return this.cusip_;
  }

  get wkn(): string {
    return this.wkn_;
  }

  get irs(): string {
    return this.irs_;
  }

  get sic(): string {
    return this.sic_;
  }

  get lei(): string {
    return this.lei_;
  }

  get figi(): string {
    return this.figi_;
  }

  get bloomberg_gid(): string {
    return this.bloomberg_gid_;
  }

  private readonly exchange_: string;
  private readonly symbol_: string;
  private readonly cik_: string;
  private readonly isin_: string;
  private readonly cusip_: string;
  private readonly wkn_: string;
  private readonly irs_: string;
  private readonly sic_: string;
  private readonly lei_: string;
  private readonly figi_: string;
  private readonly bloomberg_gid_: string;
}
