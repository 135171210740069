import { entity_from_api } from "@/api/entities/entity";
import { verify_field_defined } from "@xelonic.com/trill";
import { owner_to_company_relationship_from_api } from "@/api/ownership/owner_to_company_relationship";
import { EntityConnection } from "@/model/entities/entity_connection";
import { OwnerToCompanyRelationship } from "@/model/entities/ownership/owner_to_company_relationship";
import { Logger } from "@xelonic.com/trill";
import { EntityConnection as APIEntityConnection } from "@xelonic.com/xelonic-api";
import Big from "big.js";

export function entity_connection_from_api(connection: APIEntityConnection, logger: Logger): EntityConnection {
  const type_name = "EntityConnection";
  verify_field_defined(connection, type_name, "entity");

  const shares_denominator = connection.shares_denominator ?? 1;

  const entity = entity_from_api(connection.entity!, logger);
  const relationship = connection.relationship
    ? owner_to_company_relationship_from_api(connection.relationship)
    : new OwnerToCompanyRelationship();
  const shares = connection.shares ? new Big(connection.shares).div(shares_denominator) : undefined;

  return new EntityConnection(entity, relationship, shares);
}
