import { EntityIDMap } from "@/model/entity_id_map";
import { null_if_not_defined } from "@xelonic.com/trill";
import { RelativeValue } from "./changed_value";

export enum PriceChangeType {
  SINCE_PREVIOUS_CLOSE = "since-previous-close",
  YESTERDAY = "yesterday",
}

export class Price {
  constructor(
    currency: string,
    value: number,
    options?: {
      value_denominator?: number;
      change?: RelativeValue;
      change_type?: PriceChangeType;
    }
  ) {
    this.currency_ = currency;
    this.value_ = value / (options?.value_denominator ?? 1);
    this.change_ = null_if_not_defined(options?.change);
    this.change_type_ = null_if_not_defined(options?.change_type);
  }

  get currency(): string {
    return this.currency_;
  }

  get value(): number {
    return this.value_;
  }

  get change(): RelativeValue | null {
    return this.change_;
  }

  get change_type(): PriceChangeType | null {
    return this.change_type_;
  }

  private readonly currency_: string;
  private readonly value_: number;
  private readonly change_: RelativeValue | null;
  private readonly change_type_: PriceChangeType | null;
}

export type Prices = EntityIDMap<Price>;
