import { RepositoryStore } from "@/api/repository_store";
import { reactive } from "vue";

class ReactiveState {
  maximized_chart: string | null = null;
}

export function get_charts_service(repos: RepositoryStore): ChartsService {
  return repos.get_service("charts", () => new ChartsService());
}

export class ChartsService {
  constructor() {
    this.state_ = reactive(new ReactiveState()) as ReactiveState;
  }

  get maximized_chart(): string | null {
    return this.state_.maximized_chart;
  }

  set maximized_chart(new_value: string | null) {
    this.state_.maximized_chart = new_value ?? null;
  }

  private readonly state_: ReactiveState;
}
