import { LoginData } from "@/model/auth/login_data";
import { SuccessfulLoginData as APILoginData } from "@xelonic.com/xelonic-api";
import { possibly_defined, verify_field_defined } from "@xelonic.com/trill";
import { subscriptions_info_from_api } from "@/api/auth/subscriptions_info";

export function login_data_from_api(data: APILoginData): LoginData {
  const type_name = "SuccessfulLoginData";
  verify_field_defined(data, type_name, "expires_in_seconds");

  const subs_info = subscriptions_info_from_api(data);

  return new LoginData(data.expires_in_seconds!, {
    roles: possibly_defined(data.roles),
    is_first_login: possibly_defined(data.is_first_login),
    user_id: possibly_defined(data.user_id),
    subs_info: subs_info,
  });
}
