import { Configuration } from "@/model/config/configuration";
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $config: Configuration;
  }
}

export function install_configuration(vue: typeof Vue, config: Configuration): ConfigurationPlugin {
  vue.use(ConfigurationPlugin, { config });
  return vue.prototype.$config;
}

export default class ConfigurationPlugin {
  static install(vue: typeof Vue, options: { config: Configuration }): void {
    vue.prototype.$config = options.config;
  }
}
