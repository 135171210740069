import { null_if_empty } from "@xelonic.com/trill";

export class CompanyWatchlistSettings {
  constructor(options?: { visible_fields?: string[]; sorted_by?: string; sort_ascending?: boolean }) {
    this.visible_fields_ = options?.visible_fields ?? [];
    this.sorted_by_ = null_if_empty(options?.sorted_by);
    this.sort_ascending_ = options?.sort_ascending ?? false;
  }

  get visible_fields(): string[] {
    return this.visible_fields_;
  }

  set visible_fields(fields: string[]) {
    this.visible_fields_ = fields;
  }

  get sorted_by(): string | null {
    return this.sorted_by_;
  }

  set sorted_by(value: string | null) {
    this.sorted_by_ = value;
  }

  get sort_ascending(): boolean {
    return this.sort_ascending_;
  }

  set sort_ascending(value: boolean) {
    this.sort_ascending_ = value;
  }

  private visible_fields_: string[];
  private sorted_by_: string | null;
  private sort_ascending_: boolean;
}
